import { ChangeEvent, useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";

import { Company } from "@data/entities/Company/Company";
import { Workspace } from "@data/entities/Workspace/Workspace";
import ReadOnlyInput from "@ui/components/Input/ReadOnlyInput";
import { RegisterWorkspaceResultModal } from "@ui/modals/RegisterWorkspaceResultModal";
import {
  ResponseWorkspace,
  useMutationWorkspace,
} from "@data/repositories/Workspace/mutationWorkspace";
import { WorkspaceWitchCompany } from "@data/types";
import { DefaultLoadingOverlay } from "@ui/components/LoadingOverlay/DefualtLoadingOverlay";

import { mainTheme } from "../../../data/theme/theme";
import DefaultButton from "../../components/Button/DefaultButton";
import {
  ButtonContainer,
  GridSpacer,
  GridWrapper,
  InputContainer,
  CompanyHead,
  StyledLabel,
} from "./registerWorkspaceWidgetStyle";
import { RegisterWorkspaceForm } from "./components/RegisterWorkspaceForm";
import { WorkspaceFormInput } from "./components/InputWorkspaceName";
import ConfirmDialog from "@ui/components/Dialog/ConfirmDialog";

type validationSchema = Pick<Company, "fms_project_id" | "company_nm"> &
  Pick<Workspace, "workspace_nm" | "fms_environment_id">;

const initialWorkspace: Workspace = {
  workspace_cd: "",
  workspace_nm: "",
  fms_environment_id: "",
  admin_password: "",
  is_active: 1,
  ver_no: 0,
  company_id: "",
};

const initialErrors: validationSchema = {
  company_nm: "",
  fms_project_id: "",
  workspace_nm: "",
  fms_environment_id: "",
};

const throwableSchema = z.object<Record<keyof validationSchema, any>>({
  company_nm: z.string().nonempty("このフィールドは必須です"),
  fms_project_id: z.string().nonempty("このフィールドは必須です"),
  workspace_nm: z.string().nonempty("このフィールドは必須です"),
  fms_environment_id: z
    .string()
    .regex(/^[-0-9a-zA-Z]*$/, "半角英数字、「-」のみ入力できます")
    .nonempty("このフィールドは必須です"),
});

const RegisterWorkspaceWidget = () => {
  const [editedWorkspace, setEditedWorkspace] =
    useState<Workspace>(initialWorkspace);
  const [modalProp, setModalProps] = useState<ResponseWorkspace>();
  useState<WorkspaceWitchCompany>();

  const location = useLocation();
  const selectedCompany: Company = location.state;
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [errors, setErrors] = useState(initialErrors);
  const { registerWorkspace } = useMutationWorkspace();

  const inputHandler =
    (keyType: keyof Workspace) => (e: ChangeEvent<HTMLInputElement>) => {
      setEditedWorkspace((pre) => {
        return { ...pre, [keyType]: e.target.value };
      });
    };

  const handleClick = () => {
    try {
      throwableSchema.parse({
        company_nm: selectedCompany.company_nm,
        fms_project_id: selectedCompany.fms_project_id,
        workspace_nm: editedWorkspace.workspace_nm,
        fms_environment_id: editedWorkspace.fms_environment_id,
      });

      setErrors(initialErrors);

      setOpenDialog(true);
    } catch (e: any) {
      setErrors({
        ...e.flatten().fieldErrors,
      });
    }
  };

  const handleSubmit = () => {
    registerWorkspace.mutate(
      {
        workspace: editedWorkspace,
        company: selectedCompany,
      },
      {
        onSuccess(data, variables, context) {
          setModalProps(data);
          setModalOpen(true);
        },
        onError: (err: any) => {
          setModalOpen(true);
        },
      }
    );
  };

  /// enter_key無効化
  document.onkeydown = function (e) {
    // エンターキーだったら無効にする
    if (e.key === "Enter") {
      return false;
    }
  };

  return (
    <>
      <RegisterWorkspaceForm>
        <CompanyHead>会社情報</CompanyHead>
        <InputContainer>
          <GridWrapper>
            <StyledLabel>選択した会社名</StyledLabel>
            <ReadOnlyInput
              label="会社名"
              value={selectedCompany?.company_nm || ""}
              size={"xs"}
              errors={{
                error: Boolean(errors.company_nm),
                errorMessage: errors.company_nm,
              }}
            />
          </GridWrapper>
        </InputContainer>

        <InputContainer>
          <GridWrapper>
            <GridSpacer />

            <ReadOnlyInput
              label="FMSプロジェクトID"
              value={selectedCompany.fms_project_id}
              size={"xs"}
              errors={{
                error: Boolean(errors.fms_project_id),
                errorMessage: errors.fms_project_id,
              }}
            />
          </GridWrapper>
        </InputContainer>

        <WorkspaceFormInput
          label="走行環境名"
          labelId="label_workspace_name"
          placeholder="文字を入力"
          inputProp={editedWorkspace.workspace_nm}
          onChange={inputHandler("workspace_nm")}
          leftDescription="続いて走行環境を登録して下さい"
          description="例： eve_autonomy / sandbox など"
          errors={{
            error: Boolean(errors.workspace_nm),
            errorMessage: errors.workspace_nm,
          }}
        />

        <WorkspaceFormInput
          label="FMS走行環境ID"
          labelId="label_fms_environment_id"
          placeholder="半角英数を入力"
          inputProp={editedWorkspace.fms_environment_id}
          onChange={inputHandler("fms_environment_id")}
          description="例： b70*************"
          errors={{
            error: Boolean(errors.fms_environment_id),
            errorMessage: errors.fms_environment_id,
          }}
        />
        <ButtonContainer>
          <DefaultButton
            type="button"
            backgroundColor={"white"}
            hoverColor={mainTheme.colors.secondary}
            color={mainTheme.colors.onSecondary}
            onClick={() => {
              navigate("/workspaces/register/company");
            }}
            buttonText={"戻る"}
            size={"M"}
            border
            borderColor={mainTheme.colors.secondaryContainer}
            fontWeight={"600"}
            contentWidth={"96px"}
          />
          <DefaultButton
            type="button"
            backgroundColor={mainTheme.colors.primaryPositive}
            hoverColor={mainTheme.colors.primary}
            color={mainTheme.colors.onPrimary}
            buttonText={"登録"}
            size={"M"}
            contentWidth={"96px"}
            onClick={handleClick}
          />
        </ButtonContainer>

        <ConfirmDialog
          opened={openDialog}
          onCLose={useCallback(() => setOpenDialog(false), [openDialog])}
          description={"走行環境を登録しますか？"}
          executeButtonText={"登録"}
          onConfirm={handleSubmit}
        />

        {modalOpen && (
          <RegisterWorkspaceResultModal
            isOpen={modalOpen}
            onRequestClose={() => {
              //setModalOpen(false);
            }}
            workspace={modalProp}
            isError={registerWorkspace.isError}
            error={registerWorkspace.error}
          />
        )}
      </RegisterWorkspaceForm>

      {registerWorkspace.isLoading && <DefaultLoadingOverlay visible={true} />}
    </>
  );
};

export default RegisterWorkspaceWidget;
