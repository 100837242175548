import { FC } from "react";

import { ListItemContainer, ListText, GridSpacer } from "./listItemStyle";
import { DefaultCheckbox } from "@ui/components/Checkbox/DefaultCheckbox";
import { GetWorkspace } from "@data/repositories/Workspace/queryWorkspace";

type Props = {
  item: GetWorkspace & { selected: boolean };
  onClick: (item: GetWorkspace & { selected: boolean }) => void;
};

const ListItem: FC<Props> = ({ item, onClick }) => {
  return (
    <ListItemContainer>
      <GridSpacer />
      <ListText>
        <DefaultCheckbox
          onChange={() => onClick(item)}
          checked={item.selected}
        />
      </ListText>
      <ListText>{item.workspace_nm}</ListText>
      <ListText>{item.workspace_cd}</ListText>
      <ListText>{item.company_m?.company_nm}</ListText>
      <ListText>{item.company_m?.fms_project_id}</ListText>

      <GridSpacer />
    </ListItemContainer>
  );
};

export default ListItem;
