import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Layout from "@ui/components/Layout";
import VehicleDetailWidget from "@ui/widgets/VehicleDetailWidget";
import { VehicleListItem } from "@data/types";
import { queryWorkspace } from "@data/repositories/Workspace/queryWorkspace";

import {
  VehicleDetailScreenContainer,
  GridSpacer,
  ScrollableContent,
} from "./vehicleDetailScreenStyle";

const VehicleDetailScreen = () => {
  const location = useLocation();
  const vehicle: VehicleListItem = location.state;

  const { useWorkspaceDropdown } = queryWorkspace();
  const { data, isError } = useWorkspaceDropdown();

  useEffect(() => {
    if (isError) {
      alert("データの取得に失敗しました");
      return;
    }
  }, [isError]);

  return (
    <Layout title="車両：走行環境切替">
      <VehicleDetailScreenContainer>
        <GridSpacer />
        <ScrollableContent>
          <VehicleDetailWidget
            workspaces={data}
            vehicle={vehicle}
            currentWorkspace={vehicle.workspace_m}
          />
        </ScrollableContent>
      </VehicleDetailScreenContainer>
    </Layout>
  );
};

export default VehicleDetailScreen;
