import styled from "@emotion/styled";

export const DashMenuBody = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.background};
`;

export const DashMenuCenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const DashMenuFooter = styled.footer`
  height: 20%;
  background-color: ${(props) => props.theme.colors.background};
`;

export const StyledButton = styled.button`
  height: 80px;
  width: 560px;
  text-align: center;
  border: none;
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.primaryPositive};
  box-shadow: ${(props) => props.theme.shadow.medium};

  color: ${(props) => props.theme.colors.onPrimary};
  font-size: ${(props) => props.theme.fontSize.xLarge};

  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.colors.primary};
  }
`;
