import { FC, useCallback, useState } from "react";

import { List, ListContainer } from "./deleteWorkspacesWidgetStyle";
import ListItem from "./components/ListItem";
import RenderFooter from "./components/DeleteItemFooter";

import Footer from "@ui/components/Footer/Footer";
import { DefaultLoadingOverlay } from "@ui/components/LoadingOverlay/DefualtLoadingOverlay";
import { GetWorkspace } from "@data/repositories/Workspace/queryWorkspace";
import { useMutationWorkspace } from "@data/repositories/Workspace/mutationWorkspace";
import DeleteWorkspaceModal from "@ui/modals/DeleteWorkspaceModal";
import DeleteConfirmWorkspaceModal from "@ui/modals/DeleteConfirmWorkspaceModal";

type Props = {
  workspaces: (GetWorkspace & { selected: boolean })[];
  selectedWorkspace?: (GetWorkspace & { selected: boolean })[];
  onSelect: (item: GetWorkspace & { selected: boolean }) => void;
  openConfirm: boolean;
  setOpenConfirm: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DeleteWorkspacesWidget: FC<Props> = ({
  workspaces,
  selectedWorkspace = [],
  onSelect,
  openConfirm,
  setOpenConfirm,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { deleteWorkspaceMutation } = useMutationWorkspace();

  const deleteIds = () => {
    const ids = selectedWorkspace.map((filtered) => {
      return filtered.workspace_cd;
    });
    return ids;
  };

  const onSubmit = useCallback(() => {
    deleteWorkspaceMutation.mutate(deleteIds(), {
      onSuccess: () => {
        setModalOpen(true);
      },
      onError: () => {
        setOpenConfirm(false);
        setModalOpen(true);
      },
    });
  }, [deleteIds()]);

  return (
    <>
      <ListContainer>
        <List>
          {workspaces.length > 0 &&
            workspaces.map((workspace, index) => (
              <ListItem item={workspace} onClick={onSelect} key={index} />
            ))}
        </List>
      </ListContainer>
      <Footer>
        <RenderFooter
          onOpenDialog={useCallback(() => {
            if (selectedWorkspace.length === 0) {
              alert("項目が選択されていません");
              return;
            }
            setOpenConfirm(true);
          }, [selectedWorkspace])}
        />
      </Footer>

      {openConfirm && (
        <DeleteConfirmWorkspaceModal
          isOpen={openConfirm}
          onRequestClose={() => {
            //setOpenConfirm(false)
          }}
          onSubmit={onSubmit}
          deletedWorkspace={selectedWorkspace}
        />
      )}

      {modalOpen && (
        <DeleteWorkspaceModal
          isOpen={modalOpen}
          onRequestClose={() => {
            //setModalOpen(false);
          }}
          isError={deleteWorkspaceMutation.isError}
          error={deleteWorkspaceMutation.error}
        />
      )}

      <DefaultLoadingOverlay
        visible={deleteWorkspaceMutation.isLoading}
        loaderSize="lg"
      />
    </>
  );
};

export default DeleteWorkspacesWidget;
