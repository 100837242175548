import styled from "@emotion/styled";

export const GridSpacer = styled.div``;

export const RegisterResultModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const RegisterResultModalHeader = styled.h2`
  color: ${(props) => props.theme.colors.onBackGround};
  padding: 8px 16px;
  border-radius: 8px;
  flex: 1;
  margin: 0;
`;

export const RegisterResultModalFooter = styled.span`
  width: 100%;
  flex: 2;
`;

export const RegisterResultModalFooterButtonContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const RegisterResultModalFooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
  padding: 0 0px;
`;

export const ScrollableContent = styled.div`
  flex: 12;
  overflow-y: scroll;
  padding: 1em;

  /// scroll-bar 消去 edge
  -ms-overflow-style: none;
  /// scroll-bar 消去 chrome, safari
  &::-webkit-scrollbar {
    display: none;
  }
  /// scroll-bar 消去 firefox
  scrollbar-width: none;
`;
