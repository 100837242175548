import { Theme } from "@emotion/react";

export const mainTheme: Theme = {
  fonts: {
    mainFont: "Noto Sans JP",
    subFont: "Meiryo",
  },
  fontSize: {
    xxLarge: "30px",
    xLarge: "24px",
    large: "18px",
    medium: "16px",
    mine: "12px",
  },
  colors: {
    primary: "#00adbb",
    primaryPositive: "#3ebac4",
    primaryNegative: "#008a96",
    PrimaryContainer: "#ffffff",
    onPrimary: "#ffffff",

    secondary: "#f1f9fa",
    secondaryPositive: "#ffffff",
    secondaryNegative: "#ffffff",
    secondaryContainer: "#00adbb",
    onSecondary: "#00adbb",

    third: "#dcf7fa",
    thirdPositive: "#ffffff",
    thirdNegative: "#e7e8e9",
    thirdContainer: "#00adbb",
    onThird: "#00adbb",

    background: "#f1f9fa",
    onBackGround: "#667172",
    onBackGroundPositive: "#868e96",

    error: "#fa5252",
    onError: "#ffffff",

    warning: "#ff9300",
    onWarning: "#ffffff",

    outline: "#c1c1c1",
    grayOut: "#bfbcbc",
    onGrayOut: "#ffffff",
  },
  shadow: {
    medium: "0px 2px 8px -3px rgba(0,0,0, 0.6)",
    large: "2px 2px 15px -5px rgba(0,0,0, 0.6);",
    small: "0px 0px 10px -5px rgba(0,0,0, 0.6)",
    xsmall: "0px 6px 12px -12px rgba(0,0,0, 0.6)",
    xxsmall: "0px 6px 9px -12px rgba(0,0,0, 0.6)",
  },
};
