import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { User } from "@data/entities/User/User";
import { GetUser } from "./queryUser";
import { refreshSession } from "../Auth/auth";
import { Company } from "@data/entities/Company/Company";
import { Workspace } from "@data/entities/Workspace/Workspace";

export type ResponseUser = Pick<
  User,
  "id" | "workspace_cd" | "internal_workspace_cd" | "is_allowed_start_vehicle"
> & { password: 0 | 1 }; //{０：変更しない、　１：変更した場合}
export type ModifyUser = Pick<
  User,
  "workspace_cd" | "is_allowed_start_vehicle"
> &
  Pick<Company, "company_nm"> &
  Pick<Workspace, "workspace_nm"> & { change_password: 0 | 1 }; //{０：変更しない、　１：変更した場合}

const HOST = `${process.env.REACT_APP_ENDPOINT}`;

export const useMutationUser = () => {
  const queryClient = useQueryClient();

  /// ユーザ登録
  const postUser = async ({ user }: { user: User }) => {
    const url = `${HOST}/users`;
    const { data } = await axios.post<{ data: ResponseUser }>(
      url,
      <User>{
        workspace_cd: user.workspace_cd,
        company_id: user.company_id,
        internal_workspace_cd: user.internal_workspace_cd,
        is_allowed_start_vehicle: user.is_allowed_start_vehicle,
        password: user.password,
      },
      {
        headers: {
          Authorization: await refreshSession(),
        },
      }
    );
    return data.data;
  };
  const registerUser = useMutation((variables: { user: User }) =>
    postUser({ ...variables })
  );

  /// ユーザ設定変更
  const patchUser = async ({ user }: { user: User }) => {
    const url = `${HOST}/users/${user.id}/property`;
    const { data } = await axios.patch<{ data: ModifyUser }>(
      url,
      <User>{
        workspace_cd: user.workspace_cd,
        company_id: user.company_id,
        internal_workspace_cd: user.internal_workspace_cd,
        is_allowed_start_vehicle: user.is_allowed_start_vehicle,
        password: user.password,
      },
      {
        headers: {
          Authorization: await refreshSession(),
        },
      }
    );
    return data.data;
  };
  const updateUser = useMutation((variables: { user: User }) =>
    patchUser({ ...variables })
  );

  /// ユーザ削除
  const deleteUsers = async (userIdList: User["id"][]) => {
    const url = `${HOST}/users`;
    await axios.delete(url, {
      data: {
        users: userIdList,
      },
      headers: {
        Authorization: await refreshSession(),
      },
    });
  };
  const deleteUsersMutation = useMutation((variables: User["id"][]) => {
    return deleteUsers(variables);
  });

  /// ユーザー有効・無効切替
  const patchUserActive = async (user: User) => {
    const url = `${HOST}/users/${user.id}/is_active`;
    await axios.patch(
      url,
      {
        is_active: user.is_active,
      },
      {
        headers: {
          Authorization: await refreshSession(),
        },
      }
    );
  };
  const updateUserActive = useMutation(
    (variables: User) => {
      return patchUserActive(variables);
    },
    {
      onSuccess(data, variables, context) {
        queryClient.setQueryData<GetUser[]>(["frontUsers"], (olds) =>
          olds?.map((old) => {
            return old.id === variables.id
              ? {
                  ...old,
                  is_active: variables.is_active === 0 ? 1 : 0,
                }
              : old;
          })
        );
      },
    }
  );

  return {
    registerUser,
    updateUser,
    deleteUsersMutation,
    updateUserActive,
  };
};
