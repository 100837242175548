import { FC } from "react";
import { SizeProp } from "../../../@types/theme";
import { StyledDivider } from "./dividerStlyle";

type Props = {
  thin: SizeProp;
  dividerStyle?: "dashed" | "dotted" | "solid";
  rounded?: true;
};

const Divider: FC<Props> = ({ thin, dividerStyle = "solid", rounded }) => {
  return (
    <>
      <StyledDivider
        thin={thin}
        dividerStyle={dividerStyle}
        rounded={rounded}
      />
    </>
  );
};

export default Divider;
