import { FC } from "react";
import { useNavigate } from "react-router-dom";

import {
  GridSpacer,
  ResultModalContainer,
  ResultModalFooter,
  ResultModalHeader,
  ScrollableContent,
  ResultFooterContainer,
  ResultModalFooterButtonContainer,
} from "./ModifyUserModalStyle";
import { ModalContent } from "./components/ModalContaent";

import { mainTheme } from "@data/theme/theme";
import DefaultButton from "@ui/components/Button/DefaultButton";
import DefaultModal from "@ui/components/Modal/DefaultModal";
import { ModifyUser } from "@data/repositories/User/mutationUser";
import ModalResults from "@ui/templates/ModalResults";

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
  user?: ModifyUser;
  isError?: boolean;
  error?: any;
};

export const ModifyUserResultModal: FC<Props> = ({
  isOpen,
  onRequestClose,
  user,
  isError = false,
  error,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <DefaultModal isOpen={isOpen} onRequestClose={onRequestClose}>
        <ResultModalContainer>
          <ResultModalHeader>変更結果</ResultModalHeader>

          <ScrollableContent>
            <ModalResults
              children={<ModalContent user={user} />}
              isError={isError}
              error={error}
              msg={"ユーザの変更"}
            />
          </ScrollableContent>

          <ResultModalFooter>
            <ResultFooterContainer>
              <GridSpacer />
              <GridSpacer />
              <ResultModalFooterButtonContainer>
                <DefaultButton
                  backgroundColor={mainTheme.colors.primaryPositive}
                  hoverColor={mainTheme.colors.primary}
                  color={mainTheme.colors.onPrimary}
                  onClick={() => {
                    navigate("/users");
                  }}
                  buttonText={"OK"}
                  size={"M"}
                  contentWidth={"72px"}
                />
              </ResultModalFooterButtonContainer>
              <GridSpacer />
            </ResultFooterContainer>
          </ResultModalFooter>
        </ResultModalContainer>
      </DefaultModal>
    </>
  );
};
