import React, { FC } from "react";
import { FooterContainer } from "./footerStyle";

type Props = {
  children: React.ReactNode;
};

const Footer: FC<Props> = ({ children }) => {
  return <FooterContainer>{children}</FooterContainer>;
};

export default Footer;
