import styled from "@emotion/styled";
import { GRID_BREAKPOINTS } from "../../../data/constants/breakPoint";
import { InputTitle } from "@ui/components/InputTitle/InputTitle";

export const grid = "0.2fr 1fr 4fr 1fr 0.2fr";

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr;
  }
  @media (max-width: ${GRID_BREAKPOINTS.lg}) {
    display: flex;
    flex-direction: column;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 6em;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CompanyHead = InputTitle;

export const WorkspaceHead = InputTitle;

export const StyledLabel = styled.label`
  display: flex;
  justify-content: start;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.onBackGroundPositive};
  font-size: ${({ theme }) => theme.fontSize.medium};

  padding-right: 24px;
`;

export const GridSpacer = styled.div``;
