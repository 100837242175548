import styled from "@emotion/styled";

export const HeaderContainer = styled.div`
  z-index: 10;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px 0;

  background-color: ${(props) => props.theme.colors.primary};
  box-shadow: ${(props) => props.theme.shadow.medium};
  margin-bottom: 5px;
`;

export const HeaderItem = styled.div<{ grow: number }>`
  flex-grow: ${({ grow }) => grow};
`;

export const HeaderTitle = styled.span`
  font-size: 30px;
  color: ${(props) => props.theme.colors.onPrimary};
  display: flex;
  justify-content: flex-start;
  padding-left: 60px;
`;

export const HeaderSignOut = styled.span`
  display: flex;
  flex-direction: row-reverse;
  padding-Right: 60px;
`;