import {FC, ReactNode} from "react";
import React, {ChangeEvent, FormEvent, useState} from "react";
import {useNavigate} from "react-router-dom";
import {z} from "zod";

import InputPassword from "@ui/components/Input/InputPassword";
import InputText from "@ui/components/Input/InputText";
import DefaultButton from "@ui/components/Button/DefaultButton";
import {mainTheme} from "@data/theme/theme";
import {mockAuthLogin} from "@data/repositories/Auth/mockAuthRepository";
import {useMockAuthStore} from "@data/store/mockAuthStore";

import {AuthContainer, AuthLayout, ButtonContainer} from "./mockAuthStyle";

type LoginType = {
  email: string;
  password: string;
};

type ValidationSchema = LoginType;

const initialLogin: LoginType = {
  email: "",
  password: "",
};

const initialErrors: ValidationSchema = {
  email: "",
  password: "",
};

const MockAuthComponent: FC<{ children: ReactNode }> = ({children}) => {
  const [login, setLogin] = useState(initialLogin);
  const {setAuth, isAuth} = useMockAuthStore();
  const navigate = useNavigate();
  const [errors, setErrors] = useState(initialErrors);

  const inputHandler =
    (keyType: keyof LoginType) => (e: ChangeEvent<HTMLInputElement>) => {
      setLogin((pre) => {
        return {...pre, [keyType]: e.target.value};
      });
    };

  const prams = (objectKey: keyof LoginType) => {
    return {
      value: login[`${objectKey}`],
      onChange: inputHandler(`${objectKey}`),
      errors: {
        error: Boolean(errors[`${objectKey}`]),
        errorMessage: errors[`${objectKey}`] ? errors[`${objectKey}`][0] : "",
      },
    };
  };

  const throwableSchema = z.object<Record<keyof ValidationSchema, any>>({
    email: z
      .string()
      .nonempty("このフィールドは必須です")
      .email("メールアドレスの形式である必要があります"),
    password: z
      .string()
      .nonempty("このフィールドは必須です")
      .min(6, "パスワードは６文字以上である必要があります"),
    // .regex(
    //   /^(?=.*?[a-z])(?=.*?\d)(?=.*?[!-\/:-@[-`{-~])[!-~]{6,100}$/i,
    //   "パスワード入力に誤りがあります"
    // )
  });

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      throwableSchema.parse(login);

      setErrors(initialErrors);

      await mockAuthLogin(login)
        .then((res) => {
          navigate("/");
          setAuth(true);
        })
        .catch((err: any) => {
          console.log(err.response);
          err.response.status === 401
            ? alert("Emailかパスワードに誤りがあります")
            : alert("ログインに失敗しました。");
        });
    } catch (e: any) {
      setErrors({
        ...e.flatten().fieldErrors,
      });
    }
  };
  return (
    <>
      {
        isAuth ? (
          <>{children}</>
        ) : (
          <AuthLayout>
            <AuthContainer onSubmit={handleSubmit}>
              <h1>ログイン</h1>
              <InputText
                label="Email"
                placeholder="emailを入力"
                withAsterisk
                {...prams("email")}
              />
              <InputPassword
                label="パスワード"
                withAsterisk
                placeholder="パスワードを入力"
                {...prams("password")}
              />
              <ButtonContainer>
                <DefaultButton
                  backgroundColor={mainTheme.colors.thirdPositive}
                  hoverColor={mainTheme.colors.third}
                  buttonText={"アカウント作成？"}
                  size={"S"}
                  color={mainTheme.colors.onThird}
                  onClick={() => {
                  }}
                  contentWidth="162px"
                  radiusFull
                  type="button"
                />
                <DefaultButton
                  backgroundColor={mainTheme.colors.primaryPositive}
                  hoverColor={mainTheme.colors.primary}
                  buttonText={"ログイン"}
                  size={"S"}
                  color={mainTheme.colors.onPrimary}
                  onClick={() => {
                  }}
                  contentWidth="108px"
                  type={"submit"}
                />
              </ButtonContainer>
            </AuthContainer>
          </AuthLayout>
        )
      }
    </>
  );
};

export {MockAuthComponent};
