import React, { useEffect, useMemo, useState } from "react";

import Layout from "@ui/components/Layout";
import Divider from "@ui/components/Divider/Divider";
import { ACTIVE } from "@data/constants/entity";
import {
  GetWorkspace,
  queryWorkspace,
} from "@data/repositories/Workspace/queryWorkspace";
import DeleteWorkspacesWidget from "@ui/widgets/DeleteWorkspacesWidget";

import {
  GridSpacer,
  SearchContainer,
  DeleteScreenListTitle,
  DeleteScreenHeader,
  DeleteScreenContainer,
  DeleteScreenWrapper,
  DeleteScreenListITitleText,
} from "./deleteWorkspacesScreenStyle";
import DefaultMultiSelect from "@ui/components/Dropdown/MultiSelect/MultiSelect";

type SelectableWorkspaces = GetWorkspace & {
  selected: boolean;
};

const DeleteWorkspacesScreen = () => {
  const [selectedValue, setSelectedValue] = useState<string[]>([]);
  const [selectableWorkspace, setSelectableWorkspaces] =
    useState<SelectableWorkspaces[]>();
  const [openDialog, setOpenDialog] = useState(false);

  const { useWorkspaceDropdown, useWorkspaceList } = queryWorkspace();
  const { data: workspaces, isError: workspacesError } = useWorkspaceList();
  const { data: dropdownProp, isError: dropdownPropError } =
    useWorkspaceDropdown();

  const noActiveWorkspace = (
    workspaces: GetWorkspace[]
  ): SelectableWorkspaces[] => {
    const filtered = workspaces?.filter((workspace) => {
      return workspace.is_active === ACTIVE.DISABLE;
    });
    return filtered.map((workspace) => {
      return {
        ...workspace,
        selected: false,
      };
    });
  };

  useMemo(() => {
    setSelectableWorkspaces(noActiveWorkspace(workspaces || []));
  }, [workspaces]);

  const onSelect = (item: GetWorkspace & { selected: boolean }) => {
    setSelectableWorkspaces(
      selectableWorkspace?.map((workspace) => {
        return item.workspace_cd === workspace.workspace_cd
          ? {
              ...workspace,
              selected: !workspace.selected,
            }
          : { ...workspace };
      })
    );
  };

  const dropdownList = dropdownProp
    ? dropdownProp.map((workspace) => {
        return {
          value: workspace.workspace_cd || "",
          label: workspace.workspace_nm || "",
          group: "走行環境名",
        };
      })
    : [];

  const filterWorkspaces = (
    machs: string[],
    workspace: SelectableWorkspaces[]
  ): SelectableWorkspaces[] => {
    if (!workspace) return [];

    return workspace.filter((workspace) => {
      return machs.length > 0
        ? machs.includes(workspace.workspace_cd || "")
        : true;
    });
  };

  useEffect(() => {
    if (workspacesError || dropdownPropError) {
      alert("データの取得に失敗しました");
    }
  }, [workspacesError, dropdownPropError]);

  return (
    <>
      <Layout title="走行環境削除選択">
        <DeleteScreenContainer>
          <GridSpacer />
          <DeleteScreenWrapper>
            <DeleteScreenHeader>
              <SearchContainer>
                <DefaultMultiSelect
                  label="走行環境名"
                  data={dropdownList}
                  maxSelectedValues={1}
                  placeholder="走行環境名で検索"
                  onChange={(e) => {
                    setSelectedValue(e);
                  }}
                  onClick={() => {
                    setOpenDialog(false);
                  }}
                />
              </SearchContainer>
            </DeleteScreenHeader>

            <DeleteScreenListTitle>
              <GridSpacer />
              <DeleteScreenListITitleText>選択</DeleteScreenListITitleText>
              <DeleteScreenListITitleText>
                走行環境名
              </DeleteScreenListITitleText>
              <DeleteScreenListITitleText>
                走行環境ID
              </DeleteScreenListITitleText>
              <DeleteScreenListITitleText>会社名</DeleteScreenListITitleText>
              <DeleteScreenListITitleText>
                FMSプロジェクトID
              </DeleteScreenListITitleText>
              <GridSpacer />
            </DeleteScreenListTitle>
            <Divider thin="1px" rounded />

            <DeleteWorkspacesWidget
              workspaces={filterWorkspaces(
                selectedValue,
                selectableWorkspace || []
              )}
              selectedWorkspace={selectableWorkspace?.filter((workspaces) => {
                return workspaces.selected;
              })}
              onSelect={onSelect}
              openConfirm={openDialog}
              setOpenConfirm={setOpenDialog}
            />
          </DeleteScreenWrapper>
        </DeleteScreenContainer>
      </Layout>
    </>
  );
};

export default DeleteWorkspacesScreen;
