import styled from "@emotion/styled";

export const RegisterResultModalTextContainer = styled.div`
  display: flex;
  display: grid;
  grid-template-columns: 1.5fr 3fr;
  margin-bottom: 1em;
`;

export const RegisterResultModalLabel = styled.span`
  color: ${(props) => props.theme.colors.onBackGround};
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: 500;
`;
export const RegisterResultModalText = styled(RegisterResultModalLabel)`
  font-weight: 400;
  word-break: break-all;
`;

export const RegisterModalItem = styled.li`
  display: grid;
  justify-content: start;
  grid-template-columns: 1fr 2fr 0.5fr;
  color: ${(props) => props.theme.colors.onBackGround};
  align-items: start;
  padding: 16px 0;
`;
