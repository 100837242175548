import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { mainTheme } from "@data/theme/theme";
import DefaultButton from "@ui/components/Button/DefaultButton";
import BackToTopButton from "@ui/components/Button/BackToTopButton/BackToTopButton";

import {
  GridSpacer,
  FooterButtonContainer,
  FooterContainer,
} from "./workspacesFooterStyle";

const RenderFooter: FC = () => {
  const navigate = useNavigate();

  return (
    <FooterContainer>
      <BackToTopButton />
      <GridSpacer />
      <FooterButtonContainer>
        <DefaultButton
          backgroundColor={mainTheme.colors.primaryPositive}
          hoverColor={mainTheme.colors.primary}
          buttonText={"走行環境削除"}
          size={"M"}
          color={mainTheme.colors.onPrimary}
          onClick={() => {
            navigate("delete");
          }}
          contentWidth="144px"
        />
      </FooterButtonContainer>

      <GridSpacer />
    </FooterContainer>
  );
};

export default RenderFooter;
