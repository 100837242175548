import React, { FC, useState } from "react";
import { Popover } from "@mantine/core";

import { mainTheme } from "@data/theme/theme";
import DefaultButton from "@ui/components/Button/DefaultButton";
import { ACTIVE } from "@data/constants/entity";
import ChangeActiveButton from "@ui/components/Button/ChangActiveButton/ChangeActiveButton";
import { Vehicle } from "@data/entities/Vehicle/Vehicle";
import DefaultIconButton from "@ui/components/Button/IconButton/iconButton";
import { IconNotification } from "@tabler/icons-react";
import DefaultCopyButton from "@ui/components/Button/CopyButton/DefaultCopyButton";
import { GetVehicle } from "@data/repositories/Vehicle/queryVehicle";

import {
  VehicleListItem,
  VehicleListText,
  VehicleListButtonContainer,
  GridSpacer,
  PopoverTitle,
  PopoverText,
} from "./vehicleItemStyle";

type Props = {
  vehicle: GetVehicle;
  onClickNavigate: (vehicle: Vehicle) => void;
  onSUbmitActive: (vehicle: Vehicle) => void;
};

const VehicleItem: FC<Props> = ({
  vehicle,
  onClickNavigate,
  onSUbmitActive,
}) => {
  const { vehicle_id, alias_name, is_active, workspace_m, dash_token } =
    vehicle;
  const [opened, setOpened] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);

  return (
    <Popover
      withArrow
      arrowSize={10}
      opened={popoverOpen}
      onClose={() => {
        setPopoverOpen(false);
      }}
      shadow="sm"
      zIndex={1}
    >
      <Popover.Target>
        <VehicleListItem>
          <GridSpacer />
          <VehicleListText>{vehicle_id}</VehicleListText>
          <VehicleListText>{alias_name}</VehicleListText>
          <VehicleListText>{workspace_m?.workspace_nm}</VehicleListText>
          <VehicleListButtonContainer>
            <DefaultButton
              backgroundColor={mainTheme.colors.primaryPositive}
              hoverColor={mainTheme.colors.primary}
              color={mainTheme.colors.onPrimary}
              onClick={() => onClickNavigate(vehicle)}
              buttonText={"環境変更"}
              size={"S"}
            />
            <div>
              <ChangeActiveButton
                backgroundColor={
                  is_active === ACTIVE.ENABLE
                    ? mainTheme.colors.primaryPositive
                    : mainTheme.colors.grayOut
                }
                hoverColor={
                  is_active === ACTIVE.ENABLE
                    ? mainTheme.colors.primary
                    : mainTheme.colors.onBackGroundPositive
                }
                color={mainTheme.colors.onPrimary}
                onClick={() => {
                  setOpened(!opened);
                }}
                buttonText={is_active === ACTIVE.ENABLE ? "有効" : "無効"}
                contentWidth="72px"
                size={"S"}
                opened={opened}
                onClose={() => setOpened(false)}
                dialogTitle="有効・無効切替"
                dialogDescription={
                  is_active === ACTIVE.ENABLE
                    ? "無効化しますか？"
                    : "有効化しますか？"
                }
                onConfirm={() => {
                  onSUbmitActive(vehicle);
                }}
              />
            </div>
            <DefaultIconButton
              label="DASHトークン"
              size="xl"
              onClick={() => {
                setPopoverOpen(!popoverOpen);
              }}
              Icon={
                <IconNotification
                  size={"4rem"}
                  color={mainTheme.colors.primaryPositive}
                />
              }
            />
          </VehicleListButtonContainer>
          <GridSpacer />
        </VehicleListItem>
      </Popover.Target>
      <Popover.Dropdown sx={{ pointerEvents: "none" }}>
        <PopoverTitle
          style={{
            color: `${mainTheme.colors.onBackGround}`,
            fontWeight: 700,
            textAlign: "start",
          }}
        >
          DASHトークン
        </PopoverTitle>
        <div
          style={{
            display: "flex",
            gap: "1rem",
          }}
        >
          <PopoverText>{dash_token}</PopoverText>
          {dash_token && <DefaultCopyButton value={dash_token} />}
        </div>
      </Popover.Dropdown>
    </Popover>
  );
};

export default VehicleItem;
