import { FC } from "react";
import { START_VEHICLE } from "@data/constants/entity";
import {
  AllowedStartContainer,
  AllowedStartWrapper,
} from "./allowStartVehicle";

type Props = {
  is_allowed: START_VEHICLE;
  disabled?: boolean;
};

const start_vehicle: { [key in START_VEHICLE]: string } = {
  [START_VEHICLE.ALLOW]: "あり",
  [START_VEHICLE.DISALLOW]: "なし",
};

export const AllowStartVehicle: FC<Props> = ({ is_allowed, disabled }) => {
  return (
    <>
      <AllowedStartContainer>
        <div>
          <AllowedStartWrapper start_vehicle={is_allowed} disabled={disabled}>
            {start_vehicle[is_allowed]}
          </AllowedStartWrapper>
        </div>
      </AllowedStartContainer>
    </>
  );
};
