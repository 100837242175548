import React, { FC, useState } from "react";

import { mainTheme } from "@data/theme/theme";
import { ACTIVE } from "@data/constants/entity";
import { WorkspaceWitchCompany } from "@data/types";
import ChangeActiveButton from "@ui/components/Button/ChangActiveButton/ChangeActiveButton";

import {
  WorkspaceListItem,
  WorkspaceListText,
  ButtonContainer,
  GridSpacer,
} from "./workspaceItemStyle";

type Props = {
  workspace: WorkspaceWitchCompany;
  onClickNavigate: (workspace: WorkspaceWitchCompany) => void;
  onSubmitActive: (user: WorkspaceWitchCompany) => void;
};

const WorkspaceItem: FC<Props> = ({
  workspace,
  onClickNavigate,
  onSubmitActive,
}) => {
  const { workspace_nm, workspace_cd, is_active, company_m } = workspace;
  const [opened, setOpened] = useState(false);

  return (
    <WorkspaceListItem>
      <GridSpacer />
      <WorkspaceListText>{workspace_nm}</WorkspaceListText>
      <WorkspaceListText>{workspace_cd}</WorkspaceListText>
      <WorkspaceListText>{company_m?.company_nm}</WorkspaceListText>
      <WorkspaceListText>{company_m?.fms_project_id}</WorkspaceListText>
      <ButtonContainer>
        <ChangeActiveButton
          backgroundColor={
            is_active === ACTIVE.ENABLE
              ? mainTheme.colors.primaryPositive
              : mainTheme.colors.grayOut
          }
          hoverColor={
            is_active === ACTIVE.ENABLE
              ? mainTheme.colors.primary
              : mainTheme.colors.onBackGroundPositive
          }
          color={mainTheme.colors.onPrimary}
          onClick={() => {
            setOpened(!opened);
          }}
          buttonText={is_active === ACTIVE.ENABLE ? "有効" : "無効"}
          contentWidth="72px"
          size={"S"}
          opened={opened}
          onClose={() => setOpened(false)}
          dialogTitle="有効・無効切替"
          dialogDescription={
            is_active === ACTIVE.ENABLE
              ? "無効化しますか？"
              : "有効化しますか？"
          }
          onConfirm={() => onSubmitActive(workspace)}
        />
        {/* <DefaultButton
          backgroundColor={
            is_active === ACTIVE.ENABLE
              ? mainTheme.colors.primaryPositive
              : mainTheme.colors.grayOut
          }
          hoverColor={
            is_active === ACTIVE.ENABLE
              ? mainTheme.colors.primary
              : mainTheme.colors.onBackGroundPositive
          }
          color={mainTheme.colors.onPrimary}
          onClick={() => {}}
          buttonText={is_active === ACTIVE.ENABLE ? "有効" : "無効"}
          contentWidth="72px"
          size={"S"}
        /> */}
      </ButtonContainer>
      <GridSpacer />
    </WorkspaceListItem>
  );
};

export default WorkspaceItem;
