import { Company } from "@data/entities/Company/Company";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { refreshSession } from "../Auth/auth";

export type GetCompany = Pick<
  Company,
  "company_id" | "company_nm" | "fms_project_id"
>;
const HOST = `${process.env.REACT_APP_ENDPOINT}`;

export const queryCompany = () => {
  const useCompanyList = () => {
    const fetchCompanies = async (): Promise<GetCompany[]> => {
      const url = `${HOST}/companies`;
      const { data } = await axios.get<{ data: GetCompany[] }>(url, {
        headers: {
          Authorization: await refreshSession(),
        },
      });
      return data.data;
    };
    return useQuery(["companies"], fetchCompanies);
  };

  return {
    useCompanyList,
  };
};
