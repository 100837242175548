import { FC, ReactNode } from "react";

import { LoadingNotification } from "@ui/components/LoadingNotification/LoadingNotification";

type Poops = {
  loading?: boolean;
  isError?: boolean;
  isSkip?: boolean;
  title?: string;
  idCheckError?: boolean;
  check?: boolean;
};

type Text = {
  isError: string;
  isSkip: string;
  loading: string;
  idCheckError: string;
  check: string;
};

const VehicleIdNotification: FC<Poops> = ({
  loading,
  isError,
  isSkip,
  title,
  idCheckError,
  check,
}) => {
  const renderText = (value: keyof Text): ReactNode => {
    const text = {
      isError: <p></p>,
      isSkip: <span>FMS車両確認対象外</span>,
      loading: <p></p>,
      idCheckError: <span>FMS車両IDを修正して下さい</span>,
      check: <span>値は正常です</span>,
    };
    return text[value];
  };
  return (
    <>
      <LoadingNotification
        withCloseButton={false}
        loading={loading}
        isError={isError || idCheckError}
        isSkip={isSkip}
        title={title}
      >
        {!isSkip && loading && renderText("loading")}
        {!isSkip && isError && !idCheckError && renderText("isError")}
        {!isSkip && idCheckError && renderText("idCheckError")}
        {isSkip && renderText("isSkip")}
        {!isSkip && !loading && check && renderText("check")}
      </LoadingNotification>
    </>
  );
};

export default VehicleIdNotification;
