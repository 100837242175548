import { Company } from "@data/entities/Company/Company";
import { Workspace } from "@data/entities/Workspace/Workspace";
import { WorkspaceWitchCompany } from "@data/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { refreshSession } from "../Auth/auth";

export type ResponseWorkspace = Pick<
  Workspace,
  "workspace_cd" | "workspace_nm" | "fms_environment_id" | "admin_password"
> &
  Pick<Company, "company_id" | "company_nm" | "fms_project_id">;

const HOST = `${process.env.REACT_APP_ENDPOINT}`;

export const useMutationWorkspace = () => {
  const queryClient = useQueryClient();

  /// 走行環境登録
  const postWorkspace = async ({
    workspace,
    company,
  }: {
    workspace: Workspace;
    company: Company;
  }) => {
    const url = `${HOST}/workspaces`;
    const prams = company.company_id
      ? {
          company_id: company.company_id,
          fms_project_id: company.fms_project_id,
          workspace_nm: workspace.workspace_nm,
          fms_environment_id: workspace.fms_environment_id,
        }
      : {
          company_nm: company.company_nm,
          fms_project_id: company.fms_project_id,
          workspace_nm: workspace.workspace_nm,
          fms_environment_id: workspace.fms_environment_id,
        };
    const { data } = await axios.post<{ data: ResponseWorkspace }>(
      url,
      {
        ...prams,
      },
      {
        headers: {
          Authorization: await refreshSession(),
        },
      }
    );
    return data.data;
  };
  const registerWorkspace = useMutation(
    (variables: { workspace: Workspace; company: Company }) =>
      postWorkspace({ ...variables })
  );

  /// 走行環境有効・無効切替
  const patchWorkspaceActive = async (workspace: Workspace) => {
    const url = `${HOST}/workspaces/${workspace.workspace_cd}/is_active`;
    await axios.patch(
      url,
      {
        is_active: workspace.is_active,
      },
      {
        headers: {
          Authorization: await refreshSession(),
        },
      }
    );
  };
  const updateWorkspaceActive = useMutation(
    (variables: Workspace) => {
      return patchWorkspaceActive(variables);
    },
    {
      onSuccess(data, variables, context) {
        queryClient.setQueryData<WorkspaceWitchCompany[]>(
          ["workspaces"],
          (olds) =>
            olds?.map((old) => {
              return old.workspace_cd === variables.workspace_cd
                ? {
                    ...old,
                    is_active: variables.is_active === 0 ? 1 : 0,
                  }
                : old;
            })
        );
      },
    }
  );

  /// 走行環境まとめて削除
  const deleteWorkspaces = async (
    workspace_cds: Workspace["workspace_cd"][]
  ) => {
    const url = `${HOST}/workspaces`;
    await axios.delete(url, {
      data: {
        workspaces: workspace_cds,
      },
      headers: {
        Authorization: await refreshSession(),
      },
    });
  };
  const deleteWorkspaceMutation = useMutation(
    (variables: Workspace["workspace_cd"][]) => {
      return deleteWorkspaces(variables);
    }
  );

  return {
    registerWorkspace,
    updateWorkspaceActive,
    deleteWorkspaceMutation,
  };
};
