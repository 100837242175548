import { FC, memo, useCallback, useState } from "react";

import { List, ListContainer } from "./deleteVehiclesWidgetStyle";
import ListItem from "./components/ListItem";
import RenderFooter from "./components/DeleteItemFooter";

import Footer from "@ui/components/Footer/Footer";
import { DefaultLoadingOverlay } from "@ui/components/LoadingOverlay/DefualtLoadingOverlay";
import { GetVehicle } from "@data/repositories/Vehicle/queryVehicle";
import { useMutationVehicle } from "@data/repositories/Vehicle/mutationVehicle";
import DeleteVehicleModal from "@ui/modals/DeleteVehicleModal";
import DeleteConfirmVehicleModal from "@ui/modals/DeleteConfirmVehicleModal";

type Props = {
  vehicles: (GetVehicle & { selected: boolean })[];
  selectedVehicles?: (GetVehicle & { selected: boolean })[];
  onSelect: (item: GetVehicle & { selected: boolean }) => void;
  openConfirm: boolean;
  setOpenConfirm: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DeleteVehiclesWidget: FC<Props> = ({
  vehicles,
  selectedVehicles = [],
  onSelect,
  openConfirm,
  setOpenConfirm,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const { deleteVehiclesMutation } = useMutationVehicle();

  const deleteIds = () => {
    const ids = selectedVehicles.map((filtered) => {
      return filtered.vehicle_id;
    });
    return ids;
  };

  const onSubmit = useCallback(() => {
    deleteVehiclesMutation.mutate(deleteIds(), {
      onSuccess: () => {
        setModalOpen(true);
      },
      onError: () => {
        setOpenConfirm(false);
        setModalOpen(true);
      },
    });
  }, [deleteIds()]);

  return (
    <>
      <ListContainer>
        <List>
          {vehicles.length > 0 &&
            vehicles.map((vehicle, index) => (
              <ListItem
                item={vehicle}
                onClick={onSelect}
                key={index}
              />
            ))}
        </List>
      </ListContainer>
      <Footer>
        <RenderFooter
          onOpenDialog={useCallback(() => {
            if (selectedVehicles.length === 0) {
              alert("項目が選択されていません");
              return;
            }
            setOpenConfirm(true);
          }, [selectedVehicles])}
        />
      </Footer>

      {openConfirm && (
        <DeleteConfirmVehicleModal
          isOpen={openConfirm}
          onRequestClose={() => setOpenConfirm(false)}
          onSubmit={onSubmit}
          deletedVehicles={selectedVehicles}
        />
      )}

      {modalOpen && (
        <DeleteVehicleModal
          isOpen={modalOpen}
          onRequestClose={() => {
            //setModalOpen(false);
          }}
          isError={deleteVehiclesMutation.isError}
          error={deleteVehiclesMutation.error}
        />
      )}

      <DefaultLoadingOverlay
        visible={deleteVehiclesMutation.isLoading}
        loaderSize="lg"
      />
    </>
  );
};

export default memo(DeleteVehiclesWidget);
