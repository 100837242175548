/**
 * ユーザ認証リポジトリ切り替え用
 * モックを使用する場合は mockAuthRepository の refreshSession をエクスポートする。
 * Cognitoを使用する場合は amplifyAuthRepository の refreshSession をエクスポートする。
 */
import {refreshSession as cognitoRefreshSession} from "@data/repositories/Auth/cognitoAuthRepository";
import {refreshSession as mockRefreshSession} from "@data/repositories/Auth/mockAuthRepository";

export const is_authentication_method_mock = () => {
  const authEndPoint = `${process.env.REACT_APP_AUTH_ENDPOINT}`;
  return authEndPoint !== 'undefined';
};

const refreshSession = is_authentication_method_mock() ? mockRefreshSession : cognitoRefreshSession;
export {refreshSession};
