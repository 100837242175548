import React from "react";
import DefaultButton from "../DefaultButton";
import { mainTheme } from "@data/theme/theme";
import { useNavigate } from "react-router-dom";
import { BackToTopButtonContainer } from "./backToTopButtonStyle";

const BackToTopButton = () => {
  const navigate = useNavigate();
  return (
    <>
      <BackToTopButtonContainer>
        <DefaultButton
          backgroundColor={mainTheme.colors.background}
          hoverColor={mainTheme.colors.third}
          buttonText={"TOPへ"}
          size={"M"}
          color={mainTheme.colors.onThird}
          onClick={() => {
            navigate("/");
          }}
        />
      </BackToTopButtonContainer>
    </>
  );
};

export default BackToTopButton;
