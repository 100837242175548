import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { EditedVehicle, PostVehicle } from "@data/types";
import { Vehicle } from "@data/entities/Vehicle/Vehicle";
import { GetVehicle } from "./queryVehicle";
import { Company } from "@data/entities/Company/Company";
import { Workspace } from "@data/entities/Workspace/Workspace";
import { refreshSession } from "../Auth/auth";
import { EditedVehicleEnvironment } from "@ui/widgets/VehicleDetailWidget";

const HOST = `${process.env.REACT_APP_ENDPOINT}`;

export type UpdateVehiclesEnvironment = Pick<
  Vehicle,
  "vehicle_id" | "alias_name"
> &
  Pick<Company, "company_nm"> &
  Pick<Workspace, "workspace_nm">;

export const useMutationVehicle = () => {
  const queryClient = useQueryClient();

  /// 車両登録
  const postVehicle = async ({ vehicle }: { vehicle: EditedVehicle }) => {
    const url = `${HOST}/vehicles`;
    const { data } = await axios.post<{ data: PostVehicle }>(
      url,
      {
        company_id: vehicle.company_m.company_id,
        internal_workspace_cd: vehicle.workspace_m.workspace_cd,
        vehicle_id: vehicle.vehicle_id,
        workspace_cd: vehicle.user_m.workspace_cd,
        alias_name: vehicle.alias_name,
      },
      {
        headers: {
          Authorization: await refreshSession(),
        },
      }
    );
    return data.data;
  };
  const registerVehicle = useMutation(
    (variables: { vehicle: EditedVehicle; deleteFlag: 0 | 1 }) =>
      postVehicle({ ...variables })
  );

  /// 車両有効・無効切替
  const patchVehicleActive = async (vehicle: Vehicle) => {
    const url = `${HOST}/vehicles/${vehicle.vehicle_id}/is_active`;
    await axios.patch(
      url,
      {
        is_active: vehicle.is_active,
      },
      {
        headers: {
          Authorization: await refreshSession(),
        },
      }
    );
  };
  const updateVehicleActive = useMutation(
    (variables: Vehicle) => {
      return patchVehicleActive(variables);
    },
    {
      onSuccess(data, variables, context) {
        queryClient.setQueryData<GetVehicle[]>(["vehicles"], (olds) =>
          olds?.map((old) => {
            return old.vehicle_id === variables.vehicle_id
              ? {
                  ...old,
                  is_active: variables.is_active === 0 ? 1 : 0,
                }
              : old;
          })
        );
      },
    }
  );

  /// 車両走行環境切替
  const patchVehicleEnvironment = async (vehicle: EditedVehicleEnvironment) => {
    const url = `${HOST}/vehicles/${vehicle.vehicle_id}/environment`;
    const { data } = await axios.patch<{ data: UpdateVehiclesEnvironment }>(
      url,
      {
        vehicle_id: vehicle.next_vehicle_id,
        workspace_cd: vehicle.workspace_m.workspace_cd,
      },
      {
        headers: {
          Authorization: await refreshSession(),
        },
      }
    );
    return data.data;
  };
  const updateVehicleEnvironment = useMutation(
    (variables: EditedVehicleEnvironment) => {
      return patchVehicleEnvironment(variables);
    }
  );

  /// 車両まとめて削除
  const deleteVehicles = async (vehicle_ids: Vehicle["vehicle_id"][]) => {
    const url = `${HOST}/vehicles`;
    await axios.delete(url, {
      data: {
        vehicles: vehicle_ids,
      },
      headers: {
        Authorization: await refreshSession(),
      },
    });
  };
  const deleteVehiclesMutation = useMutation(
    (variables: Vehicle["vehicle_id"][]) => {
      return deleteVehicles(variables);
    }
  );

  return {
    registerVehicle,
    updateVehicleActive,
    updateVehicleEnvironment,
    deleteVehiclesMutation,
  };
};
