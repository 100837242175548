import React, { FC } from "react";
import { Divider } from "@mantine/core";

import { UpdateVehiclesEnvironment } from "@data/repositories/Vehicle/mutationVehicle";

import {
  VehicleDetailModalLabel,
  VehicleDetailModalText,
  VehicleDetailModalItem,
} from "./modalContentStyle";

type Props = {
  vehicle?: UpdateVehiclesEnvironment;
};

export const ModalContent: FC<Props> = ({ vehicle }) => {
  return (
    <>
      <VehicleDetailModalItem>
        <VehicleDetailModalLabel>FMS車両ID </VehicleDetailModalLabel>
        <VehicleDetailModalText>{vehicle?.vehicle_id}</VehicleDetailModalText>
      </VehicleDetailModalItem>

      <Divider />

      <VehicleDetailModalItem>
        <VehicleDetailModalLabel>車両名</VehicleDetailModalLabel>
        <VehicleDetailModalText>{vehicle?.alias_name}</VehicleDetailModalText>
      </VehicleDetailModalItem>

      <Divider />

      <VehicleDetailModalItem>
        <VehicleDetailModalLabel>走行環境名</VehicleDetailModalLabel>
        <VehicleDetailModalText>{vehicle?.workspace_nm}</VehicleDetailModalText>
      </VehicleDetailModalItem>

      <Divider />

      <VehicleDetailModalItem>
        <VehicleDetailModalLabel>会社名</VehicleDetailModalLabel>
        <VehicleDetailModalText>{vehicle?.company_nm}</VehicleDetailModalText>
      </VehicleDetailModalItem>
    </>
  );
};
