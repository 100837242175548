import styled from "@emotion/styled";
import { SizeProp } from "../../../@types/theme";

type Diver = "dashed" | "dotted" | "solid";

export const StyledDivider = styled.hr<{
  thin: SizeProp;
  dividerStyle: Diver;
  rounded?: true;
}>`
  width: 100%;
  border-top-width: ${({ thin }) => thin};
  border-top-color: ${(props) => props.theme.colors.outline};
  border-top-style: ${({ dividerStyle }) => dividerStyle};
  border-radius: ${({ rounded }) => (rounded ? "5px" : "")};
  margin-block-start: 0;
  margin-block-end: 0;
`;
