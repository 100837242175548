import { mainTheme } from "@data/theme/theme";
import { LoadingOverlay, MantineSize } from "@mantine/core";
import React, { FC } from "react";
import { LoadingText, LoaderWrapper } from "./defaultLoadingOverlayStyle";

type Props = {
  loaderSize?: MantineSize;
  text?: string;
  visible: boolean;
};

export const DefaultLoadingOverlay: FC<Props> = ({
  loaderSize = "sm",
  text = "",
  visible,
}) => {
  const customLoader = (
    <>
      <LoaderWrapper>
        <LoadingText>{text}</LoadingText>
        <svg
          width="54"
          height="54"
          viewBox="0 0 38 38"
          xmlns="http://www.w3.org/2000/svg"
          stroke={`${mainTheme.colors.primary}`}
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(1 1)" strokeWidth="2">
              <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="1s"
                  repeatCount="indefinite"
                />
              </path>
            </g>
          </g>
        </svg>
      </LoaderWrapper>
    </>
  );
  return (
    <>
      <div>
        <LoadingOverlay
          visible={visible}
          overlayBlur={2}
          loader={customLoader}
          loaderProps={{
            size: loaderSize,
            color: `${mainTheme.colors.primary}`,
            variant: "oval",
          }}
          overlayOpacity={0.3}
        />
      </div>
    </>
  );
};
