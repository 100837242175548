import { mainTheme } from "@data/theme/theme";
import { Checkbox, MantineSize } from "@mantine/core";
import React, { FC } from "react";

type Props = {
  labelPosition?: "left" | "right";
  label?: string;
  description?: string;
  size?: MantineSize;
  checked?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

export const DefaultCheckbox: FC<Props> = ({
  labelPosition,
  label,
  description,
  size = "md",
  checked,
  onChange,
}) => {
  return (
    <>
      <Checkbox
        labelPosition={labelPosition}
        label={label}
        description={description}
        size={size}
        checked={checked}
        onChange={onChange}
        styles={{
          icon: {
            backgroundColor: `${mainTheme.colors.primary}`,
          },
          input: {
            ":checked": {
              background: `${mainTheme.colors.primary}`,
              borderColor: `${mainTheme.colors.primary}`,
            },
          },
          label: {
            color: `${mainTheme.colors.onBackGround}`,
            fontWeight: 700,
          },
        }}
      />
    </>
  );
};
