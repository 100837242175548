import styled from "@emotion/styled";
import {
  VehicleListTitle,
  VehiclesHeader,
  VehiclesScreenContainer,
  VehiclesScreenWrapper,
} from "../VehiclesScreen/vehiclesScreenStyle";
import { VehiclesListContainer } from "@ui/widgets/VehiclesWidget/vehiclesWidgetStyle";

export const WorkspacesScreenContainer = styled(VehiclesScreenContainer)``;

export const WorkspaceScreenWrapper = styled(VehiclesScreenWrapper)``;

export const WorkspacesHeader = styled(VehiclesHeader)`
  justify-content: space-between;
`;

export const WorkspaceListTitle = styled(VehicleListTitle)`
  grid-template-columns: 0.2fr 1fr 1fr 1fr 1fr 1fr 0.2fr;
  color: ${(props) => props.theme.colors.onBackGround};
  font-weight: 600;
`;

export const WorkspaceListContainer = styled(VehiclesListContainer)``;

export const WorkspaceListITitleText = styled.span``;

export const GridSpacer = styled.div``;

export const SearchContainer = styled.div`
  width: 20rem;
`;
