import styled from "@emotion/styled";
import {
  VehiclesHeader,
  VehiclesScreenContainer,
  VehiclesScreenWrapper,
} from "../../VehiclesScreen/vehiclesScreenStyle";

export const userGrid = "0.2fr 1fr 1fr 1fr 1fr 1fr 0.2fr";

export const DeleteScreenContainer = styled(VehiclesScreenContainer)``;

export const DeleteScreenWrapper = styled(VehiclesScreenWrapper)``;

export const DeleteScreenHeader = styled(VehiclesHeader)`
  width: 100%;
  display: flex;
  justify-content: end;
`;

export const DeleteScreenListTitle = styled.div`
  flex: 1;
  width: 100%;
  align-items: flex-end;
  padding-bottom: 0.5em;
  display: grid;
  white-space: nowrap;
  grid-template-columns: ${userGrid};
  color: ${(props) => props.theme.colors.onBackGround};
  font-weight: 600;
`;

export const UserListContainer = styled.div`
  flex: 12;
  width: 100%;
  overflow-y: scroll;
  /// scroll-bar 消去 edge
  -ms-overflow-style: none;
  /// scroll-bar 消去 chrome, safari
  &::-webkit-scrollbar {
    display: none;
  }
  /// scroll-bar 消去 firefox
  scrollbar-width: none;
`;

export const DashMenuFooter = styled.footer`
  height: 20%;
  background-color: ${(props) => props.theme.colors.background};
`;

export const DeleteScreenListITitleText = styled.span``;

export const GridSpacer = styled.div``;

export const SearchContainer = styled.div`
  width: 20rem;
`;

export const SearchLabel = styled.span`
  color: ${({ theme }) => theme.colors.onBackGround};
`;
