import { MantineSize } from "@mantine/core";
import Dropdown from "@ui/components/Dropdown/Dropdown";
import React, { FC, memo } from "react";
import { GridWrapper } from "../../registerVehicleWidgetStyle";
import { DropDownOption } from "@data/types";

type Props = {
  label?: string;
  labelId?: string;
  placeholder?: string;
  options?: DropDownOption[];
  size?: MantineSize;
  required?: true;
  withAsterisk?: true;
  onSelect?: (selectedValue: string | null) => void;
  onClick?: () => void;
  headLabel: string;
  errorMessage?: string;
};

const CompanyFormDropdownMemo: FC<Props> = ({
  label = "",
  placeholder = "",
  options = [],
  size = "md",
  required,
  withAsterisk,
  onSelect,
  onClick,
  headLabel,
  errorMessage,
}) => {
  return (
    <>
      <GridWrapper>
        <Dropdown
          required={required}
          withAsterisk={withAsterisk}
          label={label}
          placeholder={placeholder}
          options={options}
          size={size}
          onSelect={onSelect}
          onClick={onClick}
          errorMessage={errorMessage}
        />
      </GridWrapper>
    </>
  );
};

export const CompanyFormDropdown = memo(CompanyFormDropdownMemo);
