import React, { FC } from "react";
import { mainTheme } from "../../../../../data/theme/theme";
import { HeaderContainer, HeaderItem, HeaderTitle, HeaderSignOut } from "./headerStyle";
import SignOutButton from "@ui/components/Button/SignOutButton/SignOutButton";

type Props = {
  title: string;
};

export const Header: FC<Props> = ({ title }) => {
  return (
    <HeaderContainer theme={mainTheme}>
      <HeaderItem grow={1}>
        <HeaderTitle theme={mainTheme} data-testid="title">
          {title}
        </HeaderTitle>
      </HeaderItem>
      <HeaderItem grow={2}>
        <HeaderSignOut>
          <SignOutButton />
        </HeaderSignOut>
      </HeaderItem>
    </HeaderContainer>
  );
};
