import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { mainTheme } from "@data/theme/theme";
import DefaultButton from "@ui/components/Button/DefaultButton";
import Divider from "@ui/components/Divider/Divider";
import Layout from "@ui/components/Layout";
import UsersWidget from "@ui/widgets/UsersWidget";
import { GetUser, queryUser } from "@data/repositories/User/queryUser";
import Footer from "@ui/components/Footer/Footer";

import {
  UserListTitle,
  UsersScreenContainer,
  UsersHeader,
  UserListContainer,
  GridSpacer,
  WorkspaceListITitleText,
  UsersScreenWrapper,
  SearchContainer,
} from "./usersScreenStyle";
import RenderFooter from "./UsersFooter/UsersFooter";
import InputText from "@ui/components/Input/InputText";
import { IconSearch } from "@tabler/icons-react";

const UsersScreen = () => {
  const navigate = useNavigate();
  const { useFrontUserList } = queryUser();

  const { data: users, isError: usersError } = useFrontUserList();

  useEffect(() => {
    if (usersError) {
      alert("データの取得に失敗しました");
    }
  }, [usersError]);

  const [search, setSearch] = useState("");
  function filterData(data: GetUser[], search: string) {
    const query = search.toLowerCase().trim();
    return query.length > 0
      ? data.filter(
          (item) =>
            item.workspace_cd?.toLowerCase().includes(query) ||
            item.workspace_m.workspace_nm
              ?.toLocaleLowerCase()
              .includes(query) ||
            item.company_m.company_nm?.toLocaleLowerCase().includes(query)
        )
      : data;
  }

  return (
    <Layout title="ユーザ一覧">
      <UsersScreenContainer>
        <GridSpacer />
        <UsersScreenWrapper>
          <UsersHeader>
            <DefaultButton
              backgroundColor={mainTheme.colors.primaryPositive}
              hoverColor={mainTheme.colors.primary}
              buttonText={"ユーザ新規登録"}
              size={"M"}
              color={mainTheme.colors.onPrimary}
              align={"center"}
              onClick={() => {
                navigate("register", { state: { title: "ユーザ：新規登録" } });
              }}
            />
            <SearchContainer>
              <InputText
                label="検索"
                value={search}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setSearch(e.currentTarget.value)
                }
                placeholder="入力値で検索"
                size="md"
                icon={<IconSearch size={"1.2rem"} />}
              />
              {/* <DefaultMultiSelect
                label="走行環境名"
                data={dropdownList}
                maxSelectedValues={1}
                placeholder="走行環境名で検索"
                onChange={(e) => {
                  setSelectedValue(e);
                }}
              /> */}
            </SearchContainer>
          </UsersHeader>

          <UserListTitle>
            <GridSpacer />
            <WorkspaceListITitleText>ログインID</WorkspaceListITitleText>
            <WorkspaceListITitleText data-testid="span">
              走行環境名
            </WorkspaceListITitleText>
            <WorkspaceListITitleText>発進指示</WorkspaceListITitleText>
            <WorkspaceListITitleText>会社名</WorkspaceListITitleText>
            <GridSpacer />
          </UserListTitle>
          <Divider thin="1px" rounded />

          <UserListContainer>
            {users && <UsersWidget users={filterData(users, search)} />}
          </UserListContainer>

          <Footer>
            <RenderFooter />
          </Footer>
        </UsersScreenWrapper>
      </UsersScreenContainer>
    </Layout>
  );
};

export default UsersScreen;
