import React, { useEffect, useMemo, useState } from "react";

import Layout from "@ui/components/Layout";
import Divider from "@ui/components/Divider/Divider";
import { ACTIVE } from "@data/constants/entity";
import { queryWorkspace } from "@data/repositories/Workspace/queryWorkspace";
import DefaultMultiSelect from "@ui/components/Dropdown/MultiSelect/MultiSelect";
import {
  GetVehicle,
  queryVehicle,
} from "@data/repositories/Vehicle/queryVehicle";

import {
  GridSpacer,
  SearchContainer,
  DeleteScreenListTitle,
  DeleteScreenHeader,
  DeleteScreenContainer,
  DeleteScreenWrapper,
  DeleteScreenListITitleText,
} from "./deleteVehiclesScreenStyle";
import DeleteVehiclesWidget from "@ui/widgets/DeleteVehiclesWidget";

type SelectableVehicle = GetVehicle & {
  selected: boolean;
};

const DeleteVehiclesScreen = () => {
  const [selectedValue, setSelectedValue] = useState<string[]>([]);
  const [selectableVehicle, setSelectableVehicle] =
    useState<SelectableVehicle[]>();
  const [openConfirm, setOpenConfirm] = useState(false);

  const { useWorkspaceDropdown } = queryWorkspace();
  const { useVehicleList } = queryVehicle();

  const { data: vehicles, isError: vehiclesError } = useVehicleList();
  const { data: dropdownProp, isError: dropdownPropError } =
    useWorkspaceDropdown();

  const noActiveVehicles = (vehicles: GetVehicle[]): SelectableVehicle[] => {
    const filtered = vehicles?.filter((vehicle) => {
      return vehicle.is_active === ACTIVE.DISABLE;
    });

    return filtered.map((vehicle) => {
      return {
        ...vehicle,
        selected: false,
      };
    });
  };

  useMemo(() => {
    setSelectableVehicle(noActiveVehicles(vehicles || []));
  }, [vehicles]);

  const onSelect = (item: GetVehicle & { selected: boolean }) => {
    setSelectableVehicle(
      selectableVehicle?.map((vehicle) => {
        return item.vehicle_id === vehicle.vehicle_id
          ? {
              ...vehicle,
              selected: !vehicle.selected,
            }
          : { ...vehicle };
      })
    );
  };

  const dropdownList = dropdownProp
    ? dropdownProp.map((workspace) => {
        return {
          value: workspace.workspace_nm || "",
          label: workspace.workspace_nm || "",
          group: "走行環境名",
        };
      })
    : [];

  const filterVehicles = (
    machs: string[],
    vehicles: SelectableVehicle[]
  ): SelectableVehicle[] => {
    if (!vehicles) return [];

    return vehicles.filter((vehicle) => {
      return machs.length > 0
        ? machs.includes(vehicle.workspace_m.workspace_nm || "")
        : true;
    });
  };

  useEffect(() => {
    if (vehiclesError || dropdownPropError) {
      alert("データの取得に失敗しました");
    }
  }, [vehiclesError, dropdownPropError]);

  return (
    <>
      <Layout title="車両削除選択">
        <DeleteScreenContainer>
          <GridSpacer />
          <DeleteScreenWrapper>
            <DeleteScreenHeader>
              <SearchContainer>
                <DefaultMultiSelect
                  label="走行環境名"
                  data={dropdownList}
                  maxSelectedValues={1}
                  placeholder="走行環境名で検索"
                  onChange={(e) => {
                    setSelectedValue(e);
                  }}
                  onClick={() => {
                    setOpenConfirm(false);
                  }}
                />
              </SearchContainer>
            </DeleteScreenHeader>

            <DeleteScreenListTitle>
              <GridSpacer />
              <DeleteScreenListITitleText>削除</DeleteScreenListITitleText>
              <DeleteScreenListITitleText>車両ID</DeleteScreenListITitleText>
              <DeleteScreenListITitleText>車両名</DeleteScreenListITitleText>
              <DeleteScreenListITitleText>走行環境名</DeleteScreenListITitleText>
              <GridSpacer />
            </DeleteScreenListTitle>
            <Divider thin="1px" rounded />

            <DeleteVehiclesWidget
              vehicles={filterVehicles(selectedValue, selectableVehicle || [])}
              selectedVehicles={selectableVehicle?.filter((vehicle) => {
                return vehicle.selected;
              })}
              onSelect={onSelect}
              openConfirm={openConfirm}
              setOpenConfirm={setOpenConfirm}
            />
          </DeleteScreenWrapper>
        </DeleteScreenContainer>
      </Layout>
    </>
  );
};

export default DeleteVehiclesScreen;
