export enum USER_KIND {
  FRONT_USER = 1,
  VEHICLE_USER = 2,
  BATCH_USER = 3,
}

export enum ACTIVE {
  DISABLE = 0,
  ENABLE = 1,
}

export enum START_VEHICLE {
  DISALLOW = 0,
  ALLOW = 1,
}

export enum IS_ADMIN {
  DENY = 0,
  ADMIT = 1,
}
