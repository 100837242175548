import {Auth} from "aws-amplify";

// export const useSessionRefresh = () => {
//   const navigate = useNavigate();
//   Auth.currentSession()
//     .then((res) => {
//       axios.defaults.headers.common["Authorization"] = res
//         .getIdToken()
//         .getJwtToken();
//     })
//     .catch((err: any) => navigate("/"));
// };

export const refreshSession = async () => {
  try {
    return (await Auth.currentSession()).getIdToken().getJwtToken();
  } catch (err: any) {
    await Auth.signOut();
  }
};
