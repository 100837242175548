import { Company } from "@data/entities/Company/Company";
import { User } from "@data/entities/User/User";
import { Workspace } from "@data/entities/Workspace/Workspace";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { refreshSession } from "../Auth/auth";

export type GetUser = Omit<User, "password"> & {
  company_m: Pick<Company, "company_nm">;
  workspace_m: Pick<Workspace, "workspace_nm">;
};

const HOST = `${process.env.REACT_APP_ENDPOINT}`;

export const queryUser = () => {
  const useFrontUserList = () => {
    const getFrontUsers = async () => {
      const url = `${HOST}/users`;
      const { data } = await axios.get<{ data: GetUser[] }>(url, {
        headers: {
          Authorization: await refreshSession(),
        },
      });
      return data.data;
    };

    return useQuery(["frontUsers"], getFrontUsers, {
      staleTime: 0,
    });
  };

  return {
    useFrontUserList,
  };
};
