import React, { FC } from "react";

import { START_VEHICLE } from "@data/constants/entity";
import { Divider } from "@mantine/core";
import { UserDetail } from "@data/types";
import {
  ResultModalLabel,
  ResultModalText,
  UserModalItem,
} from "./modalContentStyle";
import { ResponseUser } from "@data/repositories/User/mutationUser";

type Props = {
  user?: ResponseUser;
};

export const ModalContent: FC<Props> = ({ user }) => {
  const startVehicle = {
    [START_VEHICLE.ALLOW]: "あり",
    [START_VEHICLE.DISALLOW]: "なし",
  };

  return (
    <>
      <UserModalItem>
        <ResultModalLabel>ユーザID</ResultModalLabel>
        <ResultModalText>{user?.id}</ResultModalText>
      </UserModalItem>

      <Divider />

      <UserModalItem>
        <ResultModalLabel>ログインID</ResultModalLabel>
        <ResultModalText>{user?.workspace_cd}</ResultModalText>
      </UserModalItem>

      <Divider />

      <UserModalItem>
        <ResultModalLabel>走行環境名</ResultModalLabel>
        <ResultModalText>{user?.internal_workspace_cd}</ResultModalText>
      </UserModalItem>

      <Divider />

      <UserModalItem>
        <ResultModalLabel>パスワード</ResultModalLabel>
        <ResultModalText>{user?.password}</ResultModalText>
      </UserModalItem>

      <Divider />

      <UserModalItem>
        <ResultModalLabel>発進指示</ResultModalLabel>
        <ResultModalText>
          {user
            ? startVehicle[user.is_allowed_start_vehicle]
            : startVehicle[START_VEHICLE.DISALLOW]}
        </ResultModalText>
      </UserModalItem>
    </>
  );
};
