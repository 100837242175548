import { FC, ReactNode } from "react";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { css } from "@emotion/react";
import { mainTheme } from "@data/theme/theme";
import { Image } from "@mantine/core";
import logo from "eve_logo.svg";

const AuthenticatorComponent: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Authenticator
          hideSignUp={true}
          css={css`
            .amplify-button[data-variation="primary"] {
              background-color: ${mainTheme.colors.primary};
            }
          `}
          components={{
            Header() {
              return (
                <Image
                  alt="EVE logo"
                  src={logo}
                  style={{ marginBottom: "1rem" }}
                />
              );
            },
          }}
          formFields={{
            signIn: {
              username: {
                placeholder: "名前を入力",
                label: "ユーザ名",
                order: 1,
              },
              password: {
                isRequired: true,
                placeholder: "パスワードを入力",
                label: "パスワード",
                order: 2,
              },
            },
            signUp: {
              username: {
                placeholder: "名前を入力",
                label: "ユーザ名",
                order: 1,
              },
              password: {
                isRequired: true,
                placeholder: "パスワードを入力",
                label: "パスワード",
                order: 2,
              },
              confirm_password: {
                isRequired: true,
                placeholder: "パスワードを入力",
                label: "パスワード確認",
                order: 3,
              },
            },
            resetPassword: {
              username: {
                placeholder: "ユーザ名を入力",
                label: "ユーザ名を入力",
              },
            },
          }}
        >
          {({ signOut, user }) => <>{children}</>}
        </Authenticator>
      </div>
    </>
  );
};

export {AuthenticatorComponent};
