import { useCallback, useState} from "react";
import DefaultButton from "../DefaultButton";
import { mainTheme } from "@data/theme/theme";
import { SignOutButtonContainer } from "./SignOutButtonStyle";
import { Auth } from "aws-amplify";
import ConfirmDialog from "@ui/components/Dialog/ConfirmDialog";

const SignOutButton = () => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleSignOut = () => {
    Auth.signOut();
  };

  return (
    <>
      <SignOutButtonContainer>
        <DefaultButton
          backgroundColor={mainTheme.colors.background}
          hoverColor={mainTheme.colors.third}
          buttonText={"サインアウト"}
          size={"XXS"}
          color={mainTheme.colors.onThird}
          onClick={() => {
            setOpenDialog(true)
          }}
        />
      </SignOutButtonContainer>

      <ConfirmDialog
          opened={openDialog}
          onCLose={useCallback(() => setOpenDialog(false), [openDialog])}
          description={"サインアウトしますか？"}
          executeButtonText={"サインアウト"}
          onConfirm={handleSignOut}
        />
    </>
  );
};

export default SignOutButton;
