import styled from "@emotion/styled";

export const DeleteModalListContainer = styled.div`
  flex: 12;
  width: 100%;
  overflow-y: scroll;

  /// scroll-bar 消去 edge
  -ms-overflow-style: none;
  /// scroll-bar 消去 chrome, safari
  &::-webkit-scrollbar {
    display: none;
  }
  /// scroll-bar 消去 firefox
  scrollbar-width: none;
`;
