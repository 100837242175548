import styled from "@emotion/styled";
import {
  RegisterModalItem,
  RegisterResultModalLabel,
  RegisterResultModalTextContainer,
} from "@ui/modals/RegisterVehicleResultModal/components/ModalContaent/modalContentStyle";

export const VehicleDetailModalTextContainer = styled(
  RegisterResultModalTextContainer
)``;

export const VehicleDetailModalLabel = styled(RegisterResultModalLabel)``;

export const VehicleDetailModalText = styled(VehicleDetailModalLabel)`
  font-weight: 400;
  word-break: break-all;
`;

export const VehicleDetailModalItem = styled(RegisterModalItem)``;
