import styled from "@emotion/styled";
import { FontSizeProp, SizeProp } from "../../../@types/theme";
import { mainTheme } from "../../../data/theme/theme";
import { DEFAULT_BUTTON_SIZE } from "./DefaultButton";

const disabledColor = mainTheme.colors.grayOut;
const onDisabled = mainTheme.colors.onGrayOut;

type DefaultButtonProps = {
  size: DEFAULT_BUTTON_SIZE;
  contentWidth?: SizeProp;
  align?: "center" | "start" | "end";
  color: string;
  backgroundColor: string;
  hoverColor: string;
  border?: true;
  borderColor?: string;
  fontWight: string;
  radiusFull?: true;
};

const size: { [key in DEFAULT_BUTTON_SIZE]: SizeType } = {
  XXS: {
    hight: "24px",
    width: "106px",
    padding: "4px 20px",
    fontSize: mainTheme.fontSize.medium,
    radius: "5px",
  },
  XS: {
    hight: "30px",
    width: "128px",
    padding: "4px 20px",
    fontSize: mainTheme.fontSize.medium,
    radius: "5px",
  },
  S: {
    hight: "36px",
    width: "144px",
    padding: "4px 20px",
    fontSize: mainTheme.fontSize.large,
    radius: "5px",
  },
  M: {
    hight: "42px",
    width: "162px",
    padding: "8px 24px",
    fontSize: mainTheme.fontSize.xLarge,
    radius: "5px",
  },
  L: {
    hight: "50px",
    width: "162px",
    padding: "12px 28px",
    fontSize: mainTheme.fontSize.xLarge,
    radius: "5px",
  },
  XL: {
    hight: "60px",
    width: "162px",
    padding: "12px 28px",
    fontSize: mainTheme.fontSize.xLarge,
    radius: "5px",
  },
};

export const StyledButton = styled.button<DefaultButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  box-sizing: content-box;

  width: ${(props) => props.contentWidth};
  text-align: ${(props) => props.align};
  height: ${(props) => size[props.size].hight};
  padding: ${(props) => size[props.size].padding};
  border-style: ${(props) => (props.border ? "solid" : "none")};
  border-width: 1px;
  border-radius: ${(props) =>
    props.radiusFull ? "99999px" : size[props.size].radius};
  border-color: ${(props) =>
    !props.disabled ? props.borderColor : disabledColor};
  background-color: ${(props) =>
    !props.disabled ? props.backgroundColor : disabledColor};
  box-shadow: ${(props) => props.theme.shadow.medium};
  color: ${(props) => (!props.disabled ? props.color : onDisabled)};
  font-size: ${(props) => size[props.size].fontSize};
  font-weight: ${(props) => props.fontWight};

  cursor: pointer;
  &:hover {
    background-color: ${(props) =>
      !props.disabled ? props.hoverColor : disabledColor};
  }
`;

export const ButtonText = styled.span``;

type SizeType = {
  hight: SizeProp;
  width: SizeProp;
  padding: string;
  fontSize: FontSizeProp;
  radius: FontSizeProp;
};
