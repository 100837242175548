import styled from "@emotion/styled";

export const GridSpacer = styled.div``;

export const VehicleCheckModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const ModalHeading = styled.h3`
  color: ${(props) => props.theme.colors.onBackGround};
  margin: 0;
`;
export const VehicleCheckModalHeader = styled.div`
  color: ${(props) => props.theme.colors.onBackGround};
  padding: 8px 16px;
  border-radius: 8px;
  margin: 0;
  display: flex;
  gap: 0.5rem;
`;

export const ModalContentWrapper = styled.div`
  padding: 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
`;

export const VehicleCheckModalFooter = styled.div`
  display: flex;
  justify-content: end;
  padding-right: 16px;
  margin-top: 1rem;
`;

export const FooterButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: 2rem;
  width: 18rem;
  height: 100%;
`;

export const ModalSpan = styled.div`
  color: ${({ theme }) => theme.colors.onBackGround};
  padding-right: 1rem;
  font-weight: 600;
  text-align: end;
`;
