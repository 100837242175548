import { IconCircleCheckFilled } from "@tabler/icons-react";
import { Alert } from "@mantine/core";

import { DeleteUserListContainer } from "./deleteUserListStyle";

export const DeleteSuccess = () => {
  return (
    <>
      <DeleteUserListContainer>
        <Alert
          title={"レスポンス"}
          color={"cyan"}
          variant={"light"}
          icon={<IconCircleCheckFilled size={"3rem"} />}
        >
          {"削除に成功しました"}
        </Alert>
      </DeleteUserListContainer>
    </>
  );
};

export default DeleteSuccess;
