import React from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Layout from "../../components/Layout";
import {
  DashMenuCenterContainer,
  DashMenuBody,
  StyledButton,
} from "./dashMenuScreenStyle";

const DashMenuScreen = () => {
  const navigate = useNavigate();
  return (
    <>
      <Layout title="DASH管理メニュー">
        <DashMenuBody>
          <DashMenuCenterContainer>
            <StyledButton onClick={() => navigate("vehicles")}>
              車両操作
            </StyledButton>
            <StyledButton onClick={() => navigate("workspaces")}>
              走行環境操作
            </StyledButton>

            <StyledButton onClick={() => navigate("users")}>
              ユーザ操作
            </StyledButton>
          </DashMenuCenterContainer>
        </DashMenuBody>
        <Footer children={<></>} />
      </Layout>
    </>
  );
};

export default DashMenuScreen;
