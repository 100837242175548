import { ChangeEventHandler, FC, ReactNode, memo } from "react";
import { GridWrapper } from "../../registerVehicleWidgetStyle";
import InputText from "@ui/components/Input/InputText";
import { MantineSize } from "@mantine/core";

type Props = {
  value?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  label?: string;
  labelId?: string;
  placeholder: string;
  leftDescription?: string;
  required?: true;
  withAsterisk?: true;
  errors?: {
    error?: ReactNode;
    errorMessage?: string;
  };
  size?: MantineSize;
  disabled?: true;
  headLabel?: string;
  description?: string;
};

export const VehicleFormInputMemo: FC<Props> = ({
  value = "",
  onChange,
  label,
  labelId,
  placeholder,
  size,
  leftDescription,
  required,
  withAsterisk,
  errors,
  disabled,
  headLabel,
  description,
}) => {
  return (
    <>
      <GridWrapper>
        <InputText
          required={required}
          withAsterisk={withAsterisk}
          label={label}
          labelId={labelId}
          placeholder={placeholder}
          errors={errors}
          size={size}
          value={value}
          onChange={onChange}
          description={description}
        />
      </GridWrapper>
    </>
  );
};

export const VehicleFormInput = memo(VehicleFormInputMemo);
