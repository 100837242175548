import { FC } from "react";
import { Group, Radio } from "@mantine/core";
import { mainTheme } from "@data/theme/theme";

export type RadioProp = {
  value: string;
  label: string;
};

type Props = {
  label?: string;
  description?: string;
  withAsterisk?: true;
  radioProps: RadioProp[];
  selectedValue: string;
  disabled?: boolean;
  required?: true;
  onChange?: (value: string) => void;
};

const DefaultRadioGroup: FC<Props> = ({
  label,
  description,
  withAsterisk,
  radioProps,
  selectedValue,
  disabled = false,
  required,
  onChange = () => {},
}) => {
  return (
    <>
      <Radio.Group
        required={required}
        label={label}
        value={!disabled ? selectedValue : ""}
        onChange={onChange}
        description={description}
        withAsterisk={withAsterisk}
        styles={{
          label: {
            color: `${
              !disabled
                ? mainTheme.colors.onBackGround
                : mainTheme.colors.grayOut
            }`,
            fontWeight: 600,
            fontSize: `${mainTheme.fontSize.medium}`,
          },
          error: {
            fontSize: `${mainTheme.fontSize.medium}`,
          },
        }}
      >
        <Group style={{ marginTop: "0.3em" }}>
          {radioProps.map((radioProp, index) => (
            <Radio
              key={index}
              value={radioProp.value}
              label={radioProp.label}
              styles={{
                radio: {
                  ":checked": {
                    backgroundColor: `${mainTheme.colors.primaryPositive}`,
                  },
                },
              }}
              disabled={disabled}
            />
          ))}
        </Group>
      </Radio.Group>
    </>
  );
};

export default DefaultRadioGroup;
