/**
 * ユーザ認証画面切り替え用コンポーネント
 * モックを使用する場合は MockAuthScreen をエクスポートする。
 * Cognitoを使用する場合は AuthenticatorComponent をエクスポートする。
 */
import {is_authentication_method_mock} from "@data/repositories/Auth/auth";
import {AuthenticatorComponent as AmplifyAuthenticator} from "@ui/screens/AuthScreen/AmplifyAuth";
import {MockAuthComponent as MockAuthenticator} from "@ui/screens/AuthScreen/MockAuth";

const AuthenticatorComponent = is_authentication_method_mock() ? MockAuthenticator : AmplifyAuthenticator;
export default AuthenticatorComponent;
