import { FC, ReactNode } from "react";
import {
  RegisterUserFormContainer,
  RegisterUserFormWrapper,
} from "./UserFromStyle";
import { GridSpacer } from "../../registerUserWidgetStyle";

type Props = {
  children: ReactNode;
};

export const RegisterUserFormContent: FC<Props> = ({ children }) => {
  return (
    <>
      <RegisterUserFormWrapper>
        <GridSpacer />
        <RegisterUserFormContainer>{children}</RegisterUserFormContainer>
        <GridSpacer />
      </RegisterUserFormWrapper>
    </>
  );
};
