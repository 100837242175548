import { START_VEHICLE } from "@data/constants/entity";
import styled from "@emotion/styled";

export const ListItemContainer = styled.li`
  display: grid;
  justify-content: start;
  grid-template-columns: 0.2fr 1fr 1fr 1fr 2fr 0.2fr;
  color: ${(props) => props.theme.colors.onBackGround};
  align-items: start;
  padding: 16px 0;
  cursor: pointer;
  &:hover {
    box-shadow: ${(props) => props.theme.shadow.small};
    transition: box-shadow 0.5s;
  }
`;

export const ListText = styled.span<{ disabled?: boolean }>`
  color: ${({ theme, disabled }) =>
    !disabled ? theme.colors.onBackGround : theme.colors.grayOut};
  word-break: break-all;
  font-weight: 500;
  padding-right: 16px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  padding: 0px 8px;
  gap: 8px;
`;

export const AllowedStartContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: start;
  align-content: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const AllowedStartWrapper = styled.div<{ is_allowed: START_VEHICLE }>`
  width: 64px;
  height: 24px;
  border-radius: 99999px;
  background-color: ${({ theme, is_allowed }) =>
    is_allowed === START_VEHICLE.ALLOW
      ? theme.colors.third
      : theme.colors.thirdNegative};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme, is_allowed }) =>
    is_allowed === START_VEHICLE.ALLOW
      ? theme.colors.onThird
      : theme.colors.onThird};
`;

export const GridSpacer = styled.div``;
