import { FC, useState } from "react";
import { Vehicle } from "../../../data/entities/Vehicle/Vehicle";
import Dropdown from "../../components/Dropdown/Dropdown";
import {
  GridSpacer,
  GridWrapper,
  VehicleDetailForm,
  VehicleDetailFormWrapper,
  TextContainer,
} from "./vehicleDetailWidgetStyle";
import ReadOnlyInput from "@ui/components/Input/ReadOnlyInput";
import { Workspace } from "@data/entities/Workspace/Workspace";
import { useSelectorOption } from "utils/useSelectorOption";
import InputText from "@ui/components/Input/InputText";
import {
  ButtonContainer,
  FooterContainer,
} from "../RegisterVehicleWidget/registerVehicleWidgetStyle";
import DefaultButton from "@ui/components/Button/DefaultButton";
import { mainTheme } from "@data/theme/theme";
import { useNavigate } from "react-router-dom";
import {
  useMutationVehicle,
} from "@data/repositories/Vehicle/mutationVehicle";
import { z } from "zod";
import { DefaultLoadingOverlay } from "@ui/components/LoadingOverlay/DefualtLoadingOverlay";
import { CheckVehicleWithEnvModal } from "@ui/modals/CheckVehicleWithEnvModal";
import { WorkspaceDropdown } from "@data/types";

export type EditedVehicleEnvironment = Vehicle & {
  workspace_m: Pick<Workspace, "workspace_cd" | "workspace_nm" | "company_id">;
  next_vehicle_id: string;
  is_stock: boolean;
};
type validationSchema = Pick<Workspace, "workspace_cd"> & {
  next_vehicle_id: string;
};

type WorkspaceSchema = {
  workspace_cd: string | undefined;
  workspace_nm: string | undefined;
  company_id: string;
  company_m: {
    company_nm: string;
  };
};

type Props = {
  vehicle: Vehicle;
  currentWorkspace?: Pick<Workspace, "workspace_cd" | "workspace_nm">;
  workspaces?: WorkspaceDropdown[];
};

const initialErrors: validationSchema = {
  workspace_cd: "",
  next_vehicle_id: "",
};

const VehicleDetailWidget: FC<Props> = ({
  vehicle,
  currentWorkspace,
  workspaces = [],
}) => {
  const [errors, setErrors] = useState(initialErrors);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState<WorkspaceSchema>({
    workspace_cd: "",
    workspace_nm: "",
    company_id: "",
    company_m: {
      company_nm: "",
    },
  });
  const { setSelectorOption } = useSelectorOption();
  const navigate = useNavigate();
  const { parseSelectorOption } = useSelectorOption();
  const { updateVehicleEnvironment } = useMutationVehicle();

  const [editedVehicle, setEditedVehicle] = useState<EditedVehicleEnvironment>({
    ...vehicle,
    next_vehicle_id: "",
    workspace_m: {
      workspace_cd: "",
      workspace_nm: "",
      company_id: "",
    },
    is_stock: false,
  });

  const defaultSelectWorkspaceNm = workspaces? workspaces.filter((workspaces) => {
    return workspaces.workspace_nm == currentWorkspace?.workspace_nm;
  }) : [];

  const workspaceDropdown = workspaces.map((workspace) => {
    return {
      value: setSelectorOption({ ...workspace }),
      label: `${workspace.workspace_nm}`,
    };
  });

  const onSelect = (value: string | null) => {
    const valueJson = parseSelectorOption(value, {
      workspace_cd: "",
      workspace_nm: "",
      company_id: "",
      company_m: {
        company_nm: "",
      },
    });
    setEditedVehicle((pre) => {
      return {
        ...pre,
        workspace_cd: valueJson.workspace_cd,
        workspace_m: {
          workspace_cd: valueJson.workspace_cd,
          workspace_nm: valueJson.workspace_nm,
          company_id: valueJson.company_id,
        },
      };
    });
    const data = workspaces? workspaces.filter((workspace) => {
      return workspace.workspace_nm == valueJson.workspace_nm;
    }) : [];
    setSelectedWorkspace((pre) => {
      return {
        ...pre,
        workspace_cd: data.length? data[0].workspace_cd? data[0].workspace_cd : "" : "",
        workspace_nm: data.length? data[0].workspace_nm? data[0].workspace_nm : "" : "",
        company_id: data.length? data[0].company_id? data[0].company_id : "" : "",
        company_m: {
          company_nm: data.length? data[0].company_m.company_nm? data[0].company_m.company_nm : "" : "",
        },
      };
    });
  };

  const throwableSchema = z.object<{
    [key in keyof validationSchema]: any;
  }>({
    workspace_cd: z.string().nonempty("このフィールドは必須です"),
    next_vehicle_id: z
      .string()
      .regex(/^[0-9a-zA-Z-]*$/, "半角英数字、「-」のみ入力できます")
      .nonempty("このフィールドは必須です"),
  });
  const onClick = () => {
    try {
      throwableSchema.parse({
        workspace_cd: editedVehicle.workspace_m.workspace_cd,
        next_vehicle_id: editedVehicle.next_vehicle_id,
      });
      setErrors(initialErrors);

      setModalOpen(true);

    } catch (e: any) {
      setErrors({
        ...e.flatten().fieldErrors,
        workspace_cd: e.flatten().fieldErrors.workspace_cd,
        fms_project_id: e.flatten().fieldErrors.fms_project_id,
      });
    }
  };

  const [isStockCheck, setIsStockChecked] = useState(false);
  const handleStockCheckBox = () => {
    let list_num = 0;
    let next_vehicle_id = "";
    var i = 0;
    if (!isStockCheck) {
      for ( i = 0; i < workspaces.length; i++) {
        if (workspaces[i].workspace_cd == "-1") {
          list_num = i;
          break;
        }
      }
      next_vehicle_id = "stock-vehicle-" + editedVehicle.user_id.toString();
    } else {
      for (i = 0; i < workspaces.length; i++) {
        if (workspaces[i].workspace_cd != "-1") {
          list_num = i;
          break;
        }
      }
    }
    const workspace_cd = workspaces[list_num].workspace_cd? workspaces[list_num].workspace_cd : "";
    const workspace_nm =  workspaces[list_num].workspace_nm? workspaces[list_num].workspace_nm : "";
    const company_id = workspaces[list_num].company_id? workspaces[list_num].company_id : "";
    const company_nm = workspaces[list_num].company_m.company_nm? workspaces[list_num].company_m.company_nm : "";
    setEditedVehicle((pre) => {
      return {
        ...pre,
        workspace_m: {
          workspace_cd: workspaces[list_num].workspace_cd? workspaces[list_num].workspace_cd : "",
          workspace_nm: workspaces[list_num].workspace_nm? workspaces[list_num].workspace_nm : "",
          company_id: workspaces[list_num].company_id? workspaces[list_num].company_id : "",
        },
        next_vehicle_id: next_vehicle_id,
        is_stock: !isStockCheck,
      };
    });
    setIsStockChecked(prevState => !prevState);
    setSelectedWorkspace((pre) => {
      return {
        ...pre,
        workspace_cd: workspace_cd? workspace_cd : "",
        workspace_nm: workspace_nm? workspace_nm : "",
        company_id: company_id? company_id : "",
        company_m: {
          company_nm: company_nm? company_nm : "",
        },
      };
    });

  };

  return (
    <>
      <VehicleDetailFormWrapper>
        <GridSpacer />

        <VehicleDetailForm>
          <GridWrapper>
            <TextContainer>
              <ReadOnlyInput
                label="現在の走行環境名"
                value={currentWorkspace?.workspace_nm}
                size={"md"}
              />
            </TextContainer>
          </GridWrapper>

          <GridWrapper>
            <TextContainer>
              <ReadOnlyInput
                label="現在のFMS車両ID"
                value={vehicle.vehicle_id}
                size={"md"}
              />
            </TextContainer>
          </GridWrapper>

          <GridWrapper>
            <TextContainer>
              <ReadOnlyInput
                label="車両名"
                value={vehicle.alias_name}
                size={"md"}
              />
            </TextContainer>
          </GridWrapper>

          <GridWrapper>
            <TextContainer>
              <label>{"FMS車両ID      "}</label>
              <input
                type="checkbox"
                id="sotkCheckButton"
                checked={isStockCheck}
                onChange={handleStockCheckBox}
              />
              <label>{"在庫環境"}</label>
              <InputText
                withAsterisk
                label=""
                value={editedVehicle.next_vehicle_id}
                size={"md"}
                disabled={editedVehicle.is_stock}
                onChange={(e) => {
                  setEditedVehicle((pre) => {
                    return {
                      ...pre,
                      workspace_m: {
                        ...pre.workspace_m,
                      },
                      next_vehicle_id: e.target.value,
                    };
                  });
                }}
                placeholder="半角英数で入力"
                description="例： ab***************"
                errors={{
                  error: Boolean(errors.next_vehicle_id),
                  errorMessage: errors.next_vehicle_id,
                }}
              />
            </TextContainer>
          </GridWrapper>

          <GridWrapper>
            <Dropdown
              withAsterisk
              label="走行環境名"
              placeholder="１つ選択"
              options={workspaceDropdown}
              onSelect={onSelect}
              defaultValue={setSelectorOption(defaultSelectWorkspaceNm)}
              selectValue={setSelectorOption(selectedWorkspace)}
              errorMessage={errors.workspace_cd}
              disabled={editedVehicle.is_stock}
            />
          </GridWrapper>

          <GridSpacer />
          <FooterContainer>
            <GridSpacer />
            <ButtonContainer>
              <DefaultButton
                backgroundColor={"white"}
                hoverColor={mainTheme.colors.secondary}
                color={mainTheme.colors.onSecondary}
                onClick={() => {
                  navigate("/vehicles");
                }}
                buttonText={"キャンセル"}
                size={"M"}
                border
                borderColor={mainTheme.colors.secondaryContainer}
                fontWeight={"600"}
                type="button"
              />
              <DefaultButton
                type="button"
                backgroundColor={mainTheme.colors.primaryPositive}
                hoverColor={mainTheme.colors.primary}
                color={mainTheme.colors.onPrimary}
                onClick={onClick}
                buttonText={"切替"}
                size={"M"}
                contentWidth={"120px"}
              />
            </ButtonContainer>
            <GridSpacer />
          </FooterContainer>
        </VehicleDetailForm>
      </VehicleDetailFormWrapper>

      {modalOpen && (
        <CheckVehicleWithEnvModal
          isOpen={modalOpen}
          editedVehicleEnv={editedVehicle}
          isStockCheck={isStockCheck}
          onRequestClose={() => {}}
          onClose={() => setModalOpen(false)}
        />
      )}

      {updateVehicleEnvironment.isLoading && (
        <DefaultLoadingOverlay visible={true} loaderSize="lg" />
      )}
    </>
  );
};

export default VehicleDetailWidget;
