import styled from "@emotion/styled";

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  display: grid;
  grid-template-columns: 0.2fr 3fr 1fr 0.2fr;
`;

export const FooterButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 8px;
`;

export const GridSpacer = styled.div``;

export const VehicleModalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
