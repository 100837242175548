import { FC } from "react";

import {
  DeleteModalItemContainer,
  DeleteModalText,
  GridSpacer,
} from "./deleteModalItemStyle";
import { GetVehicle } from "@data/repositories/Vehicle/queryVehicle";

type Props = {
  vehicle: GetVehicle;
};

const DeleteVehicleItem: FC<Props> = ({ vehicle }) => {
  const { vehicle_id, alias_name, workspace_m } = vehicle;
  return (
    <DeleteModalItemContainer>
      <GridSpacer />
      <DeleteModalText>{vehicle_id}</DeleteModalText>
      <DeleteModalText>{alias_name}</DeleteModalText>
      <DeleteModalText>{workspace_m?.workspace_nm}</DeleteModalText>
      <GridSpacer />
    </DeleteModalItemContainer>
  );
};

export default DeleteVehicleItem;
