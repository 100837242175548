import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { mainTheme } from "@data/theme/theme";
import DefaultButton from "@ui/components/Button/DefaultButton";
import Divider from "@ui/components/Divider/Divider";
import Layout from "@ui/components/Layout";
import { queryWorkspace } from "@data/repositories/Workspace/queryWorkspace";
import Footer from "@ui/components/Footer/Footer";

import {
  WorkspaceListTitle,
  WorkspacesScreenContainer,
  WorkspacesHeader,
  WorkspaceListContainer,
  GridSpacer,
  WorkspaceListITitleText,
  WorkspaceScreenWrapper,
  SearchContainer,
} from "./workspacesScreenStyle";
import WorkspacesWidget from "../../widgets/WorkspacesWidget";
import WorkspaceFooter from "./WorkspacesFooter/WorkspacesFooter";
import { WorkspaceWitchCompany } from "@data/types";
import InputText from "@ui/components/Input/InputText";
import { IconSearch } from "@tabler/icons-react";

type SelectedProp = {
  workspace_cd: string[];
};

const WorkspacesScreen = () => {
  const { useWorkspaceList } = queryWorkspace();

  const [search, setSearch] = useState("");

  const { data: workspaces, isError: workspaceError } = useWorkspaceList();

  function filterData(data: WorkspaceWitchCompany[], search: string) {
    const query = search.toLowerCase().trim();
    return query.length > 0
      ? data.filter(
          (item) =>
            item.workspace_nm?.toLowerCase().includes(query) ||
            item.workspace_cd?.toLocaleLowerCase().includes(query) ||
            item.company_m.company_nm?.toLocaleLowerCase().includes(query) ||
            item.company_m.fms_project_id?.toLocaleLowerCase().includes(query)
        )
      : data;
  }

  useEffect(() => {
    if (workspaceError) {
      alert("データの取得に失敗しました");
    }
  }, [workspaceError]);
  const navigate = useNavigate();

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearch(value);
  };

  return (
    <Layout title="走行環境一覧">
      <WorkspacesScreenContainer>
        <GridSpacer />
        <WorkspaceScreenWrapper>
          <WorkspacesHeader>
            <DefaultButton
              backgroundColor={mainTheme.colors.primaryPositive}
              hoverColor={mainTheme.colors.primary}
              buttonText={"走行環境新規登録"}
              size={"M"}
              color={mainTheme.colors.onPrimary}
              align={"center"}
              onClick={() => {
                navigate("register/company");
              }}
            />

            <SearchContainer>
              <InputText
                label="検索"
                value={search}
                onChange={handleSearch}
                placeholder="入力値で検索"
                size="md"
                icon={<IconSearch size={"1.2rem"} />}
              />
              {/* 走行環境セレクター */}
              {/* <DefaultMultiSelect
                label="走行環境名"
                data={dropdownList}
                maxSelectedValues={1}
                placeholder="走行環境名で検索"
                onChange={(e) => {
                  setSelectedProp({
                    ...selectedProp,
                    workspace_cd: e,
                  });
                }}
              /> */}
            </SearchContainer>
          </WorkspacesHeader>

          <WorkspaceListTitle>
            <GridSpacer />
            <WorkspaceListITitleText>走行環境名</WorkspaceListITitleText>
            <WorkspaceListITitleText>走行環境ID</WorkspaceListITitleText>
            <WorkspaceListITitleText>会社名</WorkspaceListITitleText>
            <WorkspaceListITitleText>FMSプロジェクトID</WorkspaceListITitleText>
            <GridSpacer />
          </WorkspaceListTitle>

          <Divider thin="1px" rounded />

          <WorkspaceListContainer>
            {workspaces && (
              <WorkspacesWidget
                workspaces={filterData(workspaces || [], search)}
              />
            )}
          </WorkspaceListContainer>

          <Footer>
            <WorkspaceFooter />
          </Footer>
        </WorkspaceScreenWrapper>
      </WorkspacesScreenContainer>
    </Layout>
  );
};

export default WorkspacesScreen;
