import { RegisterWorkspaceContainer } from "./registerWorkspaceScreenStyle";
import Layout from "../../components/Layout";
import RegisterUserWidget from "@ui/widgets/RegisterUserWidget";
import { useLocation } from "react-router-dom";
import { User } from "@data/entities/User/User";
import { queryCompany } from "@data/repositories/Company/queryComany";
import { queryWorkspace } from "@data/repositories/Workspace/queryWorkspace";
import { useEffect } from "react";

const RegisterUserScreen = () => {
  const location = useLocation();
  const locationState:
    | {
        user?: User;
        title?: string;
        submitText?: string;
      }
    | undefined = location.state;

  const { useCompanyList } = queryCompany();
  const { useWorkspaceDropdown } = queryWorkspace();
  const { data: companies, isError: companyError } = useCompanyList();
  const { data: workspaces, isError: workspaceError } = useWorkspaceDropdown();

  useEffect(() => {
    if (companyError || workspaceError) {
      alert("データの取得に失敗しました");
    }
  }, [companyError, workspaceError]);

  return (
    <Layout title={locationState ? locationState.title! : "ユーザ：新規登録"}>
      <RegisterWorkspaceContainer>
        <RegisterUserWidget
          selectedUser={locationState?.user}
          submitText={locationState?.submitText}
          companies={companies}
          workspaces={workspaces}
        />
      </RegisterWorkspaceContainer>
    </Layout>
  );
};

export default RegisterUserScreen;
