import { ChangeEvent, FC, FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";
import { z } from "zod";

import Divider from "@ui/components/Divider/Divider";
import { GRID_BREAKPOINTS } from "@data/constants/breakPoint";
import { useSelectorOption } from "utils/useSelectorOption";
import { CompanyListItem, DropDownOption } from "@data/types";
import { mainTheme } from "@data/theme/theme";
import DefaultButton from "@ui/components/Button/DefaultButton";
import Dropdown from "@ui/components/Dropdown/Dropdown";
import InputText from "@ui/components/Input/InputText";

import {
  ButtonContainer,
  GridSpacer,
  GridWrapper,
  InputContainer,
  RegisterCompanyForm,
  RegisterCompanyFormWrapper,
  StyledLabel,
  StyledLabelHead,
} from "./registerCompanyWidgetStyle";

type Props = {
  companies?: CompanyListItem[];
};

type EditedCompany = {
  select: CompanyListItem;
  input: CompanyListItem;
};

type validationSchema = {
  select_company_id: string;
  input_company_nm: string;
  input_fms_project_id: string;
};

const initialCompany: EditedCompany = {
  select: {
    company_id: "",
    company_nm: "",
    fms_project_id: "",
    ver_no: 0,
  },
  input: {
    company_id: "",
    company_nm: "",
    fms_project_id: "",
    ver_no: 0,
  },
};

const initialErrors: validationSchema = {
  select_company_id: "",
  input_company_nm: "",
  input_fms_project_id: "",
};

const RegisterWorkspaceWidget: FC<Props> = ({ companies }) => {
  const [editedCompany, setEditedCompany] =
    useState<EditedCompany>(initialCompany);
  const [errors, setErrors] = useState(initialErrors);
  const navigate = useNavigate();
  const matchesMedia = useMediaQuery(`(max-width: ${GRID_BREAKPOINTS.lg})`);
  const { setSelectorOption, parseSelectorOption } = useSelectorOption();

  const companyDropdown: DropDownOption[] = companies
    ? companies.map((company) => {
        return {
          value: setSelectorOption({
            ...company,
          }),
          label: company.company_nm || "",
        };
      })
    : [];

  const selectErrorSchema = z.object<Record<keyof validationSchema, any>>({
    select_company_id: z.string().nonempty("このフィールドは必須です"),
    input_company_nm: z.string().nonempty("このフィールドは必須です"),
    input_fms_project_id: z
      .string()
      .regex(/^[-_0-9a-zA-Z]*$/, "半角英数字、「-」、「_」のみ入力できます")
      .nonempty("このフィールドは必須です"),
  });
  const inputErrorSchema = z.object<
    Record<
      keyof Pick<validationSchema, "input_company_nm" | "input_fms_project_id">,
      any
    >
  >({
    input_company_nm: z.string().nonempty("このフィールドは必須です"),
    input_fms_project_id: z
      .string()
      .regex(/^[-_0-9a-zA-Z_]*$/, "半角英数字、「-」、「_」のみ入力できます")
      .nonempty("このフィールドは必須です"),
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      /// validation :１つも入力がない場合
      if (
        !editedCompany.select.company_id &&
        !editedCompany.input.company_nm &&
        !editedCompany.input.fms_project_id
      ) {
        selectErrorSchema.parse({
          select_company_id: editedCompany.select.company_id,
          input_company_nm: editedCompany.input.company_nm,
          input_fms_project_id: editedCompany.input.fms_project_id,
        });
      }

      /// input validation
      if (!editedCompany.select.company_id) {
        inputErrorSchema.parse({
          input_company_nm: editedCompany.input.company_nm,
          input_fms_project_id: editedCompany.input.fms_project_id,
        });
      }

      setErrors(initialErrors);

      navigate("/workspaces/register/workspace", {
        state: editedCompany.select.company_id
          ? editedCompany.select
          : editedCompany.input,
      });
    } catch (e: any) {
      setErrors({
        ...e.flatten().fieldErrors,
      });
    }
  };

  const handleSelectCompany = (e: string | null) => {
    setEditedCompany({
      ...editedCompany,
      select: parseSelectorOption(e, {
        company_id: "",
        company_nm: "",
        fms_project_id: "",
        is_active: 1,
        ver_no: 0,
      }),
    });
  };

  const handleChangeCompanyNm = (e: ChangeEvent<HTMLInputElement>) => {
    if (editedCompany.select.company_id) {
      alert("会社選択をクリアしてから入力して下さい");
      return;
    }

    setEditedCompany({
      ...editedCompany,
      input: {
        company_id: "",
        company_nm: e.target.value,
        fms_project_id: editedCompany.input.fms_project_id,
        ver_no: 0,
      },
    });
  };
  const handleChangeProjectId = (e: ChangeEvent<HTMLInputElement>) => {
    if (editedCompany.select.company_id) {
      alert("会社選択をクリアしてから入力して下さい");
      return;
    }

    setEditedCompany({
      ...editedCompany,
      input: {
        company_id: "",
        company_nm: editedCompany.input.company_nm,
        fms_project_id: e.target.value,
        ver_no: 0,
      },
    });
  };

  /// enter_key無効化
  document.onkeydown = function (e) {
    // エンターキーだったら無効にする
    if (e.key === "Enter") {
      return false;
    }
  };

  return (
    <>
      <RegisterCompanyFormWrapper>
        <GridSpacer />
        <GridSpacer />
        <RegisterCompanyForm onSubmit={handleSubmit}>
          <InputContainer>
            <StyledLabelHead>会社選択</StyledLabelHead>
            <GridWrapper>
              <StyledLabel>
                既存の会社（プロジェクト）に環境を追加する場合はこちら
              </StyledLabel>
              <Dropdown
                size={matchesMedia ? "xs" : "md"}
                withAsterisk
                label="会社"
                placeholder="１つ選択"
                options={companyDropdown}
                onSelect={handleSelectCompany}
                onClick={() => {
                  if (
                    editedCompany.input.company_nm ||
                    editedCompany.input.fms_project_id
                  ) {
                    alert("入力をクリアしてから選択して下さい");
                    return;
                  }
                }}
                errorMessage={errors.select_company_id}
              />
            </GridWrapper>
          </InputContainer>

          <div>
            <Divider thin="2px" dividerStyle="dashed" />
          </div>

          <InputContainer>
            <StyledLabelHead>新規登録</StyledLabelHead>
            <GridWrapper>
              <StyledLabel>
                新規に会社（プロジェクト）を登録する場合はこちら
              </StyledLabel>
              <InputText
                size={matchesMedia ? "xs" : "md"}
                withAsterisk
                label="会社名"
                labelId={"label_input_company_nm"}
                placeholder="文字を入力"
                value={editedCompany.input.company_nm}
                onChange={handleChangeCompanyNm}
                errors={{
                  error: Boolean(errors.input_company_nm),
                  errorMessage: errors.input_company_nm,
                }}
              />
            </GridWrapper>
          </InputContainer>

          <GridWrapper>
            <GridSpacer />
            <InputText
              size={matchesMedia ? "xs" : "md"}
              withAsterisk
              label="FMSプロジェクトID"
              labelId="label_input_fms_project_id"
              placeholder="半角英数を入力"
              description="例： eve_*************"
              value={editedCompany.input.fms_project_id}
              onChange={handleChangeProjectId}
              errors={{
                error: Boolean(errors.input_fms_project_id),
                errorMessage: errors.input_fms_project_id,
              }}
            />
          </GridWrapper>

          {/* <RegisterCompanyFooter> */}
          {/* <FooterContainer>
              <GridSpacer /> */}
          <ButtonContainer>
            <DefaultButton
              type="button"
              backgroundColor={"white"}
              hoverColor={mainTheme.colors.secondary}
              color={mainTheme.colors.onSecondary}
              onClick={() => {
                navigate("/workspaces/");
              }}
              buttonText={"キャンセル"}
              size={"M"}
              border
              borderColor={mainTheme.colors.secondaryContainer}
              fontWeight={"600"}
            />
            <DefaultButton
              type="submit"
              backgroundColor={mainTheme.colors.primaryPositive}
              hoverColor={mainTheme.colors.primary}
              color={mainTheme.colors.onPrimary}
              buttonText={"次へ"}
              size={"M"}
              contentWidth={"96px"}
              onClick={() => {}}
            />
          </ButtonContainer>
        </RegisterCompanyForm>
      </RegisterCompanyFormWrapper>
    </>
  );
};

export default RegisterWorkspaceWidget;
