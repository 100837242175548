import { FC } from "react";
import { IconCircleXFilled } from "@tabler/icons-react";

import { DeleteModalListContainer } from "./ErrorStyle";

import { Alert } from "@mantine/core";

type Props = {
  title?: string;
  message?: string;
  variant?: "outline" | "filled" | "light";
  color?: "red" | "orange";
};

export const Error: FC<Props> = ({
  message,
  title,
  variant = "light",
  color = "red",
}) => {
  return (
    <>
      <DeleteModalListContainer>
        <Alert
          title={title}
          color={color}
          variant={variant}
          icon={<IconCircleXFilled size={"3rem"} />}
        >
          {message}
        </Alert>
      </DeleteModalListContainer>
    </>
  );
};

export default Error;
