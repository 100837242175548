import React, { FC, ReactNode } from "react";

import { LoadingNotification } from "@ui/components/LoadingNotification/LoadingNotification";

type Poops = {
  loading?: boolean;
  isError?: boolean;
  title?: string;
  idCheckError?: boolean;
  check?: boolean;
};

type Text = {
  isError: string;
  loading: string;
  idCheckError: string;
  check: string;
};

const VehicleIdNotification: FC<Poops> = ({
  loading,
  isError,
  title,
  idCheckError,
  check,
}) => {
  const renderText = (value: keyof Text): ReactNode => {
    const text = {
      isError: <p></p>,
      loading: <p></p>,
      idCheckError: <span>FMS車両IDを修正して下さい</span>,
      check: <span>値は正常です</span>,
    };
    return text[value];
  };
  return (
    <>
      <LoadingNotification
        withCloseButton={false}
        loading={loading}
        isError={isError || idCheckError}
        title={title}
      >
        {loading && renderText("loading")}
        {isError && !idCheckError && renderText("isError")}
        {idCheckError && renderText("idCheckError")}
        {!loading && check && renderText("check")}
      </LoadingNotification>
    </>
  );
};

export default VehicleIdNotification;
