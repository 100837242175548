import { Dialog } from "@mantine/core";
import React, { FC, memo } from "react";

import { mainTheme } from "@data/theme/theme";

import DefaultButton from "../Button/DefaultButton";
import {
  ConfirmButton,
  ConfirmDialogStyle,
  Description,
} from "./ConfirmDialogStyle";

type Props = {
  opened: boolean;
  description?: string;
  executeButtonText?: string;
  onCLose: () => void;
  onConfirm?: () => void;
};

const ConfirmDialog: FC<Props> = ({
  opened,
  description,
  executeButtonText = "",
  onCLose,
  onConfirm = () => {},
}) => {
  return (
    <Dialog
      position={{ top: "35%", left: "30%" }}
      opened={opened}
      style={{
        width: "30rem",
        height: "15rem",
        borderRadius: "16px",
        padding: "2rem",
      }}
    >
      <ConfirmDialogStyle.Content>
        <ConfirmDialogStyle.Heder>
          <Description.Container>
            <Description.Text>{description}</Description.Text>
          </Description.Container>
        </ConfirmDialogStyle.Heder>

        <ConfirmButton.Container>
          <DefaultButton
            backgroundColor={mainTheme.colors.secondaryPositive}
            hoverColor={mainTheme.colors.secondary}
            border
            borderColor={mainTheme.colors.secondaryContainer}
            buttonText={"キャンセル"}
            size={"S"}
            color={mainTheme.colors.onSecondary}
            contentWidth="90px"
            onClick={onCLose}
          />
          <DefaultButton
            backgroundColor={mainTheme.colors.primaryPositive}
            hoverColor={mainTheme.colors.primary}
            buttonText={executeButtonText}
            contentWidth="90px"
            size={"S"}
            color={mainTheme.colors.onPrimary}
            onClick={() => {
              onConfirm();
              onCLose();
            }}
          />
        </ConfirmButton.Container>
      </ConfirmDialogStyle.Content>
    </Dialog>
  );
};

export default memo(ConfirmDialog);
