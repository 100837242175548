import styled from "@emotion/styled";

export const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 0.5rem;
`;

export const DialogHead = styled.span`
  color: ${({ theme }) => theme.colors.onBackGround};
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: 600;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DialogContent = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 0.5rem;
  `,
};
export const DialogHeader = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  `,

  Title: styled.span`
    color: ${({ theme }) => theme.colors.onBackGround};
    font-size: ${({ theme }) => theme.fontSize.large};
    font-weight: 700;
    padding-bottom: 0.5rem;
  `,

  Description: styled.span`
    color: ${({ theme }) => theme.colors.onBackGround};
    font-size: ${({ theme }) => theme.fontSize.mine};
    font-weight: 400;
  `,
};

export const DialogButton = {
  Container: styled.div`
    display: flex;
    justify-content: space-between;
  `,
};
