import styled from "@emotion/styled";

export const RegisterUserFormWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 80%;

  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
`;

export const RegisterUserFormContainer = styled.div`
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-around;

  padding-top: 1rem;
  background-color: ${({ theme }) => theme.colors.background};
`;
