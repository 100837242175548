import { FC, ChangeEvent, ReactNode } from "react";
import { InputContainer } from "../../registerUserWidgetStyle";
import { Box, Popover, Text } from "@mantine/core";
import InputPassword from "@ui/components/Input/InputPassword";

type Props = {
  popOverOpened?: boolean;
  onOpenPopOver?: (isOpen: boolean) => void;
  label?: string;
  labelId?: string;
  placeholder?: string;
  disabled?: true;
  required?: true;
  withAsterisk?: true;
  value?: string;
  errors?: {
    error: boolean;
    errorMessage: string;
  };
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  renderChecks?: ReactNode;
};

const UserFromPassword: FC<Props> = ({
  popOverOpened = false,
  onOpenPopOver = (isOpen) => {},
  label,
  labelId,
  placeholder,
  disabled,
  required,
  withAsterisk,
  value = "",
  errors,
  onChange,
  renderChecks,
}) => {
  return (
    <>
      <InputContainer>
        <Popover
          opened={popOverOpened}
          position="top"
          width="target"
          transitionProps={{ transition: "pop" }}
        >
          <Popover.Target>
            <div
              onFocusCapture={() => onOpenPopOver(true)}
              onBlurCapture={() => onOpenPopOver(false)}
            >
              <InputPassword
                required={required}
                withAsterisk={withAsterisk}
                label={label}
                labelId={labelId}
                placeholder={placeholder}
                size="xs"
                value={value}
                onChange={onChange}
                disabled={disabled}
                errors={errors}
              />
            </div>
          </Popover.Target>
          <Popover.Dropdown>
            <PasswordRequirement
              label="８文字以上含みます"
              meets={value.length > 7}
            />
            {renderChecks}
          </Popover.Dropdown>
        </Popover>
      </InputContainer>
    </>
  );
};

export default UserFromPassword;

function PasswordRequirement({
  meets,
  label,
}: {
  meets: boolean;
  label: string;
}) {
  return (
    <Text
      color={meets ? "teal" : "red"}
      sx={{ display: "flex", alignItems: "center" }}
      mt={7}
      size="sm"
    >
      {meets ? "✓" : "✖"} <Box ml={10}>{label}</Box>
    </Text>
  );
}
