import React, { FC, memo } from "react";
import { StyledLabel } from "../../registerUserWidgetStyle";
import DefaultRadioGroup, {
  RadioProp,
} from "@ui/components/Radio/DefalutRadioGroup";
import { UserFormRadioContainer } from "./userFormRadioStyle";

type Props = {
  label: string;
  required?: true;
  withAsterisk?: true;
  radioProps: RadioProp[];
  selectedRadio: string;
  leftDescription?: string;
  disabled?: true;
  onChange: (value: string) => void;
};

const UserFormRadioMemo: FC<Props> = ({
  label,
  required,
  withAsterisk,
  radioProps,
  selectedRadio,
  leftDescription,
  disabled,
  onChange,
}) => {
  return (
    <>
      <UserFormRadioContainer>
        <StyledLabel>{leftDescription}</StyledLabel>
        <DefaultRadioGroup
          label={label}
          required={required}
          withAsterisk={withAsterisk}
          radioProps={radioProps}
          selectedValue={selectedRadio}
          disabled={disabled}
          onChange={onChange}
        />
      </UserFormRadioContainer>
    </>
  );
};

export const UserFormRadio = memo(UserFormRadioMemo);
