import React, { useEffect } from "react";
import Layout from "../../components/Layout";
import RegisterVehicleWidget from "../../widgets/RegisterVehicleWidget";
import { RegisterVehicleContainer } from "./registerVehicleScreenStyle";
import { queryCompany } from "@data/repositories/Company/queryComany";
import { queryWorkspace } from "@data/repositories/Workspace/queryWorkspace";

const RegisterVehicleScreen = () => {
  const { useCompanyList } = queryCompany();
  const { useWorkspaceList } = queryWorkspace();

  const { data: companies, isError: companyError } = useCompanyList();
  const { data: workspaces, isError: workspaceError } = useWorkspaceList();

  useEffect(() => {
    if (companyError || workspaceError) {
      alert("データの取得に失敗しました");
    }
  }, [companyError, workspaceError]);

  return (
    <Layout title="車両：新規登録">
      <RegisterVehicleContainer>
        <RegisterVehicleWidget companies={companies} workspaces={workspaces} />
      </RegisterVehicleContainer>
    </Layout>
  );
};

export default RegisterVehicleScreen;
