import { ChangeEventHandler, FC, memo } from "react";
import { InputContainer, StyledLabel } from "../../registerUserWidgetStyle";
import InputText from "@ui/components/Input/InputText";
import { MantineSize } from "@mantine/core";

type Props = {
  inputProp?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  label?: string;
  labelId?: string;
  placeholder: string;
  leftDescription?: string;
  required?: true;
  withAsterisk?: true;
  description?: string;
  errors?: {
    error: boolean;
    errorMessage: string;
  };
  size?: MantineSize;
  disabled?: boolean;
};

const UserFormInputMemo: FC<Props> = ({
  inputProp = "",
  onChange,
  label,
  labelId,
  placeholder,
  size,
  leftDescription,
  required,
  withAsterisk,
  errors,
  disabled,
  description,
}) => {
  return (
    <>
      <InputContainer>
        <StyledLabel>{leftDescription}</StyledLabel>
        <InputText
          required={required}
          withAsterisk={withAsterisk}
          label={label}
          labelId={labelId}
          placeholder={placeholder}
          errors={errors}
          value={inputProp}
          size={size}
          disabled={disabled}
          onChange={onChange}
          description={description}
        />
      </InputContainer>
    </>
  );
};

export const UserFormInput = memo(UserFormInputMemo);
