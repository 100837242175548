import React, { FC, useState } from "react";
import { InputTextContainer } from "./inputTextStyle";
import { Input, MantineSize, PasswordInput } from "@mantine/core";
import { mainTheme } from "../../../data/theme/theme";
import { css } from "@emotion/react";

type InputTextType = {
  value?: string;
  description?: string;
  placeholder?: string;
  label?: string;
  labelId?: string;
  size?: MantineSize;
  required?: true;
  withAsterisk?: true;
  disabled?: true;
  errors?: {
    error: boolean;
    errorMessage: string;
  };

  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
};

const InputPassword: FC<InputTextType> = ({
  value = "",
  description = "",
  placeholder = "",
  labelId = "",
  size = "md",
  required,
  withAsterisk,
  label,
  errors,
  disabled,
  onChange = (e) => {},
}) => {
  const [visible, setVisible] = useState(false);
  // const [visible, { toggle }] = useDisclosure(false);
  return (
    <>
      <InputTextContainer
        css={css`
          margin-top: 8px;
        `}
      >
        <Input.Wrapper
          inputWrapperOrder={["label", "input", "description", "error"]}
          description={!errors?.error ? description : ""}
          id={labelId}
          withAsterisk={!disabled && withAsterisk}
          label={label}
          error={errors?.errorMessage}
          styles={{
            label: {
              color: `${
                !disabled
                  ? mainTheme.colors.onBackGround
                  : mainTheme.colors.grayOut
              }`,
              fontWeight: 600,
              fontSize: `${mainTheme.fontSize.medium}`,
            },
            error: {
              fontSize: `${mainTheme.fontSize.medium}`,
            },
          }}
        >
          <PasswordInput
            id={labelId}
            required={required}
            value={!disabled ? value : ""}
            placeholder={
              !disabled ? placeholder : "このフィールドは入力できません"
            }
            size={size}
            error={errors?.error}
            onChange={onChange}
            visible={visible}
            onVisibilityChange={() => setVisible(!visible)}
            disabled={disabled}
            styles={{
              input: {
                color: `${mainTheme.colors.onBackGround}`,
                fontWeight: 400,
                "&:focus-withIn": {
                  borderColor: `${mainTheme.colors.primaryPositive}`,
                },
              },
            }}
          />
        </Input.Wrapper>
      </InputTextContainer>
    </>
  );
};

export default InputPassword;
