import { FC } from "react";
import { useNavigate } from "react-router-dom";

import {
  GridSpacer,
  RegisterResultModalContainer,
  RegisterResultModalFooter,
  RegisterResultModalHeader,
  ScrollableContent,
  RegisterResultModalFooterContainer,
  RegisterResultModalFooterButtonContainer,
} from "./RegisterVehicleModalStyle";
import { ModalContent } from "./components/ModalContaent";
import Error from "./components/Error";

import { mainTheme } from "@data/theme/theme";
import DefaultButton from "@ui/components/Button/DefaultButton";
import DefaultModal from "@ui/components/Modal/DefaultModal";
import { PostVehicle } from "@data/types";
import { API_ERROR_CODE } from "@data/constants/apiErrorCode";

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
  vehicle?: PostVehicle;
  isError?: boolean;
  error?: any;
};

type ErrorProp = {
  status?: string;
  errorCode?: string;
};

export const RegisterVehicleResultModal: FC<Props> = ({
  isOpen,
  onRequestClose,
  vehicle,
  isError = false,
  error,
}) => {
  const switchRender = ({ status = "", errorCode = "" }: ErrorProp) => {
    let err_msg = "車両の登録に失敗しました。最初からやり直してください。\n"
    err_msg += "(ステータスコード: " + status + ", エラーコード: " + errorCode + ")"
    switch (true) {
      case /^40/.test(status):
        return (
          <Error
            title="リクエストエラー"
            message={err_msg}
            color="orange"
          />
        );
      case /^50/.test(status):
        return (
          <Error
            title="サーバーエラー"
            message={err_msg}
            color="red"
          />
        );
      default:
        return <ModalContent vehicle={vehicle} />;
    }
  };
  const navigate = useNavigate();
  return (
    <>
      <DefaultModal isOpen={isOpen}>
        <RegisterResultModalContainer>
          <RegisterResultModalHeader>登録結果</RegisterResultModalHeader>

          <ScrollableContent>
            {isError
              ? switchRender({
                  status: error?.response?.status,
                  errorCode: error?.response?.data?.error_code,
                })
              : switchRender({})}
          </ScrollableContent>

          <RegisterResultModalFooter>
            <RegisterResultModalFooterContainer>
              <GridSpacer />
              <GridSpacer />
              <RegisterResultModalFooterButtonContainer>
                <DefaultButton
                  backgroundColor={mainTheme.colors.primaryPositive}
                  hoverColor={mainTheme.colors.primary}
                  color={mainTheme.colors.onPrimary}
                  onClick={() => {
                    navigate("/vehicles");
                  }}
                  buttonText={"OK"}
                  size={"M"}
                  contentWidth={"72px"}
                />
              </RegisterResultModalFooterButtonContainer>
              <GridSpacer />
            </RegisterResultModalFooterContainer>
          </RegisterResultModalFooter>
        </RegisterResultModalContainer>
      </DefaultModal>
    </>
  );
};
