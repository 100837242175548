import React, { FC, ReactNode } from "react";
import {
  RegisterWorkspaceFormContent,
  RegisterWorkspaceFormWrapper,
} from "./registerWorkspaceFromStyle";
import { GridSpacer } from "../../registerWorkspaceWidgetStyle";

type Props = {
  children: ReactNode;
};

export const RegisterWorkspaceForm: FC<Props> = ({ children }) => {
  return (
    <>
      <RegisterWorkspaceFormWrapper>
        <GridSpacer />
        <GridSpacer />
        <RegisterWorkspaceFormContent>{children}</RegisterWorkspaceFormContent>
      </RegisterWorkspaceFormWrapper>
    </>
  );
};
