import React, { FC } from "react";

import { Divider } from "@mantine/core";
import { ResponseWorkspace } from "@data/repositories/Workspace/mutationWorkspace";

import {
  ResultModalLabel,
  ResultModalText,
  UserModalItem,
} from "./modalContentStyle";

type Props = {
  workspace?: ResponseWorkspace;
};

export const ModalContent: FC<Props> = ({ workspace }) => {
  return (
    <>
      <UserModalItem>
        <ResultModalLabel>会社名</ResultModalLabel>
        <ResultModalText>{workspace?.company_nm}</ResultModalText>
      </UserModalItem>

      <Divider />

      <UserModalItem>
        <ResultModalLabel>会社ID</ResultModalLabel>
        <ResultModalText>{workspace?.company_id}</ResultModalText>
      </UserModalItem>

      <Divider />

      <UserModalItem>
        <ResultModalLabel>FMSプロジェクトID</ResultModalLabel>
        <ResultModalText>{workspace?.fms_project_id}</ResultModalText>
      </UserModalItem>

      <Divider />

      <UserModalItem>
        <ResultModalLabel>走行環境名</ResultModalLabel>
        <ResultModalText>{workspace?.workspace_nm}</ResultModalText>
      </UserModalItem>

      <Divider />

      <UserModalItem>
        <ResultModalLabel>職場CD</ResultModalLabel>
        <ResultModalText>{workspace?.workspace_cd}</ResultModalText>
      </UserModalItem>

      <Divider />

      <UserModalItem>
        <ResultModalLabel>FMS走行環境ID</ResultModalLabel>
        <ResultModalText>{workspace?.fms_environment_id}</ResultModalText>
      </UserModalItem>

      <Divider />

      <UserModalItem>
        <ResultModalLabel>管理者パスワード</ResultModalLabel>
        <ResultModalText>{workspace?.admin_password}</ResultModalText>
      </UserModalItem>
    </>
  );
};
