import styled from "@emotion/styled";

export const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LoadingText = styled.p`
  color: ${({ theme }) => theme.colors.onBackGround};
  font-size: ${({ theme }) => theme.fontSize.medium};
`;
