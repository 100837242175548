import styled from "@emotion/styled";
import {
  RegisterResultModalContainer,
  RegisterResultModalFooter,
  RegisterResultModalFooterButtonContainer,
  RegisterResultModalFooterContainer,
  RegisterResultModalHeader,
} from "../RegisterVehicleResultModal/RegisterVehicleModalStyle";

export const GridSpacer = styled.div``;

export const ResultModalContainer = styled(RegisterResultModalContainer)``;

export const ResultModalHeader = styled(RegisterResultModalHeader)``;

export const ResultModalFooter = styled(RegisterResultModalFooter)``;

export const ResultFooterContainer = styled(
  RegisterResultModalFooterContainer
)``;

export const ResultModalFooterButtonContainer = styled(
  RegisterResultModalFooterButtonContainer
)``;

export const ScrollableContent = styled.div`
  flex: 12;
  overflow-y: scroll;
  padding: 1em;

  /// scroll-bar 消去 edge
  -ms-overflow-style: none;
  /// scroll-bar 消去 chrome, safari
  &::-webkit-scrollbar {
    display: none;
  }
  /// scroll-bar 消去 firefox
  scrollbar-width: none;

  @media (max-height: 681px) {
    -ms-overflow-style: auto;
    &::-webkit-scrollbar {
      display: auto;
    }
    scrollbar-width: auto;
  }
`;
