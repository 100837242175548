import { FC } from "react";

import { User } from "@data/entities/User/User";
import { useMutationUser } from "@data/repositories/User/mutationUser";
import { DefaultLoadingOverlay } from "@ui/components/LoadingOverlay/DefualtLoadingOverlay";

import { UserList, UserListContainer } from "./usersWidgetStyle";
import UserItem from "./components/UserItem/UserItem";

type Props = {
  users: User[];
};

export const UsersWidget: FC<Props> = ({ users }) => {
  const { updateUserActive } = useMutationUser();

  const onSubmitActive = (user: User) => {
    updateUserActive.mutate(user, {
      onError: (err: any) => {
        switch(err.response.data.error_code){
          case "E400005":
            alert("ユーザーが存在しません");
            break;
          case "E400007":
            alert("走行環境が存在しません");
            break;
          case "E400018":
            alert("走行環境が無効です");
            break;
          default:
            alert("リクエストに失敗しました");
            break;
        }
      },
    });
  };

  return (
    <>
      <UserListContainer id="test">
        <UserList>
          {users.length > 0 &&
            users.map((user, index) => (
              <UserItem
                user={user}
                onSubmitActive={onSubmitActive}
                key={index}
              />
            ))}
        </UserList>
      </UserListContainer>
      {updateUserActive.isLoading && (
        <DefaultLoadingOverlay
          visible={updateUserActive.isLoading}
          loaderSize="lg"
        />
      )}
    </>
  );
};

export default UsersWidget;
