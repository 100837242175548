import styled from "@emotion/styled";
import { GRID_BREAKPOINTS } from "@data/constants/breakPoint";

export const VehicleDetailScreenContainer = styled.div`
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex: 12;
  background-color: ${(props) => props.theme.colors.background};
`;

export const ScrollableContent = styled.div`
  width: 100%;
  overflow-y: scroll;
  /// scroll-bar 消去 edge
  -ms-overflow-style: none;
  /// scroll-bar 消去 chrome, safari
  &::-webkit-scrollbar {
    display: none;
  }
  /// scroll-bar 消去 firefox
  scrollbar-width: none;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

export const FooterContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr;

  @media (max-width: ${GRID_BREAKPOINTS.lg}) {
    display: grid;
    grid-template-columns: 0.5fr 1fr 0.5fr;
  }
`;

export const GridSpacer = styled.div``;
