import React, { FC, ReactNode } from "react";
import Error from "./components/Error";

export type ErrorProp = {
  status?: string;
  errorCode?: string;
};

type Props = {
  children: ReactNode;
  isError?: boolean;
  error: any;
  msg: string;
};

const ModalResults: FC<Props> = ({ children, isError = false, error, msg}) => {
  const switchRender = ({ status = "", errorCode = "" }: ErrorProp) => {
    let err_msg = msg + "に失敗しました。最初からやり直してください。\n"
    err_msg += "(ステータスコード: " + status + ", エラーコード: " + errorCode + ")"
    switch (true) {
      case /^40/.test(status):
        return (
          <Error
            title="リクエストエラー"
            message={err_msg}
            color="orange"
          />
        );
      case /^50/.test(status):
        return (
          <Error
            title="サーバーエラー"
            message={err_msg}
            color="orange"
          />
        );
      default:
        return children;
    }
  };
  return (
    <>
      {isError
        ? switchRender({
            status: error?.response?.status,
            errorCode: error?.response?.data?.error_code,
          })
        : switchRender({})}
    </>
  );
};

export default ModalResults;
