import styled from "@emotion/styled";

export const GridSpacer = styled.div``;

export const DeleteModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const DeleteModalHeader = styled.h2`
  color: ${(props) => props.theme.colors.onBackGround};
  padding: 8px 16px;
  border-radius: 8px;
  flex: 1;
  margin: 0;
`;

export const DeleteListTitle = styled.div`
  display: flex;
  align-items: center;
  display: grid;
  grid-template-columns: 0.2fr 1fr 1fr 1fr 1fr 0.2fr;
  margin-bottom: 8px;
  margin-top: 8px;
  flex: 1;
`;

export const DeleteModalText = styled.span`
  color: ${(props) => props.theme.colors.onBackGround};
  font-weight: 600;
`;

export const DeleteModalFooter = styled.span`
  width: 100%;
  flex: 2;
`;

export const DeleteModalFooterContainer = styled.div`
  display: flex;
  height: 100%;
  display: grid;
  grid-template-columns: 0.2fr 3fr 1fr 0.2fr;
`;

export const DeleteModalFooterButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
  padding: 0 0px;
`;

export const ScrollableContent = styled.div`
  flex: 12;
  width: 100%;
  overflow-y: scroll;

  /// scroll-bar 消去 edge
  -ms-overflow-style: none;
  /// scroll-bar 消去 chrome, safari
  &::-webkit-scrollbar {
    display: none;
  }
  /// scroll-bar 消去 firefox
  scrollbar-width: none;
`;
