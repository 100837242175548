import React, { ChangeEvent, FC, FormEvent, useState } from "react";
import { z } from "zod";
import { useNavigate } from "react-router-dom";

import { mainTheme } from "@data/theme/theme";
import DefaultButton from "@ui/components/Button/DefaultButton";
import Footer from "@ui/components/Footer/Footer";
import { ACTIVE } from "@data/constants/entity";
import { Workspace } from "@data/entities/Workspace/Workspace";
import { useSelectorOption } from "utils/useSelectorOption";
import { EditedVehicle } from "@data/types";
import { CheckVehicleModal } from "@ui/modals/CheckVehicleModal";

import {
  ButtonContainer,
  FooterContainer,
  GridSpacer,
  RegisterVehicleForm,
  RegisterVehicleFormWrapper,
} from "./registerVehicleWidgetStyle";
import { VehicleFormDropdown } from "./components/VehicleFormDropdown";
import { VehicleFormInput } from "./components/VehicleFormInput";
import { CompanyFormDropdown } from "./components/CompanyFromDropdown";
import { GetCompany } from "@data/repositories/Company/queryComany";

type Props = {
  workspaces?: Workspace[];
  companies?: GetCompany[];
};

type validationSchema = Pick<EditedVehicle, "vehicle_id" | "alias_name"> & {
  workspace_m: {
    workspace_cd: string;
    company_id: string;
  };
  company_m: {
    company_id: string;
  };
  user_m: {
    workspace_cd: string;
  };
};

const initialValue: EditedVehicle = {
  vehicle_id: "",
  alias_name: "",
  user_id: "",
  is_active: ACTIVE.ENABLE,
  workspace_m: {
    workspace_cd: "",
    workspace_nm: "",
    company_id: "",
  },
  company_m: {
    company_id: "",
    company_nm: "",
    fms_project_id: "",
    is_active: ACTIVE.ENABLE,
  },
  user_m: {
    workspace_cd: "",
  },
};
const initialErrors: validationSchema = {
  vehicle_id: "",
  alias_name: "",
  workspace_m: {
    workspace_cd: "",
    company_id: "",
  },
  company_m: {
    company_id: "",
  },
  user_m: {
    workspace_cd: "",
  },
};

const RegisterVehicleWidget: FC<Props> = ({ companies, workspaces }) => {
  const [editedVehicle, setEditedVehicle] =
    useState<EditedVehicle>(initialValue);
  const [modalOpen, setModalOpen] = useState(false);
  const { setSelectorOption, parseSelectorOption } = useSelectorOption();
  const [errors, setErrors] = useState(initialErrors);
  const navigate = useNavigate();

  const inputHandler =
    (keyType: keyof EditedVehicle) => (e: ChangeEvent<HTMLInputElement>) => {
      setEditedVehicle({ ...editedVehicle, [keyType]: e.target.value });
    };

  const prams = (objectKey: keyof EditedVehicle) => {
    return {
      value: editedVehicle[`${objectKey}`],
      onChange: inputHandler(`${objectKey}`),
    };
  };

  const throwableSchema = z.object<{
    [key in keyof validationSchema]: any;
  }>({
    vehicle_id: z
      .string()
      .regex(/^[-0-9a-zA-Z]*$/, "半角英数字、「-」、のみ入力できます")
      .nonempty("このフィールドは必須です"),
    alias_name: z
      .string()
      .nonempty("このフィールドは必須です"),
    workspace_m: z.object({
      workspace_cd: z
        .string()
        .nonempty("このフィールドは必須です"),
    }),
    company_m: z.object({
      company_id: z
        .string()
        .nonempty("このフィールドは必須です"),
    }),
    user_m: z.object({
      workspace_cd: z
        .string()
        .regex(/^[0-9a-zA-Z_-]*$/, "半角英数字、「-」、「_」のみ入力できます")
        .nonempty("このフィールドは必須です"),
    }),
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      throwableSchema.parse(editedVehicle);

      setErrors(initialErrors);

      //車両ID重複チェックが必要
      setModalOpen(true);
    } catch (e: any) {
      setErrors({
        ...e.flatten().fieldErrors,
        workspace_m: {
          workspace_cd: e.flatten().fieldErrors.workspace_m,
        },
        company_m: {
          company_id: e.flatten().fieldErrors.company_m,
        },
        user_m: {
          workspace_cd: e.flatten().fieldErrors.user_m,
        },
      });
    }
  };

  const companyOptions = companies
    ? companies.map((company) => {
        return {
          value: setSelectorOption({ ...company }),
          label: `${company.company_nm}`,
        };
      })
    : [];

  const filterWorkspace = (
    mach: string | undefined,
    workspaces: Workspace[]
  ): Workspace[] => {
    if (!workspaces) return [];
    return workspaces.filter((workspace) => {
      return mach ? mach === workspace.company_id : true;
    });
  };

  const workspaceOptions = workspaces
    ? filterWorkspace(editedVehicle.company_m.company_id, workspaces).map(
        (workspace) => {
          return {
            value: setSelectorOption({ ...workspace }),
            label: `${workspace.workspace_nm}`,
          };
        }
      )
    : [];

  /// enter_key無効化
  document.onkeydown = function (e) {
    // エンターキーだったら無効にする
    if (e.key === "Enter") {
      return false;
    }
  };

  return (
    <>
      <RegisterVehicleFormWrapper>
        <GridSpacer />
        <RegisterVehicleForm onSubmit={handleSubmit}>
          <CompanyFormDropdown
            headLabel="会社名"
            required
            withAsterisk
            label="会社名"
            placeholder="１つ選択"
            size={"xs"}
            options={companyOptions}
            onSelect={(e: string | null) => {
              setEditedVehicle({
                ...editedVehicle,
                company_m: parseSelectorOption(e, initialValue.company_m),
              });
            }}
            onClick={() => {
              if (editedVehicle.workspace_m.company_id) {
                alert("走行環境名をクリアしてから選択して下さい");
                return;
              }
            }}
            errorMessage={errors.company_m.company_id}
          />

          <VehicleFormDropdown
            headLabel="走行環境名"
            required
            withAsterisk
            label="走行環境名"
            labelId={"label_workspace_cd"}
            placeholder="１つ選択"
            options={workspaceOptions}
            size={"xs"}
            onSelect={(e: string | null) => {
              setEditedVehicle({
                ...editedVehicle,
                workspace_m: parseSelectorOption(e, initialValue.workspace_m),
              });
            }}
            onClick={() => {
              if (!editedVehicle.company_m.company_id) {
                alert("会社名を選択してから選択して下さい");
              }
            }}
            errorMessage={errors.workspace_m.workspace_cd}
          />
          <VehicleFormInput
            withAsterisk
            label="FMS車両ID"
            labelId="label_vehicle_id"
            placeholder="半角英数で入力"
            {...prams("vehicle_id")}
            size={"xs"}
            headLabel="FMS車両ID"
            description="例： aaaaaaaa-bbbb-cccc-dddd-eeeeeeeeeeee"
            errors={{
              error: Boolean(errors.vehicle_id),
              errorMessage: errors.vehicle_id,
            }}
          />

          <VehicleFormInput
            withAsterisk
            label="車両名"
            labelId="label_vehicle_nm"
            placeholder="半角英数を入力"
            value={editedVehicle.user_m.workspace_cd}
            onChange={(e) => {
              setEditedVehicle({
                ...editedVehicle,
                user_m: {
                  workspace_cd: e.target.value,
                },
              });
            }}
            size={"xs"}
            headLabel="車両名"
            description="例： eva_j4j1-0046 など"
            errors={{
              error: Boolean(errors.user_m.workspace_cd),
              errorMessage: errors.user_m.workspace_cd,
            }}
          />

          <VehicleFormInput
            label="エイリアス"
            labelId="label_alias_name"
            placeholder="文字を入力"
            {...prams("alias_name")}
            size={"xs"}
            headLabel="エイリアス"
            description="例： 19号車 など"
            errors={{
              error: Boolean(errors.alias_name),
              errorMessage: errors.alias_name,
            }}
          />

          <GridSpacer />
          <Footer>
            <FooterContainer>
              <GridSpacer />

              <ButtonContainer>
                <DefaultButton
                  type="button"
                  backgroundColor={"white"}
                  hoverColor={mainTheme.colors.secondary}
                  color={mainTheme.colors.onSecondary}
                  onClick={() => {
                    navigate("/vehicles");
                  }}
                  buttonText={"キャンセル"}
                  size={"M"}
                  border
                  borderColor={mainTheme.colors.secondaryContainer}
                  fontWeight={"600"}
                />
                <DefaultButton
                  type="submit"
                  backgroundColor={mainTheme.colors.primaryPositive}
                  hoverColor={mainTheme.colors.primary}
                  color={mainTheme.colors.onPrimary}
                  onClick={() => {}}
                  buttonText={"登録"}
                  size={"M"}
                  contentWidth={"96px"}
                />
              </ButtonContainer>
              <GridSpacer />
            </FooterContainer>
            {modalOpen && (
              <CheckVehicleModal
                editedVehicle={editedVehicle}
                isOpen={modalOpen}
                onRequestClose={() => {}}
                onClose={() => setModalOpen(false)}
              />
            )}
          </Footer>
        </RegisterVehicleForm>
      </RegisterVehicleFormWrapper>
    </>
  );
};

export default RegisterVehicleWidget;
