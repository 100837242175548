import { mainTheme } from "@data/theme/theme";
import { ThemeProvider } from "@emotion/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { FC, ReactNode } from "react";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 5 * 60 * 1000,
      refetchInterval: Infinity,
      retry: false,
      staleTime: 0,
    },
  },
});

const Providers: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={mainTheme}>{children}</ThemeProvider>
      </QueryClientProvider>
    </>
  );
};

export default Providers;
