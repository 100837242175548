import { FC, useEffect, useState } from "react";

import { mainTheme } from "@data/theme/theme";
import DefaultButton from "@ui/components/Button/DefaultButton";
import DefaultModal from "@ui/components/Modal/DefaultModal";
import { VehicleUnique } from "@data/types";

import {
  VehicleCheckModalContainer,
  VehicleCheckModalFooter,
  ModalHeading,
  FooterButtonContainer,
  ModalContentWrapper,
  VehicleCheckModalHeader,
  ModalSpan,
} from "./CheckVehicleWithEnvModalStyle";
import { queryVehicle } from "@data/repositories/Vehicle/queryVehicle";
import { VehicleDetailResultModal } from "../VehicleDetailResultModal";
import VehicleIdNotification from "./components/VehicleNotification";
import { VehicleExistCheckNotification } from "./components/VehicleExistNotification";
import { UpdateVehiclesEnvironment, useMutationVehicle } from "@data/repositories/Vehicle/mutationVehicle";
import { DefaultLoadingOverlay } from "@ui/components/LoadingOverlay/DefualtLoadingOverlay";
import { ConsoleLogger } from "@aws-amplify/core";
import { error } from "console";
import { EditedVehicleEnvironment } from "@ui/widgets/VehicleDetailWidget";

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
  onClose?: () => void;
  editedVehicleEnv: EditedVehicleEnvironment;
  isStockCheck: boolean;
};

export const CheckVehicleWithEnvModal: FC<Props> = ({
  isOpen,
  editedVehicleEnv,
  isStockCheck,
  onRequestClose,
  onClose = () => {},
}) => {
  const [isRegister, setIsRegister] = useState(false);
  const [modalProps, setModalProps] = useState<UpdateVehiclesEnvironment>();
  const [modalOpen, setModalOpen] = useState(false);
  const [idCheck, setIdCheck] = useState<{ result: "true" | "false" }>();
  const [errors, setErrors] = useState({
    idCheck: false,
    uniqueCheck: false,
  });
  const [uniqueCheck, setUniqueCheck] = useState<VehicleUnique[]>();
  const { postVehicleIdCheck, getVehicleUniqueCheck } = queryVehicle();
  const { updateVehicleEnvironment } = useMutationVehicle();

  const handleIdCheck = async (
    next_vehicle_id: string,
    workspace_cd: string,
  ): Promise<[{ result: "true" | "false" }?, any?]> => {
    try {
      if (isStockCheck) {
        return [undefined, undefined];
      }
      const idCheck = await postVehicleIdCheck(next_vehicle_id, workspace_cd, "", "");
      if (!["true", "false"].includes(idCheck.result)) {
        return [undefined, "error"];
      }
      return [idCheck, undefined];
    } catch (error) {
      return [undefined, error];
    }
  };
  const handleUniqueCheck = async (
    next_vehicle_id: string
  ): Promise<[VehicleUnique[]?, any?]> => {
    try {
      const uniqueCheck = await getVehicleUniqueCheck(next_vehicle_id);
      return [uniqueCheck, undefined];
    } catch (error) {
      return [undefined, error];
    }
  };

  useEffect(() => {
    const vehicleCheck = async () => {
      const [idCheck, idCheckError] = await handleIdCheck(
        editedVehicleEnv.next_vehicle_id,
        editedVehicleEnv.workspace_cd,
        );
      if (idCheckError) {
        setErrors((pre) => {
          return {
            ...pre,
            idCheck: true,
          };
        });
        return;
      }

      if (!isStockCheck) {
        idCheck && setIdCheck({ result: idCheck.result });
        if (!idCheck?.result) {
          return;
        }
      } else {
        setIdCheck({ result: "true" });
      }

      const [unique, uniqueCheckError] = await handleUniqueCheck(editedVehicleEnv.next_vehicle_id);
      if (uniqueCheckError) {
        setErrors((pre) => {
          return {
            ...pre,
            uniqueCheck: true,
          };
        });
      }

      if (unique) {
        setUniqueCheck(unique);
        setIsRegister(true);
      }
    };

    vehicleCheck();

    if (errors.idCheck || errors.uniqueCheck) {
      alert("リクエストに失敗しました");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors.idCheck, errors.uniqueCheck]);

  const handleSubmit = () => {
    updateVehicleEnvironment.mutate(
    editedVehicleEnv,
    {
      onSuccess(data, variables, context) {
        setModalProps(data);
        setModalOpen(true);
      },

      onError: () => {
        setModalOpen(true);
      },
    });
  };

  const idCheckOK = idCheck?.result === "true";
  const idCheckNoEmpty = idCheck?.result === "false";
  const uniqueCheckEmpty = idCheck?.result === "true" && uniqueCheck?.length === 0;
  const uniqueCheckNoEmpty = uniqueCheck && uniqueCheck?.length > 0;

  /// enter_key無効化
  document.onkeydown = function (e) {
    // エンターキーだったら無効にする
    if (e.key === "Enter") {
      return false;
    }
  };

  return (
    <>
      <DefaultModal
        isOpen={isOpen}
        maxWidth="870px"
        height="440px"
        padding="5vh 6vh"
        onRequestClose={onRequestClose}
      >
        <VehicleCheckModalContainer>
          <VehicleCheckModalHeader>
            <ModalHeading>走行環境切替確認</ModalHeading>
          </VehicleCheckModalHeader>

          <ModalContentWrapper>
            <VehicleIdNotification
              loading={!idCheck && !errors.idCheck}
              isError={errors.idCheck || Boolean(!idCheck)}
              isSkip={isStockCheck}
              title="FMS車両ID"
              idCheckError={idCheck?.result === "false"}
              check={!errors.idCheck && idCheck?.result === "true"}
            />

            {idCheckOK && (
              <VehicleExistCheckNotification
                loading={!uniqueCheck && !errors.uniqueCheck}
                isError={errors.uniqueCheck}
                title={"車両重複チェック"}
                noExist={
                  !errors.uniqueCheck && uniqueCheck && uniqueCheck.length === 0
                }
                uniqueCheck={uniqueCheck && uniqueCheck[0]}
              />
            )}
          </ModalContentWrapper>

          <VehicleCheckModalFooter>
            <ModalSpan>
              {uniqueCheckNoEmpty ? "このデータを上書きして走行環境を変更しますか？" : ""}
              {uniqueCheckEmpty ? "このデータで走行環境を変更しますか？" : ""}
              {idCheckNoEmpty ? "入力情報を確認してください" : ""}
            </ModalSpan>
            <FooterButtonContainer>
              <DefaultButton
                backgroundColor={mainTheme.colors.secondaryPositive}
                hoverColor={mainTheme.colors.secondary}
                color={mainTheme.colors.onSecondary}
                border
                borderColor={mainTheme.colors.secondaryContainer}
                onClick={onClose}
                buttonText={"戻る"}
                size={"S"}
                contentWidth={"80px"}
              />
              {
                <DefaultButton
                  disabled={!isRegister}
                  backgroundColor={mainTheme.colors.primaryPositive}
                  hoverColor={mainTheme.colors.primary}
                  color={mainTheme.colors.onPrimary}
                  onClick={handleSubmit}
                  buttonText={"切替"}
                  size={"S"}
                  contentWidth={"80px"}
                />
              }
              {modalOpen && (
                <VehicleDetailResultModal
                  isOpen={modalOpen}
                  vehicle={modalProps}
                  isError={updateVehicleEnvironment.isError}
                  error={updateVehicleEnvironment.error}
                />
              )}
              {updateVehicleEnvironment.isLoading && (
                <DefaultLoadingOverlay visible={true} />
              )}
            </FooterButtonContainer>
          </VehicleCheckModalFooter>
        </VehicleCheckModalContainer>
        {}
      </DefaultModal>
    </>
  );
};
