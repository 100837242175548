import styled from "@emotion/styled";

export const RegisterWorkspaceContainer = styled.div`
  flex: 12;
  width: 100%;
  background-color: ${(props) => props.theme.colors.background};

  overflow-y: scroll;
  /// scroll-bar 消去 edge
  -ms-overflow-style: none;
  /// scroll-bar 消去 chrome, safari
  &::-webkit-scrollbar {
    display: none;
  }
  /// scroll-bar 消去 firefox
  scrollbar-width: none;
`;
