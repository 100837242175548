import { DropDownOption } from "@data/types";
import { MantineSize } from "@mantine/core";
import Dropdown from "@ui/components/Dropdown/Dropdown";
import { FC, memo } from "react";

type Props = {
  label?: string;
  labelId?: string;
  placeholder?: string;
  options?: DropDownOption[];
  size?: MantineSize;
  required?: true;
  withAsterisk?: true;
  onSelect?: (selectedValue: string | null) => void;
  onClick?: () => void;
  errorMessage?: string;
  defaultValue?: string;
};

const UserFormDropDownMemo: FC<Props> = ({
  label = "",
  placeholder = "",
  options = [],
  size = "md",
  required,
  withAsterisk,
  onSelect,
  onClick,
  errorMessage,
  defaultValue,
}) => {
  return (
    <>
      <Dropdown
        defaultValue={defaultValue}
        required={required}
        withAsterisk={withAsterisk}
        label={label}
        placeholder={placeholder}
        options={options}
        size={size}
        onSelect={onSelect}
        onClick={onClick}
        errorMessage={errorMessage}
      />
    </>
  );
};

export const UserFormDropDown = memo(UserFormDropDownMemo);
