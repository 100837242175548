import { IconCircleCheckFilled } from "@tabler/icons-react";
import { Alert } from "@mantine/core";

import { DeleteModalListContainer } from "./deleteSucessStyle";

export const DeleteSuccess = () => {
  return (
    <>
      <DeleteModalListContainer>
        <Alert
          title={"レスポンス"}
          color={"cyan"}
          variant={"light"}
          icon={<IconCircleCheckFilled size={"3rem"} />}
        >
          {"削除に成功しました"}
        </Alert>
      </DeleteModalListContainer>
    </>
  );
};

export default DeleteSuccess;
