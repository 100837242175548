import { FC, ReactNode } from "react";
import { IconAlertCircleFilled, IconCheck, IconX } from "@tabler/icons-react";
import { Notification } from "@mantine/core";

import { mainTheme } from "@data/theme/theme";

import { SizeProp } from "../../../@types/theme";

type Props = {
  isError?: boolean;
  isSkip?: boolean;
  loading?: boolean;
  withCloseButton?: boolean;
  withBorder?: boolean;
  title?: string;
  iconSize?: SizeProp;
  children: ReactNode;
  caution?: boolean;
};

export const LoadingNotification: FC<Props> = ({
  isError,
  isSkip,
  loading,
  withCloseButton,
  withBorder = true,
  title,
  iconSize = "1.2rem",
  children,
  caution,
}) => {
  const renderIcon = (isError?: boolean, caution?: boolean) => {
    const icons = {
      isError: <IconX size={iconSize} />,
      caution: <IconAlertCircleFilled size={"3rem"} />,
      success: <IconCheck size={iconSize} />,
    };

    if (isSkip) {
      return icons["success"];
    }

    if (isError) {
      return icons["isError"];
    }

    if (caution) {
      return icons["caution"];
    }
    return icons["success"];
  };
  return (
    <>
      <Notification
        withCloseButton={withCloseButton}
        withBorder={withBorder}
        loading={loading}
        icon={renderIcon(isError, caution)}
        title={title}
        styles={{
          icon: {
            backgroundColor: `${
              caution
                ? mainTheme.colors.warning
                : isError
                ? mainTheme.colors.error
                : mainTheme.colors.primary
            }`,
          },
          loader: {
            stroke: `${mainTheme.colors.primary}`,
          },
        }}
      >
        {children}
      </Notification>
    </>
  );
};
