import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { mainTheme } from "@data/theme/theme";
import DefaultButton from "@ui/components/Button/DefaultButton";

import {
  GridSpacer,
  FooterButtonContainer,
  FooterContainer,
} from "./deleteItemFooterStyle";

type Props = {
  onOpenDialog?: () => void;
};

const RenderFooter: FC<Props> = ({ onOpenDialog = () => {} }) => {
  const navigate = useNavigate();

  return (
    <FooterContainer>
      <DefaultButton
        backgroundColor={mainTheme.colors.secondaryPositive}
        hoverColor={mainTheme.colors.secondary}
        border
        borderColor={mainTheme.colors.secondaryContainer}
        buttonText={"戻る"}
        size={"M"}
        color={mainTheme.colors.onSecondary}
        contentWidth="100px"
        onClick={() => {
          navigate("/vehicles");
        }}
      />
      <GridSpacer />
      <FooterButtonContainer>
        <DefaultButton
          backgroundColor={mainTheme.colors.primaryPositive}
          hoverColor={mainTheme.colors.primary}
          buttonText={"削除"}
          contentWidth="100px"
          size={"M"}
          color={mainTheme.colors.onPrimary}
          onClick={onOpenDialog}
        />
      </FooterButtonContainer>

      <GridSpacer />
    </FooterContainer>
  );
};

export default RenderFooter;
