import styled from "@emotion/styled";

export const AuthLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 680px;
  height: 100vh;
`;

export const AuthContainer = styled.form`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 600px;
`;

export const ButtonContainer = styled.div`
  margin-top: 4rem;
  display: flex;
  justify-content: space-between;
`;
