import styled from "@emotion/styled";
import {
  RegisterResultModalLabel,
  RegisterModalItem,
} from "@ui/modals/RegisterVehicleResultModal/components/ModalContaent/modalContentStyle";

export const ResultModalLabel = styled(RegisterResultModalLabel)``;

export const ResultModalText = styled(RegisterResultModalLabel)`
  font-weight: 400;
  word-break: break-all;
`;

export const UserModalItem = styled(RegisterModalItem)``;
