import { FC } from "react";

import { mainTheme } from "@data/theme/theme";
import DefaultButton from "@ui/components/Button/DefaultButton";
import Divider from "@ui/components/Divider/Divider";
import DefaultModal from "@ui/components/Modal/DefaultModal";

import {
  GridSpacer,
  DeleteModalContainer,
  DeleteModalFooter,
  DeleteModalHeader,
  DeleteListTitle,
  DeleteModalText,
  ScrollableContent,
  DeleteModalFooterContainer,
  DeleteModalFooterButtonContainer,
} from "./deleteModalStyle";
import { GetVehicle } from "@data/repositories/Vehicle/queryVehicle";
import DeleteVehicleList from "./components/DeleteModalLIst";

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
  deletedVehicles: GetVehicle[];
  onSubmit?: () => void;
};

const DeleteConfirmVehicleModal: FC<Props> = ({
  isOpen,
  deletedVehicles,
  onRequestClose,
  onSubmit = () => {},
}) => {
  return (
    <DefaultModal isOpen={isOpen} onRequestClose={() => {}}>
      <DeleteModalContainer>
        <DeleteModalHeader>削除一覧</DeleteModalHeader>

        <DeleteListTitle>
          <GridSpacer />
          <DeleteModalText>車両ID</DeleteModalText>
          <DeleteModalText>車両名</DeleteModalText>
          <DeleteModalText>走行環境名</DeleteModalText>
          <GridSpacer />
        </DeleteListTitle>
        <Divider thin="1px" rounded />

        <ScrollableContent>
          <DeleteVehicleList items={deletedVehicles} />
        </ScrollableContent>

        <DeleteModalFooter>
          <DeleteModalFooterContainer>
            <GridSpacer />
            <GridSpacer />
            <DeleteModalFooterButtonContainer>
              <DefaultButton
                backgroundColor={mainTheme.colors.secondaryPositive}
                hoverColor={mainTheme.colors.secondary}
                color={mainTheme.colors.onSecondary}
                border
                borderColor={mainTheme.colors.secondaryContainer}
                onClick={onRequestClose}
                buttonText={"キャンセル"}
                size={"XS"}
                contentWidth={"72px"}
              />
              <DefaultButton
                backgroundColor={mainTheme.colors.primaryPositive}
                hoverColor={mainTheme.colors.primary}
                color={mainTheme.colors.onPrimary}
                onClick={onSubmit}
                buttonText={"削除"}
                size={"XS"}
                contentWidth={"72px"}
              />
            </DeleteModalFooterButtonContainer>
            <GridSpacer />
          </DeleteModalFooterContainer>
        </DeleteModalFooter>
      </DeleteModalContainer>
    </DefaultModal>
  );
};

export default DeleteConfirmVehicleModal;
