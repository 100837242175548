import axios from "axios";

import { Vehicle } from "@data/entities/Vehicle/Vehicle";
import {
  EditedVehicle,
  VehicleIdCheckResponse,
  VehicleUnique,
} from "@data/types";
import { useQuery } from "@tanstack/react-query";
import { Workspace } from "@data/entities/Workspace/Workspace";
import { Company } from "@data/entities/Company/Company";
import { refreshSession } from "../Auth/auth";

export type GetVehicle = Vehicle & {
  workspace_m: Pick<Workspace, "company_id" | "workspace_nm">;
};
const HOST = `${process.env.REACT_APP_ENDPOINT}`;

export const queryVehicle = () => {
  ///  車両一覧
  const useVehicleList = () => {
    const fetchVehicles = async () => {
      const url = `${HOST}/vehicles`;
      const {data } = await axios.get<{ data: GetVehicle[] }>(url, {
        headers: {
          Authorization: await refreshSession(),
        },
      });
      return data.data;
    };
    return useQuery(["vehicles"], fetchVehicles, {
      staleTime: 0,
    });
  };

  
  /// FMS車両存在確認
  const postVehicleIdCheck = async (
    vehicle_id: string,
    workspace_cd: string,
    fms_project_id?: string,
    fms_environment_id?: string,
  ): Promise<{ result: "true" | "false" }> => {
    const url = `${HOST}/vehicles/${vehicle_id}/exist`;
    const { data } = await axios.post<VehicleIdCheckResponse>(
      url,
      {
        fms_project_id: fms_project_id,
        fms_environment_id: fms_environment_id,
        workspace_cd: workspace_cd,
      },
      {
        headers: {
          Authorization: await refreshSession(),
        },
      }
    );
    return data;
  };

  /// 車両重複確認
  const getVehicleUniqueCheck = async (
    vehicle_id: string
  ): Promise<VehicleUnique[]> => {
    const url = `${HOST}/vehicles/${vehicle_id}/is-unique`;
    const { data } = await axios.get<{ data: VehicleUnique[] }>(url, {
      headers: {
        Authorization: await refreshSession(),
      },
    });
    return data.data;
  };

  return {
    useVehicleList,
    postVehicleIdCheck,
    getVehicleUniqueCheck,
  };
};
