import styled from "@emotion/styled";

export const ConfirmDialogStyle = {
  Content: styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,

  Heder: styled.div`
    height: 100%;
    display: flex;
    align-items: center;
  `,
};

export const Description = {
  Container: styled.div`
    width: 100%;
    text-align: center;
  `,

  Text: styled.span`
    color: ${({ theme }) => theme.colors.onBackGround};
    font-size: ${({ theme }) => theme.fontSize.large};
    font-weight: 600;
  `,
};

export const ConfirmButton = {
  Container: styled.div`
    display: flex;
    justify-content: space-around;
  `,
};
