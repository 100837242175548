import styled from "@emotion/styled";

export const DeleteUserListContainer = styled.div`
  flex: 12;
  width: 100%;
  overflow-y: scroll;

  /// scroll-bar 消去 edge
  -ms-overflow-style: none;
  /// scroll-bar 消去 chrome, safari
  &::-webkit-scrollbar {
    display: none;
  }
  /// scroll-bar 消去 firefox
  scrollbar-width: none;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const Message = styled.p`
  font-size: ${({ theme }) => theme.fontSize.xLarge};
  color: ${({ theme }) => theme.colors.onBackGround};
  font-weight: 600;
`;
