import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { mainTheme } from "@data/theme/theme";
import DefaultButton from "@ui/components/Button/DefaultButton";
import BackToTopButton from "@ui/components/Button/BackToTopButton/BackToTopButton";

import {
  GridSpacer,
  VehiclesFooterButtonContainer,
  VehiclesFooterContainer,
} from "./renderFooterStyle";

const RenderFooter: FC = () => {
  const navigate = useNavigate();

  return (
    <VehiclesFooterContainer>
      <BackToTopButton />

      <GridSpacer />
      <GridSpacer />

      <VehiclesFooterButtonContainer>
        <DefaultButton
          backgroundColor={mainTheme.colors.primaryPositive}
          hoverColor={mainTheme.colors.primary}
          buttonText={"車両削除"}
          size={"M"}
          color={mainTheme.colors.onPrimary}
          onClick={() => {
            navigate("/vehicles/delete");
          }}
          contentWidth="144px"
        />
      </VehiclesFooterButtonContainer>

      <GridSpacer />
    </VehiclesFooterContainer>
  );
};

export default RenderFooter;
