import { FC } from "react";
import { mainTheme } from "../../../data/theme/theme";
import DefaultButton from "../../components/Button/DefaultButton";
import DefaultModal from "../../components/Modal/DefaultModal";
import {
  GridSpacer,
  ResultModalContainer,
  ResultModalFooter,
  ResultModalHeader,
  ScrollableContent,
  ResultFooterContainer,
  ResultModalFooterButtonContainer,
} from "./RegisterWorkspaceModalStyle";
import { ModalContent } from "./components/ModalContaent";
import { useNavigate } from "react-router-dom";
import { ResponseWorkspace } from "@data/repositories/Workspace/mutationWorkspace";
import Error from "./components/Error";

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
  workspace?: ResponseWorkspace;
  isError?: boolean;
  error?: any;
};

type ErrorProp = {
  status?: string;
  errorCode?: string;
};

export const RegisterWorkspaceResultModal: FC<Props> = ({
  isOpen,
  onRequestClose,
  workspace,
  isError = false,
  error,
}) => {
  const switchRender = ({ status = "", errorCode = "" }: ErrorProp) => {
    let err_msg = "走行環境の登録に失敗しました。最初からやり直してください。\n"
    err_msg += "(ステータスコード: " + status + ", エラーコード: " + errorCode + ")"
    switch (true) {
      case /^40/.test(status):
        return (
          <Error
            title="リクエストエラー"
            message={err_msg}
            color="orange"
          />
        );

      case /^50/.test(status):
        return (
          <Error
            title="サーバーエラー"
            message={err_msg}
            color="red"
          />
        );
      default:
        return <ModalContent workspace={workspace} />;
    }
  };

  const navigate = useNavigate();

  return (
    <>
      <DefaultModal isOpen={isOpen}>
        <ResultModalContainer>
          <ResultModalHeader>登録結果</ResultModalHeader>

          <ScrollableContent>
            {isError
              ? switchRender({
                  status: error?.response?.status,
                  errorCode: error?.response?.data?.error_code,
                })
              : switchRender({})}
          </ScrollableContent>

          <ResultModalFooter>
            <ResultFooterContainer>
              <GridSpacer />
              <GridSpacer />
              <ResultModalFooterButtonContainer>
                <DefaultButton
                  backgroundColor={mainTheme.colors.primaryPositive}
                  hoverColor={mainTheme.colors.primary}
                  color={mainTheme.colors.onPrimary}
                  onClick={() => {
                    navigate("/workspaces");
                  }}
                  buttonText={"OK"}
                  size={"M"}
                  contentWidth={"72px"}
                />
              </ResultModalFooterButtonContainer>
              <GridSpacer />
            </ResultFooterContainer>
          </ResultModalFooter>
        </ResultModalContainer>
      </DefaultModal>
    </>
  );
};
