import { mainTheme } from "@data/theme/theme";
import { ActionIcon, CopyButton, Tooltip } from "@mantine/core";
import { IconCopy } from "@tabler/icons-react";

import React, { FC } from "react";

type Props = {
  value: string;
};

const DefaultCopyButton: FC<Props> = ({ value }) => {
  return (
    <>
      <CopyButton value={value}>
        {({ copied, copy }) => (
          <Tooltip label={"Copied !"} opened={copied} withArrow>
            <ActionIcon
              onClick={copy}
              style={{
                pointerEvents: "all",
              }}
            >
              <IconCopy color={mainTheme.colors.primary} />
            </ActionIcon>
          </Tooltip>
        )}
      </CopyButton>
    </>
  );
};

export default DefaultCopyButton;
