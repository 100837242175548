import { FC } from "react";
import { useNavigate } from "react-router-dom";

import {
  GridSpacer,
  DeleteModalContainer,
  DeleteModalFooter,
  DeleteModalHeader,
  DeleteListTitle,
  ScrollableContent,
  DeleteModalFooterContainer,
  DeleteModalFooterButtonContainer,
} from "./deleteModalStyle";
import { DeleteSuccess } from "./components/DeleteSuccess";

import { mainTheme } from "@data/theme/theme";
import DefaultButton from "@ui/components/Button/DefaultButton";
import DefaultModal from "@ui/components/Modal/DefaultModal";
import ModalResults from "@ui/templates/ModalResults";

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
  isError?: boolean;
  error?: any;
};

const DeleteVehicleModal: FC<Props> = ({ isOpen, isError = false, error }) => {
  const navigate = useNavigate();
  return (
    <DefaultModal isOpen={isOpen} onRequestClose={() => {}}>
      <DeleteModalContainer>
        <DeleteModalHeader>削除結果</DeleteModalHeader>

        <DeleteListTitle></DeleteListTitle>

        <ScrollableContent>
          <ModalResults
            children={<DeleteSuccess />}
            isError={isError}
            error={error}
            msg={"車両の削除"}
          />
        </ScrollableContent>

        <DeleteModalFooter>
          <DeleteModalFooterContainer>
            <GridSpacer />
            <GridSpacer />
            <DeleteModalFooterButtonContainer>
              <DefaultButton
                backgroundColor={mainTheme.colors.primaryPositive}
                hoverColor={mainTheme.colors.primary}
                color={mainTheme.colors.onPrimary}
                onClick={() => navigate("/vehicles")}
                buttonText={"OK"}
                size={"XS"}
                contentWidth={"72px"}
              />
            </DeleteModalFooterButtonContainer>
            <GridSpacer />
          </DeleteModalFooterContainer>
        </DeleteModalFooter>
      </DeleteModalContainer>
    </DefaultModal>
  );
};

export default DeleteVehicleModal;
