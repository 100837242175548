import React, { FC, MouseEventHandler } from "react";
import { SizeProp } from "../../../../@types/theme";
import { ButtonText, StyledButton } from "../defaultButtonStyle";
import { Popover } from "@mantine/core";
import {
  DialogButton,
  DialogContent,
  DialogHeader,
} from "./ChangeActiveButtonStyle";
import DefaultButton from "../DefaultButton";
import { mainTheme } from "@data/theme/theme";

type Props = {
  buttonText: string;
  onClick: MouseEventHandler<HTMLElement>;
  size?: DEFAULT_BUTTON_SIZE;
  contentWidth?: SizeProp;
  align?: "center" | "start" | "end";
  backgroundColor: string;
  hoverColor: string;
  border?: true;
  borderColor?: string;
  fontWeight?: "400" | "600";
  color: string;

  type?: "submit";
  opened?: boolean;
  onClose: () => void;

  dialogTitle?: string;
  dialogDescription?: string;
  onConfirm?: () => void;
};

const ChangeActiveButton: FC<Props> = ({
  buttonText,
  onClick,

  size = "M",
  contentWidth,
  fontWeight = "400",
  color,
  backgroundColor,
  hoverColor,
  border,
  borderColor,
  type,

  opened,
  onClose,

  dialogTitle = "",
  dialogDescription = "",
  onConfirm = () => {},
}) => {
  return (
    <Popover
      withArrow
      shadow="lg"
      width={"20%"}
      radius={"md"}
      opened={opened}
      onClose={onClose}
      keepMounted={false}
      arrowSize={18}
      zIndex={5}
    >
      <Popover.Target>
        <StyledButton
          size={size}
          color={color}
          backgroundColor={backgroundColor}
          hoverColor={hoverColor}
          border={border}
          borderColor={borderColor}
          fontWight={fontWeight}
          onClick={onClick}
          contentWidth={contentWidth}
          type={type}
        >
          <ButtonText>{buttonText}</ButtonText>
        </StyledButton>
      </Popover.Target>
      <Popover.Dropdown>
        <DialogContent.Container>
          <DialogHeader.Container>
            <DialogHeader.Title>{dialogTitle}</DialogHeader.Title>
            <DialogHeader.Description>
              {dialogDescription}
            </DialogHeader.Description>
          </DialogHeader.Container>

          <DialogButton.Container>
            <DefaultButton
              backgroundColor={"white"}
              hoverColor={mainTheme.colors.secondary}
              color={mainTheme.colors.onSecondary}
              onClick={onClose}
              buttonText={"NG"}
              size={"XXS"}
              border
              borderColor={mainTheme.colors.secondaryContainer}
              fontWeight={"600"}
            />
            <DefaultButton
              backgroundColor={`${mainTheme.colors.primaryPositive}`}
              buttonText="OK"
              color={`${mainTheme.colors.onPrimary}`}
              hoverColor={`${mainTheme.colors.primary}`}
              onClick={() => {
                onConfirm();
                onClose();
              }}
              size="XXS"
            />
          </DialogButton.Container>
        </DialogContent.Container>
      </Popover.Dropdown>
    </Popover>
  );
};

export default ChangeActiveButton;

export type DEFAULT_BUTTON_SIZE = `XS` | "S" | "M" | "L" | "XL";
