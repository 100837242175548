import { FC, useEffect, useState } from "react";

import { mainTheme } from "@data/theme/theme";
import DefaultButton from "@ui/components/Button/DefaultButton";
import DefaultModal from "@ui/components/Modal/DefaultModal";
import { EditedVehicle, PostVehicle, VehicleUnique } from "@data/types";

import {
  VehicleCheckModalContainer,
  VehicleCheckModalFooter,
  ModalHeading,
  FooterButtonContainer,
  ModalContentWrapper,
  VehicleCheckModalHeader,
  ModalSpan,
} from "./CheckVehicleModalStyle";
import { queryVehicle } from "@data/repositories/Vehicle/queryVehicle";
import { RegisterVehicleResultModal } from "../RegisterVehicleResultModal";
import VehicleIdNotification from "./components/VehicleNotification";
import { VehicleExistCheckNotification } from "./components/VehicleExistNotification";
import { useMutationVehicle } from "@data/repositories/Vehicle/mutationVehicle";
import { DefaultLoadingOverlay } from "@ui/components/LoadingOverlay/DefualtLoadingOverlay";
import { ConsoleLogger } from "@aws-amplify/core";
import { error } from "console";

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
  onClose?: () => void;
  editedVehicle: EditedVehicle;
};

export const CheckVehicleModal: FC<Props> = ({
  isOpen,
  editedVehicle,
  onRequestClose,
  onClose = () => {},
}) => {
  const [isRegister, setIsRegister] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [idCheck, setIdCheck] = useState<{ result: "true" | "false" }>();
  const [errors, setErrors] = useState({
    idCheck: false,
    uniqueCheck: false,
  });
  const [uniqueCheck, setUniqueCheck] = useState<VehicleUnique[]>();
  const [modalProps, setModalProps] = useState<PostVehicle>();
  const { postVehicleIdCheck, getVehicleUniqueCheck } = queryVehicle();
  const { registerVehicle } = useMutationVehicle();

  const handleIdCheck = async (
    vehicle: EditedVehicle
  ): Promise<[{ result: "true" | "false" }?, any?]> => {
    try {
      const idCheck = await postVehicleIdCheck(
        vehicle.vehicle_id, 
        "",
        vehicle.company_m.fms_project_id,
        vehicle.workspace_m.fms_environment_id);
      if (!["true", "false"].includes(idCheck.result)) {
        return [undefined, "error"];
      }
      return [idCheck, undefined];
    } catch (error) {
      return [undefined, error];
    }
  };
  const handleUniqueCheck = async (
    vehicle: EditedVehicle
  ): Promise<[VehicleUnique[]?, any?]> => {
    try {
      const uniqueCheck = await getVehicleUniqueCheck(vehicle.vehicle_id);
      return [uniqueCheck, undefined];
    } catch (error) {
      return [undefined, error];
    }
  };

  useEffect(() => {
    const vehicleCheck = async () => {
      const [idCheck, idCheckError] = await handleIdCheck(editedVehicle);
      if (idCheckError) {
        setErrors((pre) => {
          return {
            ...pre,
            idCheck: true,
          };
        });
        return;
      }
      idCheck && setIdCheck({ result: idCheck.result });

      if (!idCheck?.result) {
        return;
      }
      const [unique, uniqueCheckError] = await handleUniqueCheck(editedVehicle);
      if (uniqueCheckError) {
        setErrors((pre) => {
          return {
            ...pre,
            uniqueCheck: true,
          };
        });
      }

      if (unique) {
        setUniqueCheck(unique);
        setIsRegister(true);
      }
    };

    vehicleCheck();

    if (errors.idCheck || errors.uniqueCheck) {
      alert("リクエストに失敗しました");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors.idCheck, errors.uniqueCheck]);

  const handleSubmit = () => {
    registerVehicle.mutate(
      {
        vehicle: editedVehicle,
        deleteFlag: uniqueCheck && uniqueCheck?.length > 0 ? 1 : 0,
      },
      {
        onSuccess(data, variables, context) {
          setModalProps(data);
          setModalOpen(true);
        },

        onError: () => {
          setModalOpen(true);
        },
      }
    );
  };

  const idCheckOK = idCheck?.result === "true";
  const idCheckNoEmpty = idCheck?.result === "false";
  const uniqueCheckEmpty = idCheck?.result === "true" && uniqueCheck?.length === 0;
  const uniqueCheckNoEmpty = uniqueCheck && uniqueCheck?.length > 0;

  /// enter_key無効化
  document.onkeydown = function (e) {
    // エンターキーだったら無効にする
    if (e.key === "Enter") {
      return false;
    }
  };

  return (
    <>
      <DefaultModal
        isOpen={isOpen}
        maxWidth="870px"
        height="440px"
        padding="5vh 6vh"
        onRequestClose={onRequestClose}
      >
        <VehicleCheckModalContainer>
          <VehicleCheckModalHeader>
            <ModalHeading>登録データ確認</ModalHeading>
          </VehicleCheckModalHeader>

          <ModalContentWrapper>
            <VehicleIdNotification
              loading={!idCheck && !errors.idCheck}
              isError={errors.idCheck || Boolean(!idCheck)}
              title="FMS車両ID"
              idCheckError={idCheck?.result === "false"}
              check={!errors.idCheck && idCheck?.result === "true"}
            />

            {idCheckOK && (
              <VehicleExistCheckNotification
                loading={!uniqueCheck && !errors.uniqueCheck}
                isError={errors.uniqueCheck}
                title={"車両重複チェック"}
                noExist={
                  !errors.uniqueCheck && uniqueCheck && uniqueCheck.length === 0
                }
                uniqueCheck={uniqueCheck && uniqueCheck[0]}
              />
            )}
          </ModalContentWrapper>

          <VehicleCheckModalFooter>
            <ModalSpan>
              {uniqueCheckNoEmpty ? "このデータを上書きして登録しますか？" : ""}
              {uniqueCheckEmpty ? "このデータを登録しますか？" : ""}
              {idCheckNoEmpty ? "登録画面で入力情報を確認してください" : ""}
            </ModalSpan>
            <FooterButtonContainer>
              <DefaultButton
                backgroundColor={mainTheme.colors.secondaryPositive}
                hoverColor={mainTheme.colors.secondary}
                color={mainTheme.colors.onSecondary}
                border
                borderColor={mainTheme.colors.secondaryContainer}
                onClick={onClose}
                buttonText={"戻る"}
                size={"S"}
                contentWidth={"80px"}
              />
              {
                <DefaultButton
                  disabled={!isRegister}
                  backgroundColor={mainTheme.colors.primaryPositive}
                  hoverColor={mainTheme.colors.primary}
                  color={mainTheme.colors.onPrimary}
                  onClick={handleSubmit}
                  buttonText={"登録"}
                  size={"S"}
                  contentWidth={"80px"}
                />
              }
              {modalOpen && (
                <RegisterVehicleResultModal
                  isOpen={modalOpen}
                  onRequestClose={() => {
                    setModalOpen(false);
                  }}
                  vehicle={modalProps}
                  isError={registerVehicle.isError}
                  error={registerVehicle.error}
                />
              )}
              {registerVehicle.isLoading && (
                <DefaultLoadingOverlay visible={true} />
              )}
            </FooterButtonContainer>
          </VehicleCheckModalFooter>
        </VehicleCheckModalContainer>
        {}
      </DefaultModal>
    </>
  );
};
