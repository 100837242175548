import { FC } from "react";

import { ListItemContainer, ListText, GridSpacer } from "./listItemStyle";
import { DefaultCheckbox } from "@ui/components/Checkbox/DefaultCheckbox";
import { GetVehicle } from "@data/repositories/Vehicle/queryVehicle";

type Props = {
  item: GetVehicle & { selected: boolean };
  onClick: (item: GetVehicle & { selected: boolean }) => void;
};

const ListItem: FC<Props> = ({ item, onClick }) => {
  return (
    <ListItemContainer>
      <GridSpacer />
      <ListText>
        <DefaultCheckbox
          onChange={(e) => {
            onClick(item);
          }}
          checked={item.selected}
        />
      </ListText>
      <ListText>{item.vehicle_id}</ListText>
      <ListText>{item.alias_name}</ListText>
      <ListText>{item.workspace_m.workspace_nm}</ListText>

      <GridSpacer />
    </ListItemContainer>
  );
};

export default ListItem;
