import React, { FC } from "react";
import {
  ModalItem,
  VehicleCheckModalLabel,
  VehicleCheckModalText,
} from "./modalContentStyle";
import { Divider } from "@mantine/core";

import { LoadingNotification } from "@ui/components/LoadingNotification/LoadingNotification";
import { VehicleUnique } from "@data/types";

type Props = {
  loading?: boolean;
  isError?: boolean;
  uniqueCheck?: VehicleUnique;
  noExist?: boolean;
  title?: string;
};

export const VehicleExistCheckNotification: FC<Props> = ({
  loading,
  uniqueCheck,
  noExist,
  isError,
  title,
}) => {
  const existCheckMessage = {
    loading: <p></p>,
    isError: <p></p>,
    noExist: <span>重複はありません</span>,
    uniqueCheck: uniqueCheck && (
      <>
        <ModalItem>
          <VehicleCheckModalLabel>車両名</VehicleCheckModalLabel>
          <VehicleCheckModalText>
            {uniqueCheck?.vehicle_id}
          </VehicleCheckModalText>
        </ModalItem>
        <Divider />
        <ModalItem>
          <VehicleCheckModalLabel>エイリアス名</VehicleCheckModalLabel>
          <VehicleCheckModalText>
            {uniqueCheck?.alias_name}
          </VehicleCheckModalText>
        </ModalItem>
      </>
    ),
  };

  return (
    <>
      <>
        <LoadingNotification
          withCloseButton={false}
          withBorder
          loading={loading}
          isError={isError}
          title={title}
          caution={Boolean(uniqueCheck)}
        >
          {loading && existCheckMessage["loading"]}
          {!loading && noExist && existCheckMessage["noExist"]}
          {uniqueCheck && !noExist && existCheckMessage["uniqueCheck"]}
          {isError && existCheckMessage["isError"]}
        </LoadingNotification>
      </>
    </>
  );
};
