import { SizeProp } from "../../../@types/theme";
import React, { FC } from "react";
import ReactModal, { Styles } from "react-modal";

/// https://github.com/reactjs/react-modal/issues/808
/// react-modalをフラグで出し分けるとwarningが出ます

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
  onRequestClose?: () => void;
  height?: SizeProp;
  borderRadius?: SizeProp;
  padding?: string;
  maxWidth?: SizeProp;
};

const DefaultModal: FC<Props> = ({
  children,
  isOpen,
  onRequestClose,
  height = "70vh",
  borderRadius = "16px",
  padding = "40px 56px",
  maxWidth,
}) => {
  const DefaultModalStyle: Styles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(50, 50, 50, 0.75)",
      zIndex: 100,
    },
    content: {
      position: "absolute",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      WebkitOverflowScrolling: "touch",
      maxWidth: maxWidth,
      width: "110vh",
      height: height,
      borderRadius: borderRadius,
      padding: padding,
    },
  };
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={DefaultModalStyle}
      ariaHideApp={false}
    >
      {children}
    </ReactModal>
  );
};

export default DefaultModal;
