/**
 * ユーザ認証モック用。
 * Cognitoを使用するときは使用しない。
 */
import {create} from "zustand";

type MockAuthStore = {
  isAuth: boolean;
  setAuth: (value: boolean) => void;
};

export const useMockAuthStore = create<MockAuthStore>((set) => ({
  isAuth: false,
  setAuth: (value: boolean) => {
    set((state) => ({ isAuth: value }));
  },
}));
