import { Box, Text } from "@mantine/core";

export function PasswordRequirement({
  meets,
  label,
}: {
  meets: boolean;
  label: string;
}) {
  return (
    <Text
      color={meets ? "teal" : "red"}
      sx={{ display: "flex", alignItems: "center" }}
      mt={7}
      size="sm"
    >
      {meets ? "✓" : "✖"} <Box ml={10}>{label}</Box>
    </Text>
  );
}
