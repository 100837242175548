import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { mainTheme } from "@data/theme/theme";
import DefaultButton from "@ui/components/Button/DefaultButton";
import Divider from "@ui/components/Divider/Divider";
import Layout from "@ui/components/Layout";
import {
  GetVehicle,
  queryVehicle,
} from "@data/repositories/Vehicle/queryVehicle";

import VehiclesWidget from "../../widgets/VehiclesWidget";
import {
  VehicleListTitle,
  VehiclesScreenContainer,
  VehiclesHeader,
  VehiclesListContainer,
  GridSpacer,
  VehicleListITitleText,
  VehiclesScreenWrapper,
  SearchContainer,
} from "./vehiclesScreenStyle";
import RenderFooter from "./components/RenderFooter";
import InputText from "@ui/components/Input/InputText";
import { IconSearch } from "@tabler/icons-react";

const VehiclesScreen = () => {
  const navigate = useNavigate();
  const { useVehicleList } = queryVehicle();

  const { data: vehicles, isError: vehicleError } = useVehicleList();

  useEffect(() => {
    if (vehicleError) {
      alert("データの取得に失敗しました");
    }
  }, [vehicleError]);

  const [search, setSearch] = useState("");
  function filterData(data: GetVehicle[], search: string) {
    const query = search.toLowerCase().trim();
    return query.length > 0
      ? data.filter(
          (item) =>
            item.vehicle_id?.toLowerCase().includes(query) ||
            item.alias_name?.toLocaleLowerCase().includes(query) ||
            item.workspace_m.workspace_nm?.toLocaleLowerCase().includes(query)
        )
      : data;
  }

  return (
    <Layout title="車両一覧">
      <VehiclesScreenContainer>
        <GridSpacer />
        <VehiclesScreenWrapper>
          <VehiclesHeader>
            <DefaultButton
              backgroundColor={mainTheme.colors.primaryPositive}
              hoverColor={mainTheme.colors.primary}
              buttonText={"車両新規登録"}
              size={"M"}
              color={mainTheme.colors.onPrimary}
              align={"center"}
              onClick={() => {
                navigate("register");
              }}
            />
            <SearchContainer>
              <InputText
                label="検索"
                value={search}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setSearch(e.currentTarget.value)
                }
                placeholder="入力値で検索"
                size="md"
                icon={<IconSearch size={"1.2rem"} />}
              />
              {/* <DefaultMultiSelect
                label="走行環境名"
                data={dropdownList}
                maxSelectedValues={1}
                placeholder="走行環境名で検索"
                onChange={(e) => {
                  setSelectedValue(e);
                }}
              /> */}
            </SearchContainer>
          </VehiclesHeader>
          <VehicleListTitle>
            <GridSpacer />
            <VehicleListITitleText>車両ID</VehicleListITitleText>
            <VehicleListITitleText>車両名(エイリアス)</VehicleListITitleText>
            <VehicleListITitleText>走行環境名</VehicleListITitleText>
            <GridSpacer />
          </VehicleListTitle>

          <Divider thin="1px" rounded />

          <VehiclesListContainer>
            {vehicles && (
              <VehiclesWidget vehicles={filterData(vehicles, search)} />
            )}
          </VehiclesListContainer>

          <RenderFooter />
        </VehiclesScreenWrapper>
      </VehiclesScreenContainer>
    </Layout>
  );
};

export default VehiclesScreen;
