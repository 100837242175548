import { FC } from "react";

import { AllowStartVehicle } from "@ui/components/AllowStartVehicle";

import { UserListItem, UserListText, GridSpacer } from "./userItemStyle";
import { DefaultCheckbox } from "@ui/components/Checkbox/DefaultCheckbox";
import { GetUser } from "@data/repositories/User/queryUser";

type Props = {
  user: GetUser & { selected: boolean };
  onClick: (user: GetUser & { selected: boolean }) => void;
};

const UserItem: FC<Props> = ({ user, onClick }) => {
  const { workspace_cd, is_allowed_start_vehicle, workspace_m, company_m } =
    user;

  return (
    <UserListItem>
      <GridSpacer />
      <UserListText>
        <DefaultCheckbox
          onChange={() => onClick(user)}
          checked={user.selected}
        />
      </UserListText>
      <UserListText>{workspace_cd}</UserListText>
      <UserListText>{workspace_m?.workspace_nm}</UserListText>
      <AllowStartVehicle is_allowed={is_allowed_start_vehicle} />
      <UserListText>{company_m?.company_nm}</UserListText>

      <GridSpacer />
    </UserListItem>
  );
};

export default UserItem;
