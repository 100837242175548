import React, { FC, ReactNode } from "react";
import { InputTextContainer } from "./inputTextStyle";
import { Input, MantineSize } from "@mantine/core";
import { mainTheme } from "../../../data/theme/theme";
import { FontSizeProp } from "../../../@types/theme";

type InputTextType = {
  value?: string;
  description?: string;
  placeholder?: string;
  label?: string;
  labelId?: string;
  required?: true;
  withAsterisk?: true;
  disabled?: boolean;
  size?: MantineSize;
  errors?: {
    error?: ReactNode;
    errorMessage?: string;
  };
  icon?: ReactNode;

  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
};

const textSize: { [key in MantineSize]: FontSizeProp } = {
  xl: `${mainTheme.fontSize.medium}`,
  lg: `${mainTheme.fontSize.medium}`,
  md: `${mainTheme.fontSize.medium}`,
  xs: `${mainTheme.fontSize.medium}`,
  sm: `${mainTheme.fontSize.mine}`,
};

const InputText: FC<InputTextType> = ({
  value = "",
  description = "",
  placeholder = "",
  labelId = "",
  required,
  withAsterisk,
  label,
  errors,
  disabled,
  size = "md",
  icon,
  onChange = (e) => {},
}) => {
  return (
    <>
      <InputTextContainer>
        <Input.Wrapper
          inputWrapperOrder={["label", "input", "description", "error"]}
          description={!errors?.error ? description : ""}
          id={labelId}
          withAsterisk={!disabled ? withAsterisk : undefined}
          label={label}
          error={errors?.errorMessage}
          styles={{
            label: {
              color: `${
                !disabled
                  ? mainTheme.colors.onBackGround
                  : mainTheme.colors.grayOut
              }`,
              fontWeight: 600,
              fontSize: `${textSize[size]}`,
            },
            error: {
              fontSize: `${textSize[size]}`,
              disabled: "flex",
            },
          }}
        >
          <Input
            icon={icon}
            id={labelId}
            required={required}
            type={"text"}
            value={value}
            placeholder={
              !disabled ? placeholder : "このフィールドは入力できません"
            }
            size={size}
            error={errors?.error}
            onChange={onChange}
            disabled={disabled}
            styles={{
              input: {
                color: `${mainTheme.colors.onBackGround}`,
                fontWeight: 400,
                "&:focus-withIn": {
                  borderColor: `${mainTheme.colors.primaryPositive}`,
                },
              },
            }}
          />
        </Input.Wrapper>
      </InputTextContainer>
    </>
  );
};

export default InputText;
