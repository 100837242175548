import { GRID_BREAKPOINTS } from "@data/constants/breakPoint";
import styled from "@emotion/styled";
import { grid } from "../../registerWorkspaceWidgetStyle";

const contentGrid = "0.4fr 0.7fr 0.9fr 1fr 1fr 0.4fr";

export const RegisterWorkspaceFormWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  max-height: 700px;

  display: grid;
  grid-template-columns: ${grid};

  @media (max-width: ${GRID_BREAKPOINTS.lg}) {
    height: 80%;
  }
`;

export const RegisterWorkspaceFormContent = styled.div`
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  display: grid;
  grid-template-rows: 0.4fr 0.6fr 1fr 1fr 1fr 1fr;

  padding-top: 1rem;
  background-color: ${({ theme }) => theme.colors.background};

  @media (max-width: ${GRID_BREAKPOINTS.lg}) {
    display: grid;
    grid-template-rows: ${contentGrid};
  }
`;
