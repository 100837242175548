import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { DeleteSuccess } from "./components/DeleteSuccess";
import {
  GridSpacer,
  DeleteUserModalContainer,
  DeleteUserModalFooter,
  DeleteUserModalHeader,
  DeleteUserListTitle,
  ScrollableContent,
  DeleteUserModalFooterContainer,
  DeleteUserFooterButtonContainer,
} from "./deleteUserModalStyle";

import { mainTheme } from "@data/theme/theme";
import DefaultButton from "@ui/components/Button/DefaultButton";
import DefaultModal from "@ui/components/Modal/DefaultModal";
import ModalResults from "@ui/templates/ModalResults";

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
  isError?: boolean;
  error?: any;
};

const DeleteUserModal: FC<Props> = ({
  isOpen,
  onRequestClose,
  isError = false,
  error,
}) => {
  const navigate = useNavigate();
  return (
    <DefaultModal isOpen={isOpen} onRequestClose={() => {}}>
      <DeleteUserModalContainer>
        <DeleteUserModalHeader>削除結果</DeleteUserModalHeader>

        <DeleteUserListTitle></DeleteUserListTitle>

        {/* <ScrollableContent> */}
        <ModalResults
          children={<DeleteSuccess />}
          isError={isError}
          error={error}
          msg={"ユーザの削除"}
        />
        {/* </ScrollableContent> */}

        <DeleteUserModalFooter>
          <DeleteUserModalFooterContainer>
            <GridSpacer />
            <GridSpacer />
            <DeleteUserFooterButtonContainer>
              <DefaultButton
                backgroundColor={mainTheme.colors.primaryPositive}
                hoverColor={mainTheme.colors.primary}
                color={mainTheme.colors.onPrimary}
                onClick={() => navigate("/users")}
                buttonText={"OK"}
                size={"XS"}
                contentWidth={"72px"}
              />
            </DeleteUserFooterButtonContainer>
            <GridSpacer />
          </DeleteUserModalFooterContainer>
        </DeleteUserModalFooter>
      </DeleteUserModalContainer>
    </DefaultModal>
  );
};

export default DeleteUserModal;
