import { FC } from "react";

import {
  DeleteModalItemContainer,
  DeleteModalText,
  GridSpacer,
} from "./deleteModalItemStyle";
import { GetWorkspace } from "@data/repositories/Workspace/queryWorkspace";

type Props = {
  workspace: GetWorkspace;
};

const DeleteVehicleItem: FC<Props> = ({ workspace }) => {
  const { workspace_nm, workspace_cd, company_m } = workspace;
  return (
    <DeleteModalItemContainer>
      <GridSpacer />
      <DeleteModalText>{workspace_nm}</DeleteModalText>
      <DeleteModalText>{workspace_cd}</DeleteModalText>
      <DeleteModalText>{company_m?.company_nm}</DeleteModalText>
      <DeleteModalText>{company_m?.fms_project_id}</DeleteModalText>
      <GridSpacer />
    </DeleteModalItemContainer>
  );
};

export default DeleteVehicleItem;
