import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Vehicle } from "../../../data/entities/Vehicle/Vehicle";
import VehicleItem from "./components/VehicleItem";
import { VehiclesList, VehiclesListContainer } from "./vehiclesWidgetStyle";
import { GetVehicle } from "@data/repositories/Vehicle/queryVehicle";
import { useMutationVehicle } from "@data/repositories/Vehicle/mutationVehicle";
import { DefaultLoadingOverlay } from "@ui/components/LoadingOverlay/DefualtLoadingOverlay";

export type Props = {
  vehicles: GetVehicle[];
};

export const VehiclesWidget: FC<Props> = ({ vehicles }) => {
  const navigate = useNavigate();
  const { updateVehicleActive } = useMutationVehicle();

  const onSUbmitActive = (vehicle: Vehicle) => {
    updateVehicleActive.mutate(vehicle, {
      onError: (err: any) => {
        switch(err.response.data.error_code){
          case "E400009":
            alert("車両が存在しません");
            break;
          case "E400018":
            alert("走行環境が無効です");
            break;
          default:
            alert("リクエストに失敗しました");
            break;
        }
      },
    });
  };

  const onClickNavigate = (vehicle: Vehicle) => {
    navigate(`/vehicles/${vehicle.vehicle_id}`, { state: vehicle });
  };

  return (
    <>
      <VehiclesListContainer>
        {vehicles.length > 0 &&
          vehicles.map((vehicle, index) => (
            <VehiclesList key={index}>
              <VehicleItem
                vehicle={vehicle}
                onClickNavigate={onClickNavigate}
                onSUbmitActive={onSUbmitActive}
              />
            </VehiclesList>
          ))}
      </VehiclesListContainer>
      {updateVehicleActive.isLoading && (
        <DefaultLoadingOverlay
          visible={updateVehicleActive.isLoading}
          loaderSize="lg"
        />
      )}
    </>
  );
};

export default VehiclesWidget;
