import RegisterWorkspaceWidget from "@ui/widgets/RegisterWorkspaceWidget/";
import { RegisterWorkspaceContainer } from "./registerWorkspaceScreenStyle";
import Layout from "../../components/Layout";

const RegisterWorkspaceScreen = () => {
  return (
    <Layout title="走行環境：走行環境登録">
      <RegisterWorkspaceContainer>
        <RegisterWorkspaceWidget />
      </RegisterWorkspaceContainer>
    </Layout>
  );
};

export default RegisterWorkspaceScreen;
