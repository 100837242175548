import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { WorkspaceWitchCompany } from "@data/types";

import { WorkspaceList, WorkspaceListContainer } from "./workspacesWidgetStyle";
import WorkspaceItem from "./components/WorkspaceItem/WorkspaceItem";
import { useMutationWorkspace } from "@data/repositories/Workspace/mutationWorkspace";
import { DefaultLoadingOverlay } from "@ui/components/LoadingOverlay/DefualtLoadingOverlay";

type Props = {
  workspaces: WorkspaceWitchCompany[];
};

export const WorkspacesWidget: FC<Props> = ({ workspaces }) => {
  const navigate = useNavigate();
  const { updateWorkspaceActive } = useMutationWorkspace();

  const onSubmitActive = (workspace: WorkspaceWitchCompany) => {
    updateWorkspaceActive.mutate(workspace, {
      onError: () => {
        alert("リクエストに失敗しました");
      },
    });
  };

  const onClickNavigate = (workspace: WorkspaceWitchCompany) => {
    navigate(`/workspaces/${workspace.workspace_cd}`, { state: workspace });
  };

  return (
    <>
      <WorkspaceListContainer>
        {workspaces.length > 0 &&
          workspaces.map((workspace, index) => (
            <WorkspaceList key={index}>
              <WorkspaceItem
                workspace={workspace}
                onClickNavigate={onClickNavigate}
                onSubmitActive={onSubmitActive}
              />
            </WorkspaceList>
          ))}
      </WorkspaceListContainer>
      {updateWorkspaceActive.isLoading && (
        <DefaultLoadingOverlay
          visible={updateWorkspaceActive.isLoading}
          loaderSize="lg"
        />
      )}
    </>
  );
};

export default WorkspacesWidget;
