import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconEdit } from "@tabler/icons-react";

import { mainTheme } from "@data/theme/theme";
import { User } from "@data/entities/User/User";
import { AllowStartVehicle } from "@ui/components/AllowStartVehicle";
import DefaultIconButton from "@ui/components/Button/IconButton/iconButton";

import {
  UserListItem,
  UserListText,
  ButtonContainer,
  GridSpacer,
} from "./userItemStyle";
import { UserDetail } from "@data/types";
import ChangeActiveButton from "@ui/components/Button/ChangActiveButton/ChangeActiveButton";
import { ACTIVE } from "@data/constants/entity";

type Props = {
  user: UserDetail;
  onSubmitActive: (user: User) => void;
};

const UserItem: FC<Props> = ({ user, onSubmitActive }) => {
  const [opened, setOpened] = useState(false);

  const {
    workspace_cd,
    is_allowed_start_vehicle,
    workspace_m,
    is_active,
    company_m,
  } = user;
  const navigate = useNavigate();

  const onClickNavigate = (user: User) => {
    navigate(`/users/${user.id}`, {
      state: { user: { ...user }, title: "ユーザ：編集", submitText: "変更" },
    });
  };

  return (
    <UserListItem>
      <GridSpacer />
      <UserListText>{workspace_cd}</UserListText>
      <UserListText>{workspace_m?.workspace_nm}</UserListText>
      <AllowStartVehicle is_allowed={is_allowed_start_vehicle} />
      <UserListText>{company_m?.company_nm}</UserListText>
      <ButtonContainer>
        <div>
          <ChangeActiveButton
            backgroundColor={
              is_active === ACTIVE.ENABLE
                ? mainTheme.colors.primaryPositive
                : mainTheme.colors.grayOut
            }
            hoverColor={
              is_active === ACTIVE.ENABLE
                ? mainTheme.colors.primary
                : mainTheme.colors.onBackGroundPositive
            }
            color={mainTheme.colors.onPrimary}
            onClick={() => {
              setOpened(!opened);
            }}
            buttonText={is_active === ACTIVE.ENABLE ? "有効" : "無効"}
            contentWidth="72px"
            size={"S"}
            opened={opened}
            onClose={() => setOpened(false)}
            dialogTitle="有効・無効切替"
            dialogDescription={
              is_active === ACTIVE.ENABLE
                ? "無効化しますか？"
                : "有効化しますか？"
            }
            onConfirm={() => onSubmitActive(user)}
          />
        </div>
        <DefaultIconButton
          label="編集する"
          size="xl"
          onClick={() => onClickNavigate({ ...user })}
          Icon={<IconEdit color={mainTheme.colors.primary} size={"4rem"} />}
        />
      </ButtonContainer>
      <GridSpacer />
    </UserListItem>
  );
};

export default UserItem;
