import React, { useEffect, useMemo, useState } from "react";

import Layout from "@ui/components/Layout";
import Divider from "@ui/components/Divider/Divider";
import { GetUser, queryUser } from "@data/repositories/User/queryUser";
import { ACTIVE } from "@data/constants/entity";
import DeleteUserWidget from "@ui/widgets/DeleteUserWidget";

import {
  GridSpacer,
  SearchContainer,
  UserListTitle,
  UsersHeader,
  UsersScreenContainer,
  UsersScreenWrapper,
  UserListITitleText,
} from "./deleteUsersScreenStyle";
import DefaultMultiSelect from "@ui/components/Dropdown/MultiSelect/MultiSelect";
import { queryWorkspace } from "@data/repositories/Workspace/queryWorkspace";

type SelectableUser = GetUser & {
  selected: boolean;
};

const DeleteUserScreen = () => {
  const [selectedValue, setSelectedValue] = useState<string[]>([]);
  const [selectableUsers, setSelectableUsers] = useState<SelectableUser[]>();
  const [openConfirm, setOpenConfirm] = useState(false);

  const { useFrontUserList } = queryUser();
  const { useWorkspaceDropdown } = queryWorkspace();

  const { data: users, isError: userError } = useFrontUserList();
  const { data: workspaces, isError: workspaceError } = useWorkspaceDropdown();

  const noActiveUsers = (users: GetUser[]): SelectableUser[] => {
    const filtered = users?.filter((user) => {
      return user.is_active === ACTIVE.DISABLE;
    });

    return filtered.map((user) => {
      return {
        ...user,
        selected: false,
      };
    });
  };

  useMemo(() => {
    setSelectableUsers(noActiveUsers(users || []));
  }, [users]);

  const onSelect = (item: GetUser & { selected: boolean }) => {
    setSelectableUsers(
      selectableUsers?.map((user) => {
        return item.id === user.id
          ? {
              ...user,
              selected: !user.selected,
            }
          : { ...user };
      })
    );
  };

  const dropdownList = workspaces
    ? workspaces.map((workspace) => {
        return {
          value: workspace.workspace_nm || "",
          label: workspace.workspace_nm || "",
          group: "走行環境名",
        };
      })
    : [];

  const filterUsers = (
    machs: string[],
    users: SelectableUser[]
  ): SelectableUser[] => {
    if (!users) return [];
    return users.filter((user) => {
      return machs.length > 0
        ? machs.includes(user.workspace_m.workspace_nm || "")
        : true;
    });
  };

  useEffect(() => {
    if (userError || workspaceError) {
      alert("データの取得に失敗しました");
    }
  }, [userError, workspaceError]);

  return (
    <>
      <Layout title="ユーザ削除選択">
        <UsersScreenContainer>
          <GridSpacer />
          <UsersScreenWrapper>
            <UsersHeader>
              <SearchContainer>
                <DefaultMultiSelect
                  label="走行環境名"
                  data={dropdownList}
                  maxSelectedValues={1}
                  placeholder="走行環境名で検索"
                  onChange={(e) => {
                    setSelectedValue(e);
                  }}
                  onClick={() => {
                    setOpenConfirm(false);
                  }}
                />
              </SearchContainer>
            </UsersHeader>

            <UserListTitle>
              <GridSpacer />
              <UserListITitleText>選択</UserListITitleText>
              <UserListITitleText>ログインID</UserListITitleText>
              <UserListITitleText>走行環境名</UserListITitleText>
              <UserListITitleText>発進指示</UserListITitleText>
              <UserListITitleText>会社名</UserListITitleText>
              <GridSpacer />
            </UserListTitle>
            <Divider thin="1px" rounded />

            <DeleteUserWidget
              users={filterUsers(selectedValue, selectableUsers || [])}
              selectedUsers={selectableUsers?.filter((user) => {
                return user.selected;
              })}
              onSelect={onSelect}
              openConfirm={openConfirm}
              setOpenConfirm={setOpenConfirm}
            />
          </UsersScreenWrapper>
        </UsersScreenContainer>
      </Layout>
    </>
  );
};

export default DeleteUserScreen;
