import { MantineSize, MultiSelect, SelectItem } from "@mantine/core";
import React, { FC, ReactNode } from "react";
import { mainTheme } from "@data/theme/theme";

type Props = {
  data: SelectItem[];
  label?: string;
  maxSelectedValues?: number;
  nothingFound?: ReactNode;
  size?: MantineSize;
  placeholder?: string;
  onChange?: (value: string[]) => void;
  onClick?: () => void;
  icon?: ReactNode;
};

const DefaultMultiSelect: FC<Props> = ({
  data,
  size = "md",
  maxSelectedValues = 1,
  nothingFound,
  placeholder,
  icon,
  label,
  onChange = () => {},
  onClick = () => {},
}) => {
  return (
    <>
      <MultiSelect
        data-testid="test"
        data={data}
        icon={icon}
        label={label}
        maxSelectedValues={maxSelectedValues}
        maxDropdownHeight={"12rem"}
        size={size}
        nothingFound={nothingFound}
        placeholder={placeholder}
        onChange={onChange}
        onClick={onClick}
        styles={{
          input: {
            color: `${mainTheme.colors.onBackGround}`,
            fontWeight: 400,
            "&:focus-within": {
              borderColor: `${mainTheme.colors.primaryPositive}`,
            },
          },
          wrapper: {
            boxShadow: `${mainTheme.shadow.xxsmall}`,
          },
          label: {
            color: `${mainTheme.colors.onBackGround}`,
            fontWeight: 700,
          },
        }}
      />
    </>
  );
};

export default DefaultMultiSelect;
