import styled from "@emotion/styled";
import { GRID_BREAKPOINTS } from "../../../data/constants/breakPoint";

const grid = "1fr 2fr 1fr";

export const RegisterVehicleFormWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;

  display: grid;
  grid-template-columns: ${grid};
`;

export const GridWrapper = styled.div`
  height: 96px;

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr;
  }
  @media (max-width: ${GRID_BREAKPOINTS.lg}) {
    display: grid;
    grid-template-columns: 1fr;
  }
`;

export const RegisterVehicleForm = styled.form`
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  padding-top: 1rem;
  background-color: ${({ theme }) => theme.colors.background};
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 6em;
  margin-bottom: 2rem;
`;

export const FooterContainer = styled.div`
  margin-top: 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const StyledLabel = styled.label`
  display: flex;
  justify-content: start;
  font-weight: 500;
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.onBackGround};
  padding-right: 24px;

  @media (max-width: ${GRID_BREAKPOINTS.lg}) {
    display: none;
  }
`;

export const GridSpacer = styled.div``;
