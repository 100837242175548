import styled from "@emotion/styled";
import {
  RegisterResultModalContainer,
  RegisterResultModalFooter,
  RegisterResultModalFooterButtonContainer,
  RegisterResultModalFooterContainer,
  RegisterResultModalHeader,
} from "../RegisterVehicleResultModal/RegisterVehicleModalStyle";

export const GridSpacer = styled.div``;

export const VehicleDetailModalContainer = styled(
  RegisterResultModalContainer
)``;

export const VehicleDetailModalHeader = styled(RegisterResultModalHeader)``;

export const VehicleDetailModalFooter = styled(RegisterResultModalFooter)``;

export const VehicleDetailModalFooterContainer = styled(
  RegisterResultModalFooterButtonContainer
)``;

export const VehicleDetailFooterButtonContainer = styled(
  RegisterResultModalFooterContainer
)``;

export const ScrollableContent = styled.div`
  flex: 12;
  overflow-y: scroll;
  padding: 1em;

  /// scroll-bar 消去 edge
  -ms-overflow-style: none;
  /// scroll-bar 消去 chrome, safari
  &::-webkit-scrollbar {
    display: none;
  }
  /// scroll-bar 消去 firefox
  scrollbar-width: none;
`;
