import styled from "@emotion/styled";
import { GRID_BREAKPOINTS } from "../../../data/constants/breakPoint";
import { InputTitle } from "@ui/components/InputTitle/InputTitle";

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;

  @media (max-width: ${GRID_BREAKPOINTS.lg}) {
    display: flex;
    flex-direction: column;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
`;

export const UserFormHead = InputTitle;

export const StyledLabel = styled.label`
  display: flex;
  justify-content: start;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.onBackGroundPositive};
  font-size: ${({ theme }) => theme.fontSize.medium};

  padding-right: 24px;
  margin-bottom: 0.3rem;
`;

export const FormContainer = styled.div`
  background-color: white;
`;

export const GridSpacer = styled.div``;

export const ScrollableContent = styled.div`
  flex: 12;
  width: 100%;
  overflow-y: scroll;

  /// scroll-bar 消去 edge
  -ms-overflow-style: none;
  /// scroll-bar 消去 chrome, safari
  &::-webkit-scrollbar {
    display: none;
  }
  /// scroll-bar 消去 firefox
  scrollbar-width: none;
`;

export const RegisterUserForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`;
