export const useSelectorOption = () => {
  const setSelectorOption = <T>(object: T) => {
    return JSON.stringify(object ? object : {});
  };

  const parseSelectorOption = <T>(
    jsonString: string | null,
    initialState: T
  ): T => {
    return jsonString ? JSON.parse(jsonString) : initialState;
  };

  return {
    setSelectorOption,
    parseSelectorOption,
  };
};
