import { FC, MouseEventHandler } from "react";
import { SizeProp } from "../../../@types/theme";
import { ButtonText, StyledButton } from "./defaultButtonStyle";

type Props = {
  buttonText: string;
  onClick: MouseEventHandler<HTMLElement>;
  size?: DEFAULT_BUTTON_SIZE;
  contentWidth?: SizeProp;
  align?: "center" | "start" | "end";
  backgroundColor: string;
  hoverColor: string;
  border?: true;
  borderColor?: string;
  fontWeight?: "400" | "600";
  color: string;
  type?: "button" | "submit" | "reset" | undefined;
  radiusFull?: true;
  disabled?: boolean;
};

const DefaultButton: FC<Props> = ({
  buttonText,
  onClick,

  size = "M",
  contentWidth,
  fontWeight = "400",
  color,
  backgroundColor,
  hoverColor,
  border,
  borderColor,
  type,
  radiusFull,
  disabled,
}) => {
  return (
    <StyledButton
      disabled={disabled}
      size={size}
      color={color}
      backgroundColor={backgroundColor}
      hoverColor={hoverColor}
      border={border}
      borderColor={borderColor}
      fontWight={fontWeight}
      onClick={onClick}
      contentWidth={contentWidth}
      type={type}
      radiusFull={radiusFull}
    >
      <ButtonText>{buttonText}</ButtonText>
    </StyledButton>
  );
};

export default DefaultButton;

export type DEFAULT_BUTTON_SIZE = "XXS" | "XS" | "S" | "M" | "L" | "XL";
