import { FC } from "react";

import { mainTheme } from "@data/theme/theme";
import DefaultButton from "@ui/components/Button/DefaultButton";
import Divider from "@ui/components/Divider/Divider";
import DefaultModal from "@ui/components/Modal/DefaultModal";
import { GetUser } from "@data/repositories/User/queryUser";

import DeleteUserList from "./components/DeleteUserLIst";
import {
  GridSpacer,
  DeleteUserModalContainer,
  DeleteUserModalFooter,
  DeleteUserModalHeader,
  DeleteUserListTitle,
  DeleteUserText,
  ScrollableContent,
  DeleteUserModalFooterContainer,
  DeleteUserFooterButtonContainer,
} from "./deleteConfirmUserModalStyle";

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
  deletedUser: GetUser[];
  onSubmit?: () => void;
};

const DeleteConfirmUserModal: FC<Props> = ({
  isOpen,
  onRequestClose,
  deletedUser,
  onSubmit = () => {},
}) => {
  return (
    <DefaultModal isOpen={isOpen} onRequestClose={() => {}}>
      <DeleteUserModalContainer>
        <DeleteUserModalHeader>削除一覧</DeleteUserModalHeader>

        <DeleteUserListTitle>
          <GridSpacer />
          <DeleteUserText>ログインID</DeleteUserText>
          <DeleteUserText>走行環境名</DeleteUserText>
          <DeleteUserText>発進指示</DeleteUserText>
          <DeleteUserText>会社名</DeleteUserText>
          <GridSpacer />
        </DeleteUserListTitle>
        <Divider thin="1px" rounded />

        <ScrollableContent>
          <DeleteUserList users={deletedUser} />
        </ScrollableContent>

        <DeleteUserModalFooter>
          <DeleteUserModalFooterContainer>
            <GridSpacer />
            <GridSpacer />
            <DeleteUserFooterButtonContainer>
              <DefaultButton
                backgroundColor={mainTheme.colors.secondaryPositive}
                hoverColor={mainTheme.colors.secondary}
                color={mainTheme.colors.onSecondary}
                border
                borderColor={mainTheme.colors.secondaryContainer}
                onClick={onRequestClose}
                buttonText={"キャンセル"}
                size={"XS"}
                contentWidth={"72px"}
              />
              <DefaultButton
                backgroundColor={mainTheme.colors.primaryPositive}
                hoverColor={mainTheme.colors.primary}
                color={mainTheme.colors.onPrimary}
                onClick={onSubmit}
                buttonText={"削除"}
                size={"XS"}
                contentWidth={"72px"}
              />
            </DeleteUserFooterButtonContainer>
            <GridSpacer />
          </DeleteUserModalFooterContainer>
        </DeleteUserModalFooter>
      </DeleteUserModalContainer>
    </DefaultModal>
  );
};

export default DeleteConfirmUserModal;
