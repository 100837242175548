import { FC } from "react";

import { GetUser } from "@data/repositories/User/queryUser";

import DeleteUserItem from "../DeleteUserItem";
import { DeleteUserList, DeleteUserListContainer } from "./deleteUserListStyle";

export const DeleteVehicleList: FC<{ users?: GetUser[] }> = ({
  users = [],
}) => {
  return (
    <>
      <DeleteUserListContainer>
        {users.length > 0 &&
          users.map((user, index) => (
            <DeleteUserList key={index}>
              <DeleteUserItem user={user} />
            </DeleteUserList>
          ))}
      </DeleteUserListContainer>
    </>
  );
};

export default DeleteVehicleList;
