import { ChangeEvent, FC, memo } from "react";
import {
  GridWrapper,
  InputContainer,
  StyledLabel,
} from "../../registerWorkspaceWidgetStyle";
import InputText from "@ui/components/Input/InputText";

type Props = {
  inputProp?: string;
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
  leftDescription?: string;
  label?: string;
  labelId?: string;
  placeholder?: string;
  required?: true;
  withAsterisk?: true;
  description?: string;
  errors?: {
    error: boolean;
    errorMessage?: string;
  };
};

const WorkspaceFormInputMemo: FC<Props> = ({
  inputProp = "",
  onChange,
  leftDescription,
  label,
  labelId,
  placeholder,
  required,
  withAsterisk,
  errors,
  description,
}) => {
  return (
    <>
      <InputContainer>
        <GridWrapper>
          <StyledLabel>{leftDescription}</StyledLabel>
          <InputText
            size={"xs"}
            required={required}
            withAsterisk={withAsterisk}
            label={label}
            labelId={labelId}
            placeholder={placeholder}
            value={inputProp}
            onChange={onChange}
            errors={errors}
            description={description}
          />
        </GridWrapper>
      </InputContainer>
    </>
  );
};

export const WorkspaceFormInput = memo(WorkspaceFormInputMemo);
