import { GRID_BREAKPOINTS } from "@data/constants/breakPoint";
import styled from "@emotion/styled";

export const InputTitle = styled.label<{ breakpoint?: string }>`
  display: flex;
  justify-content: start;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.onBackGround};
  font-weight: 500;
  font-size: 2rem;

  margin-bottom: 0.1rem;
  padding-right: 24px;

  @media (max-width: ${GRID_BREAKPOINTS.lg}) {
    display: ${({ breakpoint }) => breakpoint};
    font-weight: 600;
    font-size: 1.5rem;
  }
`;
