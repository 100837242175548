import { START_VEHICLE } from "@data/constants/entity";
import styled from "@emotion/styled";

export const AllowedStartContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: start;
  align-content: start;
`;

export const AllowedStartWrapper = styled.div<{
  start_vehicle: START_VEHICLE;
  disabled?: boolean;
}>`
  width: 64px;
  height: 24px;
  border-radius: 99999px;
  background-color: ${({ theme, start_vehicle, disabled }) =>
    start_vehicle === START_VEHICLE.ALLOW && !disabled
      ? theme.colors.third
      : theme.colors.thirdNegative};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme, disabled }) =>
    !disabled ? theme.colors.onThird : theme.colors.grayOut};
`;
