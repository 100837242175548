import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { mainTheme } from "@data/theme/theme";
import DefaultButton from "@ui/components/Button/DefaultButton";
import DefaultModal from "@ui/components/Modal/DefaultModal";
import { UpdateVehiclesEnvironment } from "@data/repositories/Vehicle/mutationVehicle";

import {
  GridSpacer,
  VehicleDetailModalContainer,
  VehicleDetailModalFooter,
  VehicleDetailModalHeader,
  ScrollableContent,
  VehicleDetailFooterButtonContainer,
  VehicleDetailModalFooterContainer,
} from "./VehicleDetailModalStyle";
import { ModalContent } from "./components/ModalContent";
import ModalResults from "@ui/templates/ModalResults";

type Props = {
  isOpen: boolean;
  vehicle?: UpdateVehiclesEnvironment;
  isError?: boolean;
  error?: any;
};

export const VehicleDetailResultModal: FC<Props> = ({
  isOpen,
  vehicle,
  isError,
  error,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <DefaultModal isOpen={isOpen}>
        <VehicleDetailModalContainer>
          <VehicleDetailModalHeader>切替結果</VehicleDetailModalHeader>

          <ScrollableContent>
            <ModalResults
              children={<ModalContent vehicle={vehicle} />}
              isError={isError}
              error={error}
              msg={"車両の更新"}
            />
          </ScrollableContent>

          <VehicleDetailModalFooter>
            <VehicleDetailFooterButtonContainer>
              <GridSpacer />
              <GridSpacer />
              <VehicleDetailModalFooterContainer>
                <DefaultButton
                  backgroundColor={mainTheme.colors.primaryPositive}
                  hoverColor={mainTheme.colors.primary}
                  color={mainTheme.colors.onPrimary}
                  onClick={() => {
                    navigate("/vehicles");
                  }}
                  buttonText={"OK"}
                  size={"M"}
                  contentWidth={"72px"}
                />
              </VehicleDetailModalFooterContainer>
              <GridSpacer />
            </VehicleDetailFooterButtonContainer>
          </VehicleDetailModalFooter>
        </VehicleDetailModalContainer>
      </DefaultModal>
    </>
  );
};
