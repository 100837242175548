import React, { FC } from "react";
import { InputTextContainer } from "./inputTextStyle";
import { Input, MantineSize } from "@mantine/core";
import { mainTheme } from "../../../data/theme/theme";

type InputTextType = {
  value?: string;
  description?: string;
  label?: string;
  withAsterisk?: true;
  disabled?: true;
  errors?: {
    error: boolean;
    errorMessage?: string;
  };
  size?: MantineSize;
};

const ReadOnlyInput: FC<InputTextType> = ({
  value = "",
  description = "",
  withAsterisk,
  label,
  errors,
  disabled,
  size = "md",
}) => {
  return (
    <>
      <InputTextContainer>
        <Input.Wrapper
          inputWrapperOrder={["label", "input", "description", "error"]}
          description={!errors?.error ? description : ""}
          withAsterisk={withAsterisk}
          label={label}
          error={errors?.errorMessage}
          styles={{
            label: {
              color: `${mainTheme.colors.onBackGround}`,
              fontWeight: 600,
              fontSize: `${mainTheme.fontSize.medium}`,
            },
            error: {
              fontSize: `${mainTheme.fontSize.medium}`,
            },
          }}
        >
          <Input
            type={"text"}
            value={value}
            size={size}
            error={errors?.error}
            disabled={disabled}
            readOnly={true}
            styles={{
              input: {
                color: `${mainTheme.colors.onBackGround}`,
                backgroundColor: `${mainTheme.colors.background}`,
                fontWeight: 400,
                pointerEvents: "none",
              },
            }}
          />
        </Input.Wrapper>
      </InputTextContainer>
    </>
  );
};

export default ReadOnlyInput;
