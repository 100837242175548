import { mainTheme } from "@data/theme/theme";
import { ActionIcon, MantineSize, Tooltip } from "@mantine/core";

import React, { FC, MouseEventHandler, ReactNode } from "react";

type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  label?: string;
  size?: MantineSize;
  Icon: ReactNode;
};

const DefaultIconButton: FC<Props> = ({
  onClick,
  label,
  size = "md",
  Icon,
}) => {
  return (
    <>
      <Tooltip
        label={label}
        withArrow
        styles={{
          tooltip: {
            backgroundColor: `${mainTheme.colors.third}`,
            color: `${mainTheme.colors.onThird}`,
          },
        }}
      >
        <ActionIcon onClick={onClick} size={size}>
          {Icon}
        </ActionIcon>
      </Tooltip>
    </>
  );
};

export default DefaultIconButton;
