import axios from "axios";

const authEndPoint = `${process.env.REACT_APP_AUTH_ENDPOINT}`;

const Axios = axios.create({
  baseURL: authEndPoint,
});

const set_mock_access_token = (token: string) => {
  localStorage.setItem('mock_auth_token', token);
}

const get_mock_access_token = () => {
  return localStorage.getItem('mock_auth_token');
}

export const mockAuthLogin = async (loginData: { email: string; password: string }) => {
  const {data} = await Axios.post("/auth/login", loginData);
  const mock_access_token = data.access_token;
  set_mock_access_token(mock_access_token);
  axios.defaults.headers.post["x-auth-token"] = mock_access_token;
  return data;
};

export const refreshSession = async () => {
  return get_mock_access_token();
}
