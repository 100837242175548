import { FC } from "react";

import { AllowStartVehicle } from "@ui/components/AllowStartVehicle";
import { UserDetail } from "@data/types";

import {
  DeleteUserItemContainer,
  DeleteUserText,
  GridSpacer,
} from "./deleteUserItemStyle";

type Props = {
  user: UserDetail;
};

const DeleteVehicleItem: FC<Props> = ({ user }) => {
  const { is_allowed_start_vehicle, workspace_cd, workspace_m, company_m } =
    user;
  return (
    <DeleteUserItemContainer>
      <GridSpacer />
      <DeleteUserText>{workspace_cd}</DeleteUserText>
      <DeleteUserText>{workspace_m?.workspace_nm}</DeleteUserText>
      <DeleteUserText>
        <AllowStartVehicle is_allowed={is_allowed_start_vehicle} />
      </DeleteUserText>
      <DeleteUserText>{company_m?.company_nm}</DeleteUserText>
      <GridSpacer />
    </DeleteUserItemContainer>
  );
};

export default DeleteVehicleItem;
