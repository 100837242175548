import styled from "@emotion/styled";

export const DeleteUserItemContainer = styled.li`
  display: grid;
  grid-template-columns: 0.2fr 1fr 1fr 1fr 1fr 0.2fr;
  justify-content: start;
  color: ${(props) => props.theme.colors.onBackGround};

  align-items: start;
  padding: 16px 0;
  cursor: pointer;
  &:hover {
    box-shadow: ${(props) => props.theme.shadow.small};
    transition: box-shadow 0.5s;
  }
`;

export const DeleteUserText = styled.span`
  word-break: break-all;
  font-weight: 500;
  padding-right: 8px;
`;

export const GridSpacer = styled.div``;
