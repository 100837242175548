import RegisterWorkspaceWidget from "@ui/widgets/RegisterCompanyWidget/";
import { RegisterCompanyContainer } from "./registerCompanyScreenStyle";
import Layout from "../../components/Layout";
import { queryCompany } from "@data/repositories/Company/queryComany";
import { useEffect } from "react";

const RegisterCompanyScreen = () => {
  const { useCompanyList } = queryCompany();
  const { data, isError } = useCompanyList();

  useEffect(() => {
    if (isError) {
      alert("データの取得に失敗しました");
    }
  }, [isError]);
  return (
    <Layout title="走行環境：会社登録">
      <RegisterCompanyContainer>
        <RegisterWorkspaceWidget companies={data} />
      </RegisterCompanyContainer>
    </Layout>
  );
};

export default RegisterCompanyScreen;
