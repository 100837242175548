import { FC, useCallback, useState } from "react";

import { UserList, UserListContainer } from "./deleteUsersWidgetStyle";
import UserItem from "./components/UserItem/UserItem";
import RenderFooter from "./components/DeleteUsersFooter";

import { GetUser } from "@data/repositories/User/queryUser";
import Footer from "@ui/components/Footer/Footer";
import { useMutationUser } from "@data/repositories/User/mutationUser";
import DeleteUserModal from "@ui/modals/DeleteUserModal";
import { DefaultLoadingOverlay } from "@ui/components/LoadingOverlay/DefualtLoadingOverlay";
import DeleteConfirmUserModal from "@ui/modals/DeleteConfirmUserModal";

type Props = {
  users: (GetUser & { selected: boolean })[];
  selectedUsers?: (GetUser & { selected: boolean })[];
  onSelect: (item: GetUser & { selected: boolean }) => void;
  openConfirm: boolean;
  setOpenConfirm: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DeleteUserWidget: FC<Props> = ({
  users,
  selectedUsers = [],
  onSelect,
  openConfirm,
  setOpenConfirm,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const { deleteUsersMutation } = useMutationUser();

  const deleteIds = () => {
    const ids = selectedUsers.map((filtered) => {
      return filtered.id;
    });
    return ids;
  };

  const onSubmit = useCallback(() => {
    deleteUsersMutation.mutate(deleteIds(), {
      onSuccess: () => {
        setModalOpen(true);
      },
      onError: () => {
        setOpenConfirm(false);
        setModalOpen(true);
      },
    });
  }, [deleteIds()]);

  return (
    <>
      <UserListContainer>
        <UserList>
          {users.length > 0 &&
            users.map((user, index) => (
              <UserItem user={user} onClick={onSelect} key={index} />
            ))}
        </UserList>
      </UserListContainer>
      <Footer>
        <RenderFooter
          onOpenDialog={useCallback(() => {
            if (selectedUsers.length === 0) {
              alert("項目が選択されていません");
              return;
            }
            setOpenConfirm(true);
          }, [selectedUsers])}
        />
      </Footer>

      {openConfirm && (
        <DeleteConfirmUserModal
          isOpen={openConfirm}
          onRequestClose={() => {
            //setOpenConfirm(false)
          }}
          onSubmit={onSubmit}
          deletedUser={selectedUsers}
        />
      )}

      {modalOpen && (
        <DeleteUserModal
          isOpen={modalOpen}
          onRequestClose={() => {
            //setModalOpen(false);
          }}
          isError={deleteUsersMutation.isError}
          error={deleteUsersMutation.error}
        />
      )}

      <DefaultLoadingOverlay
        visible={deleteUsersMutation.isLoading}
        loaderSize="lg"
      />
    </>
  );
};

export default DeleteUserWidget;
