import React, { FC } from "react";
import { Header } from "./components/Hedaer";
import { LayoutContainer } from "./layoutStle";

type Props = {
  children: React.ReactNode;
  title: string;
};

const Layout: FC<Props> = ({ children, title = "" }) => {
  return (
    <LayoutContainer>
      <Header title={title} />
      {children}
    </LayoutContainer>
  );
};

export default Layout;
