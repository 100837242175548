import styled from "@emotion/styled";
import { GRID_BREAKPOINTS } from "../../../data/constants/breakPoint";

const grid = "1fr 2fr 1fr";

export const VehicleDetailFormWrapper = styled.div`
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: center;

  display: grid;
  grid-template-columns: ${grid};
`;

export const VehicleDetailForm = styled.form`
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
  padding-top: 1rem;

  background-color: ${({ theme }) => theme.colors.background};
`;

export const GridWrapper = styled.div`
  height: 96px;
`;

export const StyledLabel = styled.label`
  display: flex;
  justify-content: start;
  font-weight: 500;
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.onBackGround};
  padding-right: 24px;

  @media (max-width: ${GRID_BREAKPOINTS.lg}) {
    display: none;
  }
`;

export const TextContainer = styled.div`
  height: 96px;
`;

export const GridSpacer = styled.div``;
