import { FC } from "react";

import { GetWorkspace } from "@data/repositories/Workspace/queryWorkspace";

import DeleteModalItem from "../DeleteModalItem";
import {
  DeleteModalList,
  DeleteModalListContainer,
} from "./deleteModalListStyle";

export const DeleteVehicleList: FC<{ items?: GetWorkspace[] }> = ({
  items = [],
}) => {
  return (
    <>
      <DeleteModalListContainer>
        <DeleteModalList>
          {items.length > 0 &&
            items.map((item, index) => (
              <DeleteModalItem workspace={item} key={index} />
            ))}
        </DeleteModalList>
      </DeleteModalListContainer>
    </>
  );
};

export default DeleteVehicleList;
