import React, { FC } from "react";
import {
  RegisterResultModalLabel,
  RegisterResultModalText,
  RegisterModalItem,
} from "./modalContentStyle";
import { Divider } from "@mantine/core";
import DefaultCopyButton from "@ui/components/Button/CopyButton/DefaultCopyButton";
import { css } from "@emotion/react";
import { PostVehicle } from "@data/types";

type Props = {
  vehicle?: PostVehicle;
};

export const ModalContent: FC<Props> = ({ vehicle }) => {
  return (
    <>
      <RegisterModalItem>
        <RegisterResultModalLabel>会社名</RegisterResultModalLabel>
        <RegisterResultModalText>{vehicle?.company_nm}</RegisterResultModalText>
      </RegisterModalItem>

      <Divider />

      <RegisterModalItem>
        <RegisterResultModalLabel>FMS車両ID </RegisterResultModalLabel>
        <RegisterResultModalText>{vehicle?.vehicle_id}</RegisterResultModalText>
      </RegisterModalItem>

      <Divider />

      <RegisterModalItem>
        <RegisterResultModalLabel>車両名</RegisterResultModalLabel>
        <RegisterResultModalText>{vehicle?.alias_name}</RegisterResultModalText>
      </RegisterModalItem>

      <Divider />

      <RegisterModalItem>
        <RegisterResultModalLabel>走行環境名</RegisterResultModalLabel>
        <RegisterResultModalText>
          {vehicle?.workspace_nm}
        </RegisterResultModalText>
      </RegisterModalItem>

      <Divider />

      <RegisterModalItem>
        <RegisterResultModalLabel>DASHトークン</RegisterResultModalLabel>
        <RegisterResultModalText>
          {vehicle?.dash_token}
          {/* {"9cfe9477-04bf-46cf-a8c7-8d0307c8060a"} */}
        </RegisterResultModalText>
        <div
          css={css`
            display: flex;
            justify-content: center;
          `}
        >
          <DefaultCopyButton value={vehicle?.dash_token || ""} />
        </div>
      </RegisterModalItem>
    </>
  );
};
