import styled from "@emotion/styled";

export const VehiclesFooterContainer = styled.div`
  height: 20%;
  width: 100%;
  display: flex;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 0.2fr 2fr 1fr 0.2fr;
`;

export const VehiclesFooterButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  padding-right: 8px;
`;

export const BackToTopButtonContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;

export const GridSpacer = styled.div``;

export const VehicleModalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
