import styled from "@emotion/styled";

export const VehicleListItem = styled.li`
  display: grid;
  justify-content: start;
  grid-template-columns: 0.2fr 1fr 1fr 1fr 1fr 0.2fr;
  color: ${(props) => props.theme.colors.onBackGround};
  align-items: start;
  padding: 16px 0;
  cursor: pointer;
  &:hover {
    box-shadow: ${(props) => props.theme.shadow.small};
    transition: box-shadow 0.5s;
  }
`;

export const VehicleListText = styled.span`
  word-break: break-all;
  font-weight: 500;
  padding-right: 16px;
`;

export const VehicleListButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  padding: 0px 8px;
  gap: 16px;
`;

export const PopoverTitle = styled.h3`
  color: ${({ theme }) => theme.colors.onBackGround};
  font-weight: 700;
`;
export const PopoverText = styled.span`
  color: ${({ theme }) => theme.colors.onBackGround};
  font-weight: 500;
`;

export const GridSpacer = styled.div``;
