import { MantineSize, Select } from "@mantine/core";
import { FC } from "react";
import { mainTheme } from "../../../data/theme/theme";
import { DropdownWrapper } from "./dropdownStyle";
import { FontSizeProp } from "../../../@types/theme";
import { DropDownOption } from "@data/types";

type DropType = {
  required?: true;
  placeholder: string;
  description?: string;
  multiple?: true;
  options: DropDownOption[];
  label?: string;
  withAsterisk?: true;
  errorMessage?: string;
  disabled?: boolean;
  size?: MantineSize;
  defaultValue?: string;
  selectValue?: string;

  onSelect?: (value: string | null) => void;
  onClick?: () => void;
};

const textSize: { [key in MantineSize]: FontSizeProp } = {
  xl: `${mainTheme.fontSize.medium}`,
  lg: `${mainTheme.fontSize.medium}`,
  md: `${mainTheme.fontSize.medium}`,
  xs: `${mainTheme.fontSize.medium}`,
  sm: `${mainTheme.fontSize.mine}`,
};

const Dropdown: FC<DropType> = ({
  required,
  placeholder,
  description,
  options,
  label,
  withAsterisk,
  errorMessage,
  disabled,
  size = "md",
  defaultValue,
  selectValue,

  onSelect = (value: string | null) => {},
  onClick = () => {},
}) => {
  return (
    <>
      <DropdownWrapper>
        <Select
          defaultValue={defaultValue}
          shadow="md"
          placeholder={placeholder}
          description={description}
          clearable
          data={options}
          size={size}
          required={required}
          withAsterisk={withAsterisk}
          error={errorMessage}
          onChange={onSelect}
          label={label}
          disabled={disabled}
          clearButtonProps={{ "aria-label": "Clear select field" }}
          onDropdownOpen={onClick}
          onClick={() => {
            return;
          }}
          styles={{
            item: {
              borderColor: `${mainTheme.colors.outline}`,
              color: `${mainTheme.colors.onBackGround}`,
              wordBreak: "break-all",
              fontWeight: 400,
              "$:hover": {
                backgroundColor: `${mainTheme.colors.background}`,
              },
              "&[data-selected]": {
                backgroundColor: `${mainTheme.colors.primaryPositive}`,

                "&:hover": {
                  backgroundColor: `${mainTheme.colors.primary}`,
                },
              },
            },
            input: {
              color: `${mainTheme.colors.onBackGround}`,
              boxShadow: `${mainTheme.shadow.xsmall}`,
              fontWeight: 400,
              "&:focus-within": {
                borderColor: `${mainTheme.colors.primaryPositive}`,
              },
            },
            label: {
              fontSize: `${textSize[size]}`,
              color: `${mainTheme.colors.onBackGround}`,
              fontWeight: 600,
            },
            error: {
              fontSize: `${textSize[size]}`,
            },
          }}
        />
      </DropdownWrapper>
    </>
  );
};

export default Dropdown;
