import { WorkspaceDropdown, WorkspaceWitchCompany } from "@data/types";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { refreshSession } from "../Auth/auth";

export type GetWorkspace = WorkspaceWitchCompany;
const HOST = `${process.env.REACT_APP_ENDPOINT}`;

export const queryWorkspace = () => {
  const useWorkspaceList = () => {
    const fetchWorkspaces = async () => {
      const url = `${HOST}/workspaces`;
      const { data } = await axios.get<{ data: GetWorkspace[] }>(url, {
        headers: {
          Authorization: await refreshSession(),
        },
      });
      return data.data;
    };

    return useQuery(["workspaces"], fetchWorkspaces);
  };

  const useWorkspaceDropdown = () => {
    const fetchWorkspacesWithCompany = async () => {
      const url = `${HOST}/company/workspaces/`;
      const { data } = await axios.get<{ data: WorkspaceDropdown[] }>(url, {
        headers: {
          Authorization: await refreshSession(),
        },
      });
      return data.data;
    };

    return useQuery(["workspaceDropdown"], fetchWorkspacesWithCompany, {
      staleTime: 0,
    });
  };

  return {
    useWorkspaceList,
    useWorkspaceDropdown,
  };
};
