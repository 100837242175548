import React, { FC } from "react";

import {
  ResultModalLabel,
  ResultModalText,
  UserModalItem,
} from "./modalContentStyle";

import { START_VEHICLE } from "@data/constants/entity";
import { Divider } from "@mantine/core";
import { ModifyUser } from "@data/repositories/User/mutationUser";

type Props = {
  user?: ModifyUser;
};

export const ModalContent: FC<Props> = ({ user }) => {
  const startVehicle = {
    [START_VEHICLE.ALLOW]: "あり",
    [START_VEHICLE.DISALLOW]: "なし",
  };

  enum PASSWORD {
    NO_CHANGE = 0,
    CHANGE = 1,
  }
  return (
    <>
      <UserModalItem>
        <ResultModalLabel>会社名</ResultModalLabel>
        <ResultModalText>{user?.company_nm}</ResultModalText>
      </UserModalItem>

      <Divider />

      <UserModalItem>
        <ResultModalLabel>ログインID</ResultModalLabel>
        <ResultModalText>{user?.workspace_cd}</ResultModalText>
      </UserModalItem>

      <Divider />

      <UserModalItem>
        <ResultModalLabel>走行環境名</ResultModalLabel>
        <ResultModalText>{user?.workspace_nm}</ResultModalText>
      </UserModalItem>

      <Divider />

      <UserModalItem>
        <ResultModalLabel>パスワード</ResultModalLabel>
        <ResultModalText>
          {user?.change_password === PASSWORD.CHANGE ? "変更あり" : "変更なし"}
        </ResultModalText>
      </UserModalItem>

      <Divider />

      <UserModalItem>
        <ResultModalLabel>発進指示</ResultModalLabel>
        <ResultModalText>
          {user
            ? startVehicle[user.is_allowed_start_vehicle]
            : startVehicle[START_VEHICLE.DISALLOW]}
        </ResultModalText>
      </UserModalItem>
    </>
  );
};
