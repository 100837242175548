import styled from "@emotion/styled";

export const VehicleTitleButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 0px 8px;
`;

export const VehiclesListContainer = styled.div`
  flex: 12;
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  /// scroll-bar 消去 edge
  -ms-overflow-style: none;
  /// scroll-bar 消去 chrome, safari
  &::-webkit-scrollbar {
    display: none;
  }
  /// scroll-bar 消去 firefox
  scrollbar-width: none;
`;

export const VehiclesList = styled.ul`
  list-style: none;
  margin: 2px 0 0 0;
  padding-inline-start: 0;
`;
