import React from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {css} from "@emotion/react";
import {mainTheme} from "@data/theme/theme";
import DashMenuScreen from "@ui/screens/DashMenuScreen";
import VehiclesScreen from "@ui/screens/VehiclesScreen";
import RegisterVehicleScreen from "@ui/screens/RegisterVehicleScreen";
import VehicleDetailScreen from "@ui/screens/VehicleDetailScreen";
import WorkspacesScreen from "@ui/screens/WorkspacesScreen";
import RegisterCompanyScreen from "@ui/screens/RegisterCompanyScreen";
import RegisterWorkspaceScreen from "@ui/screens/RegisterWorkspaceScreen";
import UsersScreen from "@ui/screens/UsersScreen";
import RegisterUserScreen from "@ui/screens/RegisterUserScreen";
import DeleteUserScreen from "@ui/screens/DeleteUserScreen";
import DeleteWorkspacesScreen from "@ui/screens/DeleteWorkspaces";
import DeleteVehiclesScreen from "@ui/screens/DeleteVehiclesScreen/DeleteWorkspaces";
import AuthenticatorComponent from "@ui/screens/AuthScreen/AuthComponent";

import {Amplify} from "aws-amplify";
import config from "aws-exports";

Amplify.configure(config);

function App() {
  return (
    <div
      css={css`
        /* font-family: "Roboto"; */
        font-family: ${mainTheme.fonts.mainFont};
      `}
    >
      <BrowserRouter>
        <AuthenticatorComponent>
          <Routes>
            <Route path="/" element={<DashMenuScreen/>}/>
            <Route path="/vehicles" element={<VehiclesScreen/>}/>
            <Route path="/vehicles/register" element={<RegisterVehicleScreen/>}/>
            <Route path="/vehicles/delete" element={<DeleteVehiclesScreen/>}/>
            <Route path="/vehicles/:id" element={<VehicleDetailScreen/>}/>
            <Route path="/workspaces" element={<WorkspacesScreen/>}/>
            <Route path="/workspaces/register/company" element={<RegisterCompanyScreen/>}/>
            <Route path="/workspaces/register/workspace" element={<RegisterWorkspaceScreen/>}/>
            <Route path="/workspaces/delete" element={<DeleteWorkspacesScreen/>}/>
            <Route path="/users" element={<UsersScreen/>}/>
            <Route path="/users/register" element={<RegisterUserScreen/>}/>
            <Route path="/users/delete" element={<DeleteUserScreen/>}/>
            <Route path="/users/:id" element={<RegisterUserScreen/>}/>
          </Routes>
        </AuthenticatorComponent>
      </BrowserRouter>
    </div>
  );
}

export default App;
