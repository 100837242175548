import styled from "@emotion/styled";
import { GRID_BREAKPOINTS } from "../../../data/constants/breakPoint";
import { InputTitle } from "@ui/components/InputTitle/InputTitle";
import { RegisterWorkspaceFormWrapper } from "../RegisterWorkspaceWidget/components/RegisterWorkspaceForm/registerWorkspaceFromStyle";

const grid = "0.2fr 1fr 4fr 1fr 0.2fr";

export const RegisterCompanyFormWrapper = styled(RegisterWorkspaceFormWrapper)`
  grid-template-columns: ${grid};
`;

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr;
  }
  @media (max-width: ${GRID_BREAKPOINTS.lg}) {
    display: flex;
    flex-direction: column;
  }
`;

export const RegisterCompanyForm = styled.form`
  max-height: 800px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  display: grid;
  grid-template-rows: 1.3fr 0.1fr 1.1fr 1.5fr 1fr;

  padding-top: 1rem;
  background-color: ${({ theme }) => theme.colors.background};

  @media (max-width: ${GRID_BREAKPOINTS.lg}) {
    display: grid;
    grid-template-rows: 1fr 0.1fr 1fr 0.8fr;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem;
  gap: 1rem;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLabelHead = InputTitle;

export const StyledLabel = styled.label`
  display: flex;
  justify-content: start;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.onBackGround};
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize.medium};

  padding-right: 24px;
`;

export const GridSpacer = styled.div``;
