import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { mainTheme } from "@data/theme/theme";
import DefaultButton from "@ui/components/Button/DefaultButton";
import {
  ACTIVE,
  IS_ADMIN,
  START_VEHICLE,
  USER_KIND,
} from "@data/constants/entity";
import { User } from "@data/entities/User/User";
import { useSelectorOption } from "utils/useSelectorOption";
import { Company } from "@data/entities/Company/Company";
import { Workspace } from "@data/entities/Workspace/Workspace";
import { RegisterUserResultModal } from "@ui/modals/RegisterUserResultModal";
import { UserDetail } from "@data/types";
import {
  ModifyUser,
  ResponseUser,
  useMutationUser,
} from "@data/repositories/User/mutationUser";

import {
  ButtonContainer,
  GridSpacer,
  RegisterUserForm,
} from "./registerUserWidgetStyle";
import { RegisterUserFormContent } from "./components/UserForm";
import { UserFormInput } from "./components/userFormInput";
import { UserFormRadio } from "./components/UserFormRadio";
import UserFromPassword from "./components/UserFormPassword";
import { UserFormDropDown } from "./components/UserFromDropDown";
import { PasswordRequirement } from "./components/PasswordRequirement";
import { DefaultLoadingOverlay } from "@ui/components/LoadingOverlay/DefualtLoadingOverlay";
import { GetCompany } from "@data/repositories/Company/queryComany";
import ConfirmDialog from "@ui/components/Dialog/ConfirmDialog";
import { ModifyUserResultModal } from "@ui/modals/ModifyUserResultModal";

type validationSchema = Pick<
  User,
  "workspace_cd" | "password" | "company_id" | "internal_workspace_cd"
>;

type Props = {
  selectedUser?: UserDetail;
  submitText?: string;
  companies?: GetCompany[];
  workspaces?: Workspace[];
};

const requirements = [
  { re: /[0-9]/, label: "半角数字を含みます" },
  { re: /[a-zA-Z]/, label: "半角英字を含みます" },
];

const initialFrontUser: UserDetail = {
  id: undefined,
  workspace_cd: "",
  workspace_m: {
    workspace_cd: "",
    workspace_nm: "",
  },
  internal_workspace_cd: "",
  company_id: "",
  is_allowed_start_vehicle: START_VEHICLE.DISALLOW,
  password: "",
  kind: USER_KIND.FRONT_USER,
  is_admin: IS_ADMIN.DENY,
  is_active: ACTIVE.ENABLE,
  ver_no: 0,
};

const initialErrors: validationSchema = {
  workspace_cd: "",
  password: "",
  company_id: "",
  internal_workspace_cd: "",
};

const RegisterUserWidget: FC<Props> = ({
  selectedUser,
  submitText,
  companies,
  workspaces,
}) => {
  const [popoverOpened, setPopoverOpened] = useState(false);
  const [editedUser, setEditedUser] = useState<UserDetail>(initialFrontUser);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const { setSelectorOption, parseSelectorOption } = useSelectorOption();
  const [modalOpen, setModalOpen] = useState(false);
  const [modifyModalOpen, setModifyModalOpen] = useState(false);
  const [errors, setErrors] = useState(initialErrors);
  const { registerUser, updateUser } = useMutationUser();
  const [modalProp, setModalProps] = useState<ResponseUser>();
  const [modifyModalProp, setModifyModalProps] = useState<ModifyUser>();
  
  const REGISTER = selectedUser === undefined;

  useMemo(() => {
    selectedUser &&
      setEditedUser({
        ...selectedUser,
        password: "",
      });
    
  }, [selectedUser]);

  useEffect(() => {

    const initWorkspace = workspaces
    ? workspaces.filter((workspace) => {
        return workspace.workspace_nm === selectedUser?.workspace_m.workspace_nm;
      })
    : [];

    // Set default internal_workspace_cd on user-edit.
    if(!REGISTER) {
        if(initWorkspace.length > 0) 
        {
          setEditedUser((pre) => {
            return {
              ...pre,
              internal_workspace_cd: initWorkspace[0]?.workspace_cd,
              workspace_m: { ...initWorkspace[0] },
            };
          });
        }
    }
  }, [workspaces]);

  const inputHandler =
    (keyType: keyof User) => (e: ChangeEvent<HTMLInputElement>) => {
      setEditedUser((pre) => {
        return { ...pre, [keyType]: e.target.value };
      });
    };

  const registThrowableSchema = z.object<{
    [key in keyof validationSchema]: any;
  }>({
    workspace_cd: z
      .string()
      .regex(/^[-_0-9a-zA-Z]*$/, "半角英数字、「-」、「_」のみ入力できます")
      .nonempty("このフィールドは必須です"),
    password: z
      .string()
      .min(8, "パスワードは８文字以上である必要があります")
      .regex(
        /^(?=.*?[a-zA-Z])(?=.*?[0-9])[a-zA-Z0-9]{8,100}$/i,
        "パスワード入力に誤りがあります(半角英数字のみ入力できます)"
      ),
    company_id: z.string().nonempty("このフィールドは必須です"),
    internal_workspace_cd: z.string().nonempty("このフィールドは必須です"),
  });

  const noEditPassThrowableSchema = z.object<{
    [key in keyof validationSchema]: any;
  }>({
    workspace_cd: z
      .string()
      .regex(/^[-_0-9a-zA-Z]*$/, "半角英数字、「-」、「_」のみ入力できます")
      .nonempty("このフィールドは必須です"),
    company_id: z.string().nonempty("このフィールドは必須です"),
    internal_workspace_cd: z.string().nonempty("このフィールドは必須です"),
  });

  const editPassThrowableSchema = z.object<{
    [key in keyof validationSchema]: any;
  }>({
    workspace_cd: z
      .string()
      .regex(/^[-_0-9a-zA-Z]*$/, "半角英数字、「-」、「_」のみ入力できます")
      .nonempty("このフィールドは必須です"),
    password: z
      .string()
      .min(8, "パスワードは８文字以上である必要があります")
      .regex(
        /^(?=.*?[a-zA-Z])(?=.*?[0-9])[a-zA-Z0-9]{8,100}$/i,
        "パスワード入力に誤りがあります(半角英数字のみ入力できます)"
      ),
    company_id: z.string().nonempty("このフィールドは必須です"),
    internal_workspace_cd: z.string().nonempty("このフィールドは必須です"),
  });

  const renderChecks = requirements.map((requirement, indx) => {
    return (
      <PasswordRequirement
        key={indx}
        label={requirement.label}
        meets={requirement.re.test(editedUser.password || "")}
      />
    );
  });

  const handleCLick = () => {
    try {
      if(REGISTER) {
        registThrowableSchema.parse(editedUser);
      }
      else {
        if(editedUser.password?.length) {
          editPassThrowableSchema.parse(editedUser);
        }
        else {
          noEditPassThrowableSchema.parse(editedUser);
        }
      }
      setErrors(initialErrors);

      setOpenDialog(true);
    } catch (e: any) {
      setErrors({
        ...e.flatten().fieldErrors,
        workspace_m: {
          workspace_cd: e.flatten().fieldErrors.workspace,
        },
        company_m: {
          company_id: e.flatten().fieldErrors.company,
        },
        user_m: {
          workspace_cd: e.flatten().fieldErrors.user,
        },
      });
    }
  };

  const handleSubmit = () => {
    if (REGISTER) {
      registerUser.mutate(
        { user: editedUser },
        {
          onSuccess(data) {
            setModalProps(data);
            setModalOpen(true);
          },
          onError() {
            setModalOpen(true);
          },
        }
      );
    } else {
      updateUser.mutate(
        { user: editedUser },
        {
          onSuccess(data, variables, context) {
            setModifyModalProps(data);
            setModifyModalOpen(true);
          },
          onError() {
            setModifyModalOpen(true);
          },
        }
      );
    }
  };

  const filterWorkspace = (
    mach: string | undefined,
    workspaces: Workspace[]
  ): Workspace[] => {
    if (!workspaces) return [];
    return workspaces.filter((workspace) => {
      return mach ? mach === workspace.company_id : true;
    });
  };

  const defaultCompany = companies
    ? companies.filter((company) => {
        return company.company_id === selectedUser?.company_id;
      })
    : [];

  const companyDropDown = companies
    ? companies.map((company) => {
        return {
          value: setSelectorOption<
            Pick<Company, "company_id" | "company_nm" | "fms_project_id">
          >({
            company_id: company.company_id || "",
            company_nm: company.company_nm || "",
            fms_project_id: company.fms_project_id || "",
          }),
          label: company.company_nm || "",
        };
      })
    : [];

  const defaultWorkspace = workspaces
    ? workspaces.filter((workspace) => {
        return workspace.workspace_nm === selectedUser?.workspace_m.workspace_nm;
      })
    : [];
  
  const workspaceDropdown = workspaces
    ? filterWorkspace(editedUser.company_id, workspaces).map((workspace) => {
        return {
          value: setSelectorOption<
            Pick<Workspace, "workspace_cd" | "workspace_nm">
          >({ 
            workspace_cd: workspace.workspace_cd || "",
            workspace_nm: workspace.workspace_nm || "",
          }),
          label: workspace.workspace_nm || "",
        };
      })
    : [];

  /// enter_key無効化
  document.onkeydown = function (e) {
    if (e.key === "Enter") {
      return false;
    }
  };

  return (
    <>
      <RegisterUserForm>
        <RegisterUserFormContent>
          { companies && companies.length > 0 &&
          <UserFormDropDown
            defaultValue={setSelectorOption<GetCompany>({
              company_id: defaultCompany[0]?.company_id,
              company_nm: defaultCompany[0]?.company_nm,
              fms_project_id: defaultCompany[0]?.fms_project_id,
            })}
            label="会社名"
            placeholder="１つ選択"
            options={companyDropDown}
            size="xs"
            withAsterisk
            onSelect={(e: string | null) => {
              const company = parseSelectorOption<Company | undefined>(e, {
                company_id: "",
                company_nm: "",
                is_active: 1,
                ver_no: 0,
              });
              setEditedUser((pre) => {
                return { ...pre, company_id: company?.company_id };
              });
            }}
            onClick={() => {
              if (editedUser.workspace_m.workspace_cd) {
                alert("走行環境名をクリアしてから選択して下さい");
                return;
              }
            }}
            errorMessage={errors.company_id}
          />
          }
          { workspaces && workspaces.length > 0 &&
          <UserFormDropDown
            defaultValue={setSelectorOption<
              Pick<Workspace, "workspace_cd" | "workspace_nm">
            >({
              workspace_cd: defaultWorkspace[0]?.workspace_cd,
              workspace_nm: defaultWorkspace[0]?.workspace_nm,
            })}
            label="走行環境名"
            labelId="label_internal_workspace_cd"
            placeholder="１つ選択"
            size="xs"
            withAsterisk
            options={workspaceDropdown}
            onSelect={(e: string | null) => {
              const workspace = parseSelectorOption<
                Pick<Workspace, "workspace_cd" | "workspace_nm">
              >(e, {
                workspace_cd: "",
                workspace_nm: "",
              });
              setEditedUser((pre) => {
                return {
                  ...pre,
                  internal_workspace_cd: workspace?.workspace_cd,
                  workspace_m: { ...workspace },
                };
              });
            }}
            onClick={() => {
              if (!editedUser.company_id) {
                alert("会社名を選択してから選択して下さい");
              }
            }}
            errorMessage={errors.internal_workspace_cd}
          />
          }

          <UserFormInput
            label="ログインID"
            labelId="label_workspace_cd"
            placeholder="半角英数で入力"
            inputProp={editedUser.workspace_cd}
            onChange={inputHandler("workspace_cd")}
            size="xs"
            withAsterisk
            description="例： 1*******"
            errors={{
              error: Boolean(errors.workspace_cd),
              errorMessage: errors.workspace_cd,
            }}
          />

          <UserFormRadio
            label="発進指示"
            radioProps={[
              {
                value: `${START_VEHICLE.DISALLOW}`,
                label: "なし",
              },
              {
                value: `${START_VEHICLE.ALLOW}`,
                label: "あり",
              },
            ]}
            selectedRadio={`${editedUser.is_allowed_start_vehicle}`}
            onChange={(e: string) => {
              setEditedUser((pre) => {
                return { ...pre, is_allowed_start_vehicle: Number(e) };
              });
            }}
          />

          <UserFromPassword
            popOverOpened={popoverOpened}
            onOpenPopOver={setPopoverOpened}
            withAsterisk={REGISTER ? true : undefined}
            label="パスワード"
            labelId="label_admin_password"
            placeholder="パスワードを入力"
            value={editedUser.password}
            onChange={inputHandler("password")}
            renderChecks={renderChecks}
            errors={{
              error: Boolean(errors.password),
              errorMessage: errors.password ? errors.password[0] : "",
            }}
          />
          <GridSpacer />

          <ButtonContainer>
            <DefaultButton
              backgroundColor={"white"}
              hoverColor={mainTheme.colors.secondary}
              color={mainTheme.colors.onSecondary}
              onClick={() => {
                setEditedUser(initialFrontUser);
                navigate("/users");
              }}
              buttonText={"キャンセル"}
              size={"M"}
              border
              borderColor={mainTheme.colors.secondaryContainer}
              fontWeight={"600"}
              type="button"
            />
            <DefaultButton
              type="button"
              backgroundColor={mainTheme.colors.primaryPositive}
              hoverColor={mainTheme.colors.primary}
              color={mainTheme.colors.onPrimary}
              onClick={handleCLick}
              buttonText={!submitText ? "登録" : submitText}
              size={"M"}
              contentWidth={"96px"}
            />
          </ButtonContainer>
        </RegisterUserFormContent>

        <ConfirmDialog
          opened={openDialog}
          onCLose={useCallback(() => setOpenDialog(false), [openDialog])}
          description={
            REGISTER ? "編集内容を登録しますか？" : "ユーザ情報を変更しますか？"
          }
          executeButtonText={REGISTER ? "登録" : "変更"}
          onConfirm={handleSubmit}
        />

        {modalOpen && (
          <RegisterUserResultModal
            isOpen={modalOpen}
            onRequestClose={() => {
              //setModalOpen(false);
            }}
            user={modalProp}
            isError={registerUser.isError}
            error={registerUser.error}
          />
        )}

        {modifyModalOpen && (
          <ModifyUserResultModal
            isOpen={modifyModalOpen}
            onRequestClose={() => {
              //setModifyModalOpen(false);
            }}
            user={modifyModalProp}
            isError={updateUser.isError}
            error={updateUser.error}
          />
        )}

        {(registerUser.isLoading || updateUser.isLoading) && (
          <DefaultLoadingOverlay visible={true} loaderSize="lg" />
        )}
      </RegisterUserForm>
    </>
  );
};

export default RegisterUserWidget;
