import styled from "@emotion/styled";

export const VehiclesScreenContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 12;
  display: grid;
  grid-template-columns: 0.2fr 4fr 0.2fr;
  background-color: ${(props) => props.theme.colors.background};

  overflow-y: scroll;
  /// scroll-bar 消去 edge
  -ms-overflow-style: none;
  /// scroll-bar 消去 chrome, safari
  &::-webkit-scrollbar {
    display: none;
  }
  /// scroll-bar 消去 firefox
  scrollbar-width: none;
`;

export const SearchContainer = styled.div`
  width: 20rem;
`;

export const VehiclesScreenWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.background};

  overflow-y: scroll;
  /// scroll-bar 消去 edge
  -ms-overflow-style: none;
  /// scroll-bar 消去 chrome, safari
  &::-webkit-scrollbar {
    display: none;
  }
  /// scroll-bar 消去 firefox
  scrollbar-width: none;
`;

export const VehiclesHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 1rem;
  margin-bottom: 0.5rem;
`;

export const VehicleListTitle = styled.div`
  flex: 1;
  width: 100%;
  align-items: flex-end;
  padding-bottom: 0.5em;
  display: grid;
  white-space: nowrap;
  grid-template-columns: 0.2fr 1fr 1fr 2fr 0.2fr;
  color: ${(props) => props.theme.colors.onBackGround};
  font-weight: 600;
`;

export const VehiclesListContainer = styled.div`
  position: relative;
  z-index: 10;
  flex: 12;
  width: 100%;
  overflow-y: scroll;
  /// scroll-bar 消去 edge
  -ms-overflow-style: none;
  /// scroll-bar 消去 chrome, safari
  &::-webkit-scrollbar {
    display: none;
  }
  /// scroll-bar 消去 firefox
  scrollbar-width: none;
`;

export const VehicleListITitleText = styled.span``;

export const GridSpacer = styled.div``;
